import * as React from 'react';
import { useScript } from '../../../hooks';

export interface AdUnitProps {
  adSlot: string;
}

export default function AdUnit({ adSlot }: AdUnitProps) {
  useScript({
    src: process.env.GATSBY_GOOGLE_ADSENSE_SCRIPT_SRC ?? '',
    crossorigin: 'anonymous',
  });

  React.useEffect(() => {
    try {
      // @ts-ignore
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (err) {
      console.error(err);
    }
  }, []);

  return (
    <ins
      className="adsbygoogle"
      style={{
        display: 'inline-block',
        width: '100%',
        height: '100%',
      }}
      data-ad-client={process.env.GATSBY_GOOGLE_ADSENSE_CLIENT_ID ?? ''}
      data-ad-slot={adSlot}
      data-ad-format="auto"
      data-full-width-responsive="true"
      data-testid="ad-unit"
    />
  );
}
