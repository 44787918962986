import * as React from 'react';
import { SvgIconProps } from '@material-ui/core';
import SvgIconBtr from './SvgIconBtr';

export default function BtrBath(props: SvgIconProps) {
  return (
    <SvgIconBtr {...props}>
      <path d="M25.43,31.73H14.57a7.93,7.93,0,0,1-7.68-5.95L5.74,21.32A2.3,2.3,0,0,1,8,18.45H32a2.3,2.3,0,0,1,2.23,2.87l-1.15,4.46A7.93,7.93,0,0,1,25.43,31.73Z" />
      <line x1="14.57" y1="31.73" x2="14.57" y2="33.45" />
      <line x1="25.36" y1="31.73" x2="25.36" y2="33.45" />
      <path d="M11.75,18.39V8.87a2.26,2.26,0,0,1,2.46-2.32h0c1.36,0,2.47.82,2.47,1.84V9" />
      <polygon points="18.78 11.13 14.28 11.13 15.02 9.08 18.04 9.08 18.78 11.13" />
      <line x1="16.68" y1="21.13" x2="33.91" y2="21.13" />
    </SvgIconBtr>
  );
}
