/**
 * Add zeroes in front of a number. This will convert the number into a string.
 *
 * Example: given value = 42 and totalDigitCount = 3, will return '042'.
 */
export default function padZeroes(
  value: number,
  totalDigitCount: number
): string {
  if (Number.isNaN(value) || value === Infinity || value === -Infinity) {
    return `${value}`;
  }

  const valueWithoutSign = Math.abs(value);

  const zeroesCount = Math.max(
    Math.max(totalDigitCount, 0) - `${valueWithoutSign}`.length,
    0
  );

  const zeroes = '0'.repeat(zeroesCount);

  return `${value < 0 ? '-' : ''}${zeroes}${valueWithoutSign}`;
}
