import * as React from 'react';
import { SvgIconProps } from '@material-ui/core';
import SvgIconBtr from './SvgIconBtr';

export default function BtrEnergy(props: SvgIconProps) {
  return (
    <SvgIconBtr {...props}>
      <path d="M20,6.39,8,9.8v6A19.24,19.24,0,0,0,19.68,33.49l.32.12.32-.12A19.24,19.24,0,0,0,32,15.8v-6Z" />
      <polyline points="20.61 11.65 17.84 18.93 23.38 18.93 20 27.65" />
    </SvgIconBtr>
  );
}
