import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    videoCtn: {
      position: 'relative',
      width: '100%',
      height: '100%',
    },

    video: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      overflow: 'hidden',
      cursor: 'pointer',
    },
  })
);

export default useStyles;
