/**
 * Return the value of a client-side cookie.
 *
 * Return null if it doesn't exist;
 */
export default function getClientSideCookie(name: string): string | null {
  const existingCookie = document.cookie
    .split(';')
    .find((row) => row.trim().startsWith(`${name}=`));

  if (!existingCookie) {
    return null;
  }

  return existingCookie.split('=')[1];
}
