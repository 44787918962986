import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapContainer: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(1.5),
      [theme.breakpoints.up('md')]: {
        marginBottom: theme.spacing(2),
        width: '50%',
        float: 'left',
        '&:nth-child(2n+1)': {
          paddingRight: theme.spacing(6),
        },
        '&:nth-child(2n)': {
          paddingLeft: theme.spacing(6),
        },
      },
    },
    iconContainer: {
      padding: theme.spacing(1.5),
      width: 55,
      height: 55,
      backgroundColor: '#EBEAEA',
    },
    icon: {
      width: '100%',
    },
    itemContainer: {
      backgroundColor: '#FFFFFF',
      padding: theme.spacing(1.5),
      flex: 1,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(1.75, 2),
        maxHeight: 55,
      },
    },
    select: {
      borderRadius: theme.spacing(0.5),
      '& > .MuiOutlinedInput-notchedOutline': {
        borderRadius: theme.spacing(0.5),
      },
      '& > div.MuiSelect-select': {
        padding: theme.spacing(0.5, 1),
      },
      '& > .MuiSelect-icon': {
        right: 0,
      },
    },
    selectDropdown: {
      [theme.breakpoints.up('md')]: {
        border: 0,
        marginTop: theme.spacing(4),
        padding: 0,
        boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',
        '& .MuiListItem-root': {
          height: 26,
          justifyContent: 'center',
        },
      },
    },
    wrapSelect: {
      marginBottom: 0,
    },
    attributeName: {
      fontSize: theme.typography.pxToRem(14),
      [theme.breakpoints.up('md')]: {
        fontSize: theme.typography.pxToRem(16),
      },
    },
  })
);

export default useStyles;
