import * as React from 'react';
import { SvgIconProps } from '@material-ui/core';
import SvgIconBtr from './SvgIconBtr';

export default function BtrPaper(props: SvgIconProps) {
  return (
    <SvgIconBtr {...props}>
      <path d="M26.57,22.75v7.1a2.94,2.94,0,0,0,2.94,2.94h0a2.94,2.94,0,0,0,2.94-2.94V24.54H26.57" />
      <path d="M26.57,32.79H10.5a2.94,2.94,0,0,1-2.95-2.94h0V7.21h19V29.64" />
      <line x1="22.92" y1="12.83" x2="10.81" y2="12.83" />
      <line x1="22.92" y1="16.63" x2="10.81" y2="16.63" />
      <line x1="22.92" y1="20.43" x2="10.81" y2="20.43" />
    </SvgIconBtr>
  );
}
