import * as React from 'react';
import { SvgIconProps } from '@material-ui/core';
import SvgIconBtr from './SvgIconBtr';

export default function BtrVideoDoorEntry(props: SvgIconProps) {
  return (
    <SvgIconBtr {...props}>
      <polyline points="32.41 23.43 32.41 31.44 1.21 31.44 1.21 8.56 32.41 8.56 32.41 20" />
      <rect x="3.67" y="11.02" width="16.22" height="17.68" />
      <circle cx="11.77" cy="16.72" r="3.13" />
      <path d="M6.06,25.56c1.17-2.48,3.29-4.13,5.71-4.13,3.35,0,6.13,3.15,6.58,7.26" />
      <line x1="38.79" y1="20" x2="22.39" y2="20" />
      <polyline points="34.89 14.94 38.79 20.05 34.96 25.06" />
    </SvgIconBtr>
  );
}
