import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    snackbarStyled: {},

    containerStyled: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: theme.spacing(3),
      padding: theme.spacing(0.5, 2),
      maxWidth: 300,
      margin: theme.spacing(10.5, 'auto'),
    },
  })
);
