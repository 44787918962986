import * as React from 'react';
import { SvgIconProps } from '@material-ui/core';
import SvgIconBtr from './SvgIconBtr';

export default function BtrTreadmill(props: SvgIconProps) {
  return (
    <SvgIconBtr {...props}>
      <rect
        x="5.33"
        y="9.91"
        width="11.12"
        height="2.63"
        transform="translate(9.71 -4.37) rotate(39.82)"
      />
      <path d="M33.34,33.34h-25A3.32,3.32,0,0,1,5.06,30h0a3.32,3.32,0,0,1,3.77-3.29l24.73,3.43a1.6,1.6,0,0,1,1.38,1.58h0A1.6,1.6,0,0,1,33.34,33.34Z" />
      <circle cx="8.69" cy="30.03" r="0.87" />
      <line x1="12.98" y1="30.64" x2="27.2" y2="31.37" />
      <line x1="11.3" y1="13.28" x2="11.3" y2="26.71" />
      <line x1="5.59" y1="28.24" x2="8.69" y2="13.28" />
      <path d="M11.3,19.53h9.94a8.65,8.65,0,0,1,6.12,2.53l1.72,1.72" />
    </SvgIconBtr>
  );
}
