export class CustomError extends Error {
  readonly meta?: Record<string, any>;

  constructor({
    message,
    meta,
  }: {
    message: string;
    meta?: Record<string, any>;
  }) {
    super(message);

    if (meta) {
      this.meta = meta;
    }
  }
}

export interface ErrorObjFromApi {
  message: string;
  meta?: Record<string, any>;
}
