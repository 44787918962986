import * as React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import clsx from 'clsx';
import useStyles from './Link.styles';

export interface LinkProps {
  to: string;
  className?: string | undefined;
  noColor?: boolean;
  fullSize?: boolean;
  textDecoration?: React.CSSProperties['textDecoration'];
  activeClassName?: string;
  children?: React.ReactNode;
}

const Link = ({
  children,
  className,
  fullSize,
  to,
  noColor,
  textDecoration,
  activeClassName,
}: LinkProps) => {
  const classes = useStyles({ textDecoration });

  return (
    <GatsbyLink
      className={clsx(
        classes.link,
        fullSize && classes.fullSize,
        noColor ? classes.noColor : classes.withColor,
        className
      )}
      to={to}
      activeClassName={activeClassName}
    >
      {children}
    </GatsbyLink>
  );
};

export default Link;
