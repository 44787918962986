/**
 * Documentation: https://github.com/googlemaps/js-api-loader
 */

import { Loader } from '@googlemaps/js-api-loader';
import logIfDev from '../../utils/logging/logIfDev';

let loader: Loader | undefined = undefined;

if (typeof window !== 'undefined') {
  loader = new Loader({
    apiKey: process.env.GATSBY_GOOGLE_MAPS_API_KEY ?? '',
    libraries: ['places'],
    language: 'en-GB',
    region: 'GB',

    // TODO: re-enable when moved away from google-map-react
    // version: 'weekly',
  });

  loader.loadCallback((err) => {
    if (err) {
      console.error(`Could not load GoogleMaps`);
      console.error(err);
    } else {
      logIfDev(`GoogleMaps loaded`);
    }
  });
}

export default function loadGoogleMaps() {
  if (typeof window !== 'undefined') {
    try {
      if (!google) {
        return loader?.load();
      }
      return google;
    } catch (err) {
      return loader?.load();
    }
  }
}
