import * as React from 'react';
import { SvgIconProps } from '@material-ui/core';
import SvgIconBtr from './SvgIconBtr';

export default function BtrDetail(props: SvgIconProps) {
  return (
    <SvgIconBtr {...props}>
      <polyline points="30.45 24.97 30.45 34.82 7.96 34.82 7.96 7.14 30.45 7.14 30.45 20.98" />
      <line x1="12.44" y1="5.18" x2="30.45" y2="5.18" />
      <line x1="11.29" y1="13.14" x2="23.17" y2="13.14" />
      <line x1="11.29" y1="16.37" x2="23.17" y2="16.37" />
      <line x1="11.29" y1="19.61" x2="23.17" y2="19.61" />
      <line x1="11.29" y1="22.84" x2="23.17" y2="22.84" />
      <polyline points="20.58 25.61 25.2 30.22 36.04 19.38" />
    </SvgIconBtr>
  );
}
