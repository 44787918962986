import * as React from 'react';
import { SvgIconProps } from '@material-ui/core';
import SvgIconBtr from './SvgIconBtr';

export default function BtrCalendarWarning(props: SvgIconProps) {
  return (
    <SvgIconBtr {...props}>
      <polyline points="15.99 30.09 2.18 30.09 2.18 8.24 24.02 8.24 24.02 22.64" />
      <line x1="5.09" y1="13.91" x2="8.46" y2="13.91" />
      <line x1="11.41" y1="13.91" x2="14.78" y2="13.91" />
      <line x1="17.37" y1="13.91" x2="20.74" y2="13.91" />
      <line x1="5.09" y1="17.56" x2="8.46" y2="17.56" />
      <line x1="11.41" y1="17.56" x2="14.78" y2="17.56" />
      <line x1="17.37" y1="17.56" x2="20.74" y2="17.56" />
      <line x1="5.09" y1="21.2" x2="8.46" y2="21.2" />
      <line x1="11.41" y1="21.2" x2="14.78" y2="21.2" />
      <line x1="17.37" y1="21.2" x2="20.74" y2="21.2" />
      <line x1="7.13" y1="6.12" x2="7.13" y2="10.62" />
      <line x1="19.06" y1="6.12" x2="19.06" y2="10.62" />
      <polygon points="27.68 16.3 17.53 33.88 37.82 33.88 27.68 16.3" />
      <line x1="27.68" y1="21.2" x2="27.68" y2="28.89" />
      <line x1="27.68" y1="30.18" x2="27.68" y2="31.16" />
    </SvgIconBtr>
  );
}
