import * as React from 'react';
import useStyles from './DateSelector.styles';
import {
  ArrowRight as ArrowRightIcon,
  ArrowLeft as ArrowLeftIcon,
} from '@material-ui/icons';
import { Box } from '@material-ui/core';
import { Button, IconButton } from '../../atoms';
import CalendarIcon from '../../../images/icons/Calendar';
import DateSelectorDialog from './DateSelectorDialog/DateSelectorDialog';
import { formatDateToString } from './utils';
import { DateSelectorTab, DatesInfo } from './types';
import {
  addDays,
  addMonths,
  addWeeks,
  subDays,
  addYears,
  subWeeks,
  subMonths,
  subYears,
} from 'date-fns';

export interface DateSelectorProps {
  datesFrom: [Date, Date] | [Date];
  datesTo?: [Date, Date] | [Date];
  setDatesFrom: (datesFrom: [Date, Date] | [Date]) => void;
  setDatesTo: (datesTo?: [Date, Date] | [Date]) => void;
}

function DateSelector({
  datesFrom,
  datesTo,
  setDatesFrom,
  setDatesTo,
}: DateSelectorProps) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [selectedTab, setSelectedTab] = React.useState(DateSelectorTab.DATE);
  const [submittedTab, setSubmittedTab] = React.useState(DateSelectorTab.DATE);
  const [dateInfo, setDateInfo] = React.useState<DatesInfo | null>(null);
  const [dateComparingInfo, setDateComparingInfo] =
    React.useState<DatesInfo | null>(null);
  const [isComparing, setIsComparing] = React.useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const onSave = () => {
    if (dateInfo) {
      setDatesFrom(dateInfo.value);
    }
    setDatesTo(dateComparingInfo?.value);
    setSubmittedTab(selectedTab);
    handleClose();
  };

  const onChangeTab = (_: React.ChangeEvent, value: DateSelectorTab) => {
    setSelectedTab(value);
    setDateInfo(null);
    setDateComparingInfo(null);
  };

  const onSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsComparing(event.target.checked);
    setDateComparingInfo(null);
  };

  const onSelectDate = (_dateInfo: DatesInfo) => {
    if (_dateInfo.key === dateInfo?.key) {
      setDateInfo(null);
    } else {
      setDateInfo(_dateInfo);
    }
  };

  const onSelectDateComparing = (_dateInfo: DatesInfo) => {
    if (_dateInfo.key === dateComparingInfo?.key) {
      setDateComparingInfo(null);
    } else {
      setDateComparingInfo(_dateInfo);
    }
  };

  const onNextPeriod = () => {
    const [start1, end1] = datesFrom;
    const start2 = datesTo?.[0];
    const end2 = datesTo?.[1];
    let _datesFrom = datesFrom;
    let _datesTo = datesTo;

    switch (submittedTab) {
      case DateSelectorTab.DATE:
        _datesFrom = [subDays(start1, 1)];
        if (start2) {
          _datesTo = [subDays(start2, 1)];
        }
        break;
      case DateSelectorTab.WEEK:
        _datesFrom = [subWeeks(start1, 1), end1 && subWeeks(end1, 1)].filter(
          Boolean
        ) as [Date, Date] | [Date];
        if (start2) {
          _datesTo = [subWeeks(start2, 1), end2 && subWeeks(end2, 1)].filter(
            Boolean
          ) as [Date, Date] | [Date];
        }
        break;
      case DateSelectorTab.MONTH:
        _datesFrom = [subMonths(start1, 1), end1 && subMonths(end1, 1)].filter(
          Boolean
        ) as [Date, Date] | [Date];
        if (start2) {
          _datesTo = [subMonths(start2, 1), end2 && subMonths(end2, 1)].filter(
            Boolean
          ) as [Date, Date] | [Date];
        }
        break;
      case DateSelectorTab.YEAR:
        _datesFrom = [subYears(start1, 1), end1 && subYears(end1, 1)].filter(
          Boolean
        ) as [Date, Date] | [Date];
        if (start2) {
          _datesTo = [subYears(start2, 1), end2 && subYears(end2, 1)].filter(
            Boolean
          ) as [Date, Date] | [Date];
        }
        break;
      default:
        break;
    }
    setDatesFrom(_datesFrom);
    setDatesTo(_datesTo);
  };

  const onPrevPeriod = () => {
    const [start1, end1] = datesFrom;
    const start2 = datesTo?.[0];
    const end2 = datesTo?.[1];
    let _datesFrom = datesFrom;
    let _datesTo = datesTo;

    switch (submittedTab) {
      case DateSelectorTab.DATE:
        _datesFrom = [addDays(start1, 1)];
        if (start2) {
          _datesTo = [addDays(start2, 1)];
        }
        break;
      case DateSelectorTab.WEEK:
        _datesFrom = [addWeeks(start1, 1), end1 && addWeeks(end1, 1)].filter(
          Boolean
        ) as [Date, Date] | [Date];
        if (start2) {
          _datesTo = [addWeeks(start2, 1), end2 && addWeeks(end2, 1)].filter(
            Boolean
          ) as [Date, Date] | [Date];
        }
        break;
      case DateSelectorTab.MONTH:
        _datesFrom = [addMonths(start1, 1), end1 && addMonths(end1, 1)].filter(
          Boolean
        ) as [Date, Date] | [Date];
        if (start2) {
          _datesTo = [addMonths(start2, 1), end2 && addMonths(end2, 1)].filter(
            Boolean
          ) as [Date, Date] | [Date];
        }
        break;
      case DateSelectorTab.YEAR:
        _datesFrom = [addYears(start1, 1), end1 && addYears(end1, 1)].filter(
          Boolean
        ) as [Date, Date] | [Date];
        if (start2) {
          _datesTo = [addYears(start2, 1), end2 && addYears(end2, 1)].filter(
            Boolean
          ) as [Date, Date] | [Date];
        }
        break;
      default:
        break;
    }
    setDatesFrom(_datesFrom);
    setDatesTo(_datesTo);
  };

  return (
    <Box display="flex" justifyContent="space-between" className={classes.ctn}>
      <Box display="flex">
        <IconButton
          className={classes.iconButton}
          size="small"
          onClick={onPrevPeriod}
        >
          <ArrowLeftIcon />
        </IconButton>
        <Box>
          <Box className={classes.datesFrom}>
            {formatDateToString(datesFrom)}
          </Box>
          {datesTo && (
            <Box className={classes.datesTo}>{`vs ${formatDateToString(
              datesTo
            )}`}</Box>
          )}
        </Box>
        <IconButton
          className={classes.iconButton}
          size="small"
          onClick={onNextPeriod}
        >
          <ArrowRightIcon />
        </IconButton>
      </Box>
      <Button
        color="primary"
        className={classes.calendarButton}
        size="small"
        onClick={handleOpen}
      >
        <CalendarIcon className={classes.calendarIcon} />
        Date Range
      </Button>
      <DateSelectorDialog
        open={open}
        handleClose={handleClose}
        selectedTab={selectedTab}
        dateInfo={dateInfo}
        dateComparingInfo={dateComparingInfo}
        isComparing={isComparing}
        onSave={onSave}
        onChangeTab={onChangeTab}
        onSwitchChange={onSwitchChange}
        onSelectDate={onSelectDate}
        onSelectDateComparing={onSelectDateComparing}
      />
    </Box>
  );
}

export default DateSelector;
