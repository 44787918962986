import * as React from 'react';
import {
  createStyles,
  Modal,
  Theme,
  ModalProps as MUIModalProps,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

const useStyles = makeStyles<Theme, { modalWidth?: number }>((theme: Theme) =>
  createStyles({
    modalStyled: {
      display: 'flex',
      padding: theme.spacing(1),
      alignItems: 'center',
      justifyContent: 'center',
    },
    containerStyled: ({ modalWidth }) => ({
      width: '100%',
      maxWidth: modalWidth ?? 440,
      backgroundColor: '#F3F3F3',
      borderRadius: theme.spacing(3),
      boxShadow: theme.shadows[3],
      padding: theme.spacing(5),
      [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(0, 5),
      },
    }),
  })
);

export type ModalProps = MUIModalProps & {
  styleCustomized?: boolean;
  modalWidth?: number;
  containerClassName?: string;
};

export default function Popup({
  children,
  className,
  containerClassName,
  modalWidth = 440,
  styleCustomized = true,
  ...rest
}: ModalProps) {
  const classes = useStyles({ modalWidth });

  return (
    <Modal
      {...rest}
      className={clsx(styleCustomized && classes.modalStyled, className)}
    >
      <div
        className={clsx(
          styleCustomized && classes.containerStyled,
          containerClassName
        )}
      >
        {children}
      </div>
    </Modal>
  );
}
