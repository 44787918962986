import * as React from 'react';
import { SvgIconProps } from '@material-ui/core';
import SvgIconBtr from './SvgIconBtr';

export default function BtrTree(props: SvgIconProps) {
  return (
    <SvgIconBtr {...props}>
      <line x1="12.09" y1="34.14" x2="27.94" y2="34.14" />
      <line x1="20.01" y1="34.14" x2="20.01" y2="13.56" />
      <line x1="16.52" y1="14.34" x2="20.13" y2="17.95" />
      <line x1="23.2" y1="17.95" x2="20.17" y2="20.99" />
      <line x1="16.52" y1="21.97" x2="20" y2="25.45" />
      <path d="M22.79,27.33H27.4a4.12,4.12,0,0,0,.4-8.23,5.65,5.65,0,0,0,1.46-3.78h0A5.68,5.68,0,0,0,25,9.83a5.15,5.15,0,0,0-10,0,5.68,5.68,0,0,0-4.25,5.49h0A5.65,5.65,0,0,0,12.2,19.1a4.12,4.12,0,0,0,.4,8.23h7.53" />
    </SvgIconBtr>
  );
}
