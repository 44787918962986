import { zonedTimeToUtc } from 'date-fns-tz';
import { FirestoreDate } from '../api/utils/firestore';
import { getLocale, padZeroes } from './string';

export function formatDateDDMMYYYY(d: Date): string {
  const year = d.getFullYear();
  const month = padZeroes(d.getMonth() + 1, 2);
  const day = padZeroes(d.getDate(), 2);

  return `${day}/${month}/${year}`;
}

/**
 * Format a Date object to its local representation.
 *
 * If toLocaleDateString() is not supported, will fall back to DD/MM/YYYY.
 */
export function formatDate(d: Date): string {
  try {
    return d.toLocaleDateString(getLocale(), {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    });
  } catch (err) {
    return formatDateDDMMYYYY(d);
  }
}

/**
 * Format a Firestore Date object to its local representation.
 */
export function formatFirestoreDate(firestoreDate: FirestoreDate): string {
  return formatDate(new Date(firestoreDate._seconds * 1000));
}

export const getCurrentTimeStampUTC = (): number => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return zonedTimeToUtc(new Date(), timeZone).getTime();
};

export const formatDurationToString = (
  duration: number,
  { hours = 'h', minutes = 'm', seconds = 's' }
) => {
  let str = '';
  if (duration >= 3600) {
    str += `${Math.floor(duration / 3600)}${hours}`;
    duration %= 3600;
  }
  if (duration >= 60) {
    str += `${Math.floor(duration / 60)}${minutes}`;
    duration %= 60;
  }
  if (duration > 0) {
    str += `${duration}${seconds}`;
  }
  return str;
};
