import * as React from 'react';
import { SvgIconProps } from '@material-ui/core';
import SvgIconBtr from './SvgIconBtr';

export default function BtrTerrace(props: SvgIconProps) {
  return (
    <SvgIconBtr {...props}>
      <path d="M21.2,19a8.11,8.11,0,0,0-5.12-.49" />
      <path d="M30.08,16.57a.89.89,0,0,0,0-.14c-2.77-6.13-7.19-10-11.22-9.38l-.4.08c-.15,0-.28.08-.42.12" />
      <line x1="21.22" y1="18.97" x2="18.49" y2="7.13" />
      <path d="M21.2,19a8.11,8.11,0,0,0-5.12-.49,8.2,8.2,0,0,0-4,2.2,8.11,8.11,0,0,0-6.31-2.39A17.66,17.66,0,0,1,18.07,7.25c.14,0,.27-.09.42-.12l.4-.08a17.73,17.73,0,0,1,15.3,4,8.22,8.22,0,0,0-4.08,5.34.89.89,0,0,1,0,.14A8.14,8.14,0,0,0,21.2,19Z" />
      <path d="M12.12,20.72c-.23-6.76,2-12.24,6-13.47" />
      <line x1="21.2" y1="19.01" x2="24.49" y2="33.32" />
      <rect x="10.38" y="26.57" width="23" height="2.81" />
      <line x1="12.2" y1="29.38" x2="12.2" y2="33.32" />
      <line x1="31.88" y1="29.38" x2="31.88" y2="33.32" />
    </SvgIconBtr>
  );
}
