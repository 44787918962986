import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { OrgFile } from '../../orgs/types';
import pvtFetcher from '../../utils/pvtFetcher';

interface FetcherResponse {
  data: OrgFile[];
}

export const useFlatImagesUrlCreator = ({
  developmentId,
  flatId,
}: {
  developmentId: number;
  flatId: number;
}): string =>
  `${process.env.GATSBY_API_HOST}/api/development/${developmentId}/${flatId}/images`;

export default function useFlatImages({
  authToken,
  orgId,
  developmentId,
  flatId,
  queryConfig,
}: {
  authToken: string;
  orgId: number;
  developmentId: number;
  flatId: number;
  queryConfig?: UseQueryOptions<any>;
}) {
  const url = useFlatImagesUrlCreator({ developmentId, flatId });

  return useQuery<FetcherResponse, Error>(
    [url, { authToken, orgId }],
    () => pvtFetcher(url, { authToken, orgId }),
    queryConfig
  );
}
