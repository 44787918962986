import * as React from 'react';
import { Box, BoxProps } from '@material-ui/core';

export interface TabPanelProps extends BoxProps {
  currentTabIndex: number | string;
  thisTabIndex: number | string;
  children?: React.ReactNode;
}

export default function TabPanel({
  currentTabIndex,
  thisTabIndex,
  children,
  ...rest
}: TabPanelProps) {
  return (
    <Box role="tabpanel" hidden={currentTabIndex !== thisTabIndex} {...rest}>
      {currentTabIndex === thisTabIndex && children}
    </Box>
  );
}
