import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    textStyled: {
      background: '#fff',
      borderRadius: theme.spacing(1),
      height: 'auto',
      width: '100%',
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.pxToRem(16),
      resize: 'vertical',
      padding: theme.spacing(2, 2.5),
      [theme.breakpoints.down('md')]: {
        fontSize: theme.typography.pxToRem(14),
      },
      '&::-webkit-scrollbar': {
        width: 6,
        backgroundColor: '#f3f3f3',
      },
      '&::-webkit-scrollbar-track': {
        borderRadius: theme.spacing(1),
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
      },
    },

    labelStyled: {
      fontSize: theme.typography.pxToRem(16),
      fontFamily: theme.typography.fontFamily,
      fontWeight: theme.typography.fontWeightSemiBold,
      marginBottom: 14,
      [theme.breakpoints.down('md')]: {
        fontSize: theme.typography.pxToRem(14),
      },
    },

    wrapStyled: {
      marginBottom: 14,
    },
  })
);
