import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const Calendar = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 16 16" {...props}>
      <g id="calendar" transform="translate(-106 -229.68)">
        <path
          id="Path_275"
          data-name="Path 275"
          d="M106,242.9v-9.22a.482.482,0,0,0,.028-.086,3.077,3.077,0,0,1,2.832-2.668c.268-.018.539,0,.826,0,0-.211,0-.392,0-.572a.626.626,0,1,1,1.249,0c0,.181,0,.362,0,.55h6.126c0-.186,0-.362,0-.538a.626.626,0,1,1,1.249-.008c0,.181,0,.362,0,.555h.532a3.085,3.085,0,0,1,3.15,3.046q.015,4.344,0,8.688a3.072,3.072,0,0,1-2.369,2.949c-.146.034-.293.061-.44.091H108.811a.5.5,0,0,0-.087-.027,3.091,3.091,0,0,1-2.65-2.391C106.047,243.141,106.025,243.019,106,242.9Zm14.747-7.044h-13.5v.183q0,3.234,0,6.469a1.842,1.842,0,0,0,1.914,1.922q4.834,0,9.668,0a1.842,1.842,0,0,0,1.915-1.921q0-3.234,0-6.469C120.747,235.981,120.747,235.924,120.747,235.854Zm-.013-1.267a1.985,1.985,0,0,0-2.423-2.4c0,.389,0,.779,0,1.169a.625.625,0,1,1-1.248,0c0-.344,0-.688,0-1.031,0-.05-.006-.1-.009-.152h-6.117v.176c0,.344,0,.688,0,1.031a.623.623,0,1,1-1.247,0c0-.4,0-.8,0-1.2a1.985,1.985,0,0,0-2.418,2.409Z"
          transform="translate(0)"
          fill="currentColor"
        />
        <path
          id="Path_277"
          data-name="Path 277"
          d="M180.876,509.543c.213,0,.426,0,.639,0a.654.654,0,0,1,.011,1.307q-.663.015-1.326,0a.654.654,0,0,1,0-1.307C180.429,509.538,180.653,509.543,180.876,509.543Z"
          transform="translate(-70.518 -268.226)"
          fill="currentColor"
        />
        <path
          id="Path_278"
          data-name="Path 278"
          d="M357.7,509.541c.213,0,.426,0,.639,0a.654.654,0,0,1,.006,1.308q-.655.011-1.31,0a.654.654,0,0,1,.01-1.308C357.263,509.537,357.481,509.541,357.7,509.541Z"
          transform="translate(-240.037 -268.225)"
          fill="currentColor"
        />
        <path
          id="Path_279"
          data-name="Path 279"
          d="M357.7,421.227c.213,0,.426,0,.639,0a.654.654,0,0,1,0,1.308c-.431.007-.863.006-1.294,0a.655.655,0,1,1,0-1.309C357.266,421.224,357.485,421.227,357.7,421.227Z"
          transform="translate(-240.037 -183.582)"
          fill="currentColor"
        />
        <path
          id="Path_280"
          data-name="Path 280"
          d="M180.856,422.532c-.213,0-.426,0-.639,0a.655.655,0,1,1,0-1.31q.639-.006,1.277,0a.655.655,0,1,1,0,1.309C181.282,422.536,181.069,422.532,180.856,422.532Z"
          transform="translate(-70.507 -183.576)"
          fill="currentColor"
        />
        <path
          id="Path_281"
          data-name="Path 281"
          d="M269.178,421.231c.213,0,.426,0,.639,0a.654.654,0,0,1,.011,1.308q-.655.011-1.31,0a.654.654,0,0,1,.006-1.308C268.741,421.228,268.959,421.231,269.178,421.231Z"
          transform="translate(-155.176 -183.586)"
          fill="currentColor"
        />
        <path
          id="Path_282"
          data-name="Path 282"
          d="M269.159,510.838c-.207,0-.415,0-.622,0a.663.663,0,0,1-.669-.663.655.655,0,0,1,.666-.647q.639-.007,1.277,0a.655.655,0,1,1,0,1.309C269.6,510.842,269.378,510.838,269.159,510.838Z"
          transform="translate(-155.176 -268.21)"
          fill="currentColor"
        />
      </g>
    </SvgIcon>
  );
};

export default Calendar;
