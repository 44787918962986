import React, { useState } from 'react';
import { SnackbarType } from '../../components/atoms';

export interface SnackbarState {
  type: SnackbarType;
  show: boolean;
  content?: React.ReactNode | string;
}

export default function useSnackbar() {
  const [snackbarState, setSnackbarState] = useState<SnackbarState>({
    type: SnackbarType.NORMAL,
    show: false,
    content: undefined,
  });

  const displaySnackbar = (
    type: SnackbarType,
    content: React.ReactNode | string
  ) => {
    setSnackbarState({
      type,
      show: true,
      content,
    });
  };

  const hideSnackbar = () => {
    setSnackbarState((prevState) => ({
      ...prevState,
      show: false,
    }));
  };

  return {
    snackbarState,
    displaySnackbar,
    hideSnackbar,
  };
}
