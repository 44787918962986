import * as React from 'react';
import { Box } from '@material-ui/core';
import { Button, ButtonProps, UploadArea, UploadAreaProps } from '../../atoms';

import useStyles from './UploadButton.styles';

export interface UploadButtonProps {
  buttonProps?: ButtonProps;
  uploadAreaProps: UploadAreaProps;
}

export default function UploadButton({
  buttonProps,
  uploadAreaProps,
}: UploadButtonProps) {
  const classes = useStyles();

  return (
    <Box className={classes.uploadButton}>
      <Button
        className={classes.uploadButtonButton}
        variant="outlined"
        roundedLevel="medium"
        {...buttonProps}
      >
        Upload
      </Button>

      <Box className={classes.uploadButtonUploadArea}>
        <UploadArea {...uploadAreaProps} />
      </Box>
    </Box>
  );
}
