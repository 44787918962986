import * as React from 'react';
import { SvgIconProps } from '@material-ui/core';
import SvgIconBtr from './SvgIconBtr';

export default function BtrStudent(props: SvgIconProps) {
  return (
    <SvgIconBtr {...props}>
      <polyline points="31.73 14.31 19.6 8.83 3.7 16.01 19.6 23.18 30.09 18.45 30.09 18.45 30.09 21.55 30.09 26.43 23.48 29.42 19.6 31.17 15.72 29.42 9.1 26.43 9.1 21.55 9.1 18.45" />
      <polyline points="34.48 26.54 34.48 16.01 19.6 16.01" />
      <polygon points="36.3 31.17 32.8 31.17 32.8 27.54 34.48 26.1 36.3 27.54 36.3 31.17" />
      <line x1="28.64" y1="19.1" x2="31.73" y2="17.7" />
    </SvgIconBtr>
  );
}
