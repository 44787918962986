import { IS_PROD } from '../../config/app';

/**
 * Perform a console.log in non-production environments only.
 */
export default function logIfDev(
  msg: unknown,
  variant: 'default' | 'error' = 'default'
) {
  if (!IS_PROD) {
    switch (variant) {
      case 'error':
        console.error(msg);
        break;
      default:
        console.log(msg);
    }
  }
}
