import * as React from 'react';
import { SvgIconProps } from '@material-ui/core';
import SvgIconBtr from './SvgIconBtr';

export default function BtrSwimmingPool(props: SvgIconProps) {
  return (
    <SvgIconBtr {...props}>
      <path d="M6.9,12.33V9.6A2.94,2.94,0,0,1,9.84,6.66h0A2.93,2.93,0,0,1,12.78,9.6V26.21" />
      <path d="M21.12,12.33V9.6a2.94,2.94,0,0,1,2.94-2.94h0A2.94,2.94,0,0,1,27,9.6V25.67" />
      <line x1="15.04" y1="16.44" x2="27" y2="16.44" />
      <line x1="12.78" y1="20.56" x2="24.74" y2="20.56" />
      <path d="M3.73,25.67c5.42,0,5.42,3.26,10.84,3.26S20,25.67,25.42,25.67s5.42,3.26,10.85,3.26" />
      <path d="M3.73,30.09c5.42,0,5.42,3.25,10.84,3.25S20,30.09,25.42,30.09s5.42,3.25,10.85,3.25" />
    </SvgIconBtr>
  );
}
