import * as React from 'react';
import { SvgIconProps } from '@material-ui/core';
import SvgIconBtr from './SvgIconBtr';

export default function BtrPet(props: SvgIconProps) {
  return (
    <SvgIconBtr {...props}>
      <path d="M26.75,25.3l-3.2-5.53a4.09,4.09,0,0,0-7.1,0l-3.2,5.53A4.11,4.11,0,0,0,14.9,31a3.92,3.92,0,0,0,2.76.25L19,30.93a4.31,4.31,0,0,1,2,0l1.33.32A3.92,3.92,0,0,0,25.1,31,4.11,4.11,0,0,0,26.75,25.3Z" />
      <ellipse
        cx="10.62"
        cy="18.95"
        rx="2.59"
        ry="3.59"
        transform="translate(-8.63 8.9) rotate(-33.16)"
      />
      <ellipse
        cx="15.98"
        cy="12.2"
        rx="2.59"
        ry="3.59"
        transform="translate(-0.63 0.87) rotate(-3.06)"
      />
      <ellipse
        cx="29.38"
        cy="18.95"
        rx="3.59"
        ry="2.59"
        transform="translate(-2.56 33.17) rotate(-56.84)"
      />
      <ellipse
        cx="24.02"
        cy="12.2"
        rx="3.59"
        ry="2.59"
        transform="translate(10.55 35.54) rotate(-86.94)"
      />
    </SvgIconBtr>
  );
}
