import * as React from 'react';
import {
  ListItem,
  Typography,
  Grid,
  Select,
  MenuItem,
} from '@material-ui/core';
import { ArrowLeft, ArrowRight } from '@material-ui/icons';
import clsx from 'clsx';
import useStyles from './Pagination.styles';

export interface PaginationProps {
  page?: number;
  itemsPerPage?: number;
  total: number;
  showDescription?: boolean;

  handlePagination?: (action: string, pageAction?: number) => void;
}

export default function Pagination({
  page = 1,
  itemsPerPage = 10,
  showDescription = true,
  total,
  handlePagination,
}: PaginationProps) {
  const classes = useStyles();

  const totalPage = Math.ceil(total / itemsPerPage);
  const showingStart = (page - 1) * itemsPerPage + 1;
  let showingEnd = page * itemsPerPage;
  if (showingEnd > total) {
    showingEnd = total;
  }

  function handNextPagination() {
    handlePagination?.('next');
  }

  function handPrevPagination() {
    handlePagination?.('prev');
  }

  function handPagePagination(
    event: React.ChangeEvent<{
      name?: string;
      value: number;
    }>
  ) {
    handlePagination?.('page_action', event.target.value);
  }

  function listPage() {
    return (
      <div className={classes.pageSelectCtn}>
        <span>{`Page`}</span>
        <Select
          value={page}
          onChange={handPagePagination}
          displayEmpty
          className={classes.pageSelect}
          inputProps={{ 'aria-label': 'Without label' }}
          disableUnderline
        >
          {Array(totalPage)
            .fill(true)
            .map((val, idx) => (
              <MenuItem key={idx} value={idx + 1}>
                {idx + 1}
              </MenuItem>
            ))}
        </Select>
        <span>{`of ${totalPage}`}</span>
      </div>
    );
  }

  return (
    <Grid container alignItems="center">
      {showDescription && (
        <Grid item xs={12} sm={6}>
          <Typography>
            Showing {showingStart} - {showingEnd} of {total} Properties
          </Typography>
        </Grid>
      )}
      <Grid item className={classes.paginationV1} xs={12} sm={6}>
        <ListItem
          className={clsx(
            classes.prevPagination,
            (!page || page === 1) && classes.disabled
          )}
          onClick={handPrevPagination}
        >
          <ArrowLeft />
          Previous
        </ListItem>

        {listPage()}

        <ListItem
          className={clsx(
            classes.nextPagination,
            page === totalPage && classes.disabled
          )}
          onClick={handNextPagination}
        >
          Next
          <ArrowRight />
        </ListItem>
      </Grid>
    </Grid>
  );
}
