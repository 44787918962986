import * as React from 'react';
import {
  Box,
  InputLabel,
  MenuItem,
  Select as MUISelect,
  SelectProps as MUISelectProps,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';

import useIsScreenType from '../../../utils/dom/useIsScreenType';
import { ScreenType } from '../../../config';

import useStyles from './Select.styles';

export type SelectOption = string | { label?: string; value: string | number };

export interface SelectProps extends Omit<MUISelectProps, 'ref'> {
  label?: string;
  placeholder?: string;
  options: SelectOption[];
  htmlFor?: string;

  dropdownClassName?: string;
  placeholderClassName?: string;

  minWidth?: number;
  fullWidth?: boolean;
  styleCustomized?: boolean;
  labelClassName?: string;
  wrapClassName?: string;
  marginBottom?: number;
}

const NativeOption = (props: React.ComponentPropsWithoutRef<'option'>) => (
  <option {...props} />
);

function Select(
  {
    htmlFor,
    label,
    placeholder,
    className,
    labelClassName,
    wrapClassName,
    dropdownClassName,
    placeholderClassName,
    onChange,
    fullWidth = false,
    variant = 'standard',
    styleCustomized = true,
    minWidth = 210,
    options = [],
    marginBottom = 14,
    ...rest
  }: SelectProps,
  ref
) {
  const classes = useStyles({ minWidth, fullWidth, marginBottom });
  const isMobile = useIsScreenType(ScreenType.MOBILE);

  const [hasValue, setHasValue] = React.useState(false);

  // We render native <option> elements in mobile view and <MenuItem>
  // otherwise. This is to prevent different select behaviour in Safari
  // mobile.
  const Option = isMobile ? NativeOption : MenuItem;
  const optionElements = options.map((option) => {
    const optionElementProps =
      typeof option === 'string'
        ? {
            key: option,
            value: option,
            children: option,
          }
        : {
            key: option.value,
            value: option.value,
            children:
              option.label !== undefined ? option.label : `${option.value}`,
          };

    // Disabled because it seems ESLint couldn't see that we already have the
    // key props in 'optionElementProps'.
    // eslint-disable-next-line react/jsx-key
    return <Option {...optionElementProps} />;
  });

  const onSelectChange = (
    // 'value' can be either a string or an array (if 'multiple' is true)
    e: React.ChangeEvent<{ name?: string; value: unknown }>,
    // Note: 'child' is only available when 'native' is false
    child?: React.ReactNode
  ) => {
    if (onChange) {
      onChange(e, child);
    }

    const value = e.target.value;

    if (
      (Array.isArray(value) && value.length) ||
      (typeof value === 'string' && value.trim() !== '')
    ) {
      setHasValue(true);
    } else {
      setHasValue(false);
    }
  };

  return (
    <Box className={clsx(styleCustomized && classes.wrapStyled, wrapClassName)}>
      {label && (
        <InputLabel
          className={clsx(
            styleCustomized && classes.labelStyled,
            labelClassName
          )}
          htmlFor={htmlFor}
        >
          {label}
        </InputLabel>
      )}
      {placeholder && !isMobile && (
        <Typography
          component="span"
          className={clsx(
            classes.placeholderStyled,
            hasValue ? classes.hasValue : '',
            placeholderClassName
          )}
        >
          {placeholder}
        </Typography>
      )}
      <MUISelect
        {...rest}
        ref={ref}
        className={clsx(
          styleCustomized && classes.selectStyled,
          !placeholder && classes.noPlaceHolder,
          className
        )}
        variant={styleCustomized ? 'outlined' : variant}
        MenuProps={{
          PopoverClasses: {
            paper: clsx(
              styleCustomized && classes.dropdownStyled,
              dropdownClassName
            ),
          },
          getContentAnchorEl: null,
        }}
        native={isMobile}
        onChange={onSelectChange}
        fullWidth={fullWidth}
      >
        {optionElements}
      </MUISelect>
    </Box>
  );
}

export default React.forwardRef<HTMLSelectElement, SelectProps>(Select);
