import * as React from 'react';
import { SvgIconProps } from '@material-ui/core';
import SvgIconBtr from './SvgIconBtr';

export default function BtrBreakClause(props: SvgIconProps) {
  return (
    <SvgIconBtr {...props}>
      <path d="M26.24,31.75l-.45.25a13.56,13.56,0,1,1,5.9-6.12" />
      <line x1="16.6" y1="13.67" x2="28.94" y2="13.67" />
      <line x1="14.49" y1="13.67" x2="11.24" y2="13.67" />
      <line x1="16.6" y1="17.74" x2="28.94" y2="17.74" />
      <line x1="14.49" y1="17.74" x2="11.24" y2="17.74" />
      <line x1="16.6" y1="21.81" x2="28.94" y2="21.81" />
      <line x1="14.49" y1="21.81" x2="11.24" y2="21.81" />
      <line x1="16.6" y1="25.88" x2="28.94" y2="25.88" />
      <line x1="14.49" y1="25.88" x2="11.24" y2="25.88" />
      <path d="M34.09,27.69a13.48,13.48,0,0,1-1.92,2.95,13.62,13.62,0,0,1-3.53,2.92" />
    </SvgIconBtr>
  );
}
