import * as React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles<
  Theme,
  { resize: React.CSSProperties['resize'] }
>((theme: Theme) =>
  createStyles({
    markdownStyled: ({ resize }) => ({
      border: `1px solid ${theme.palette.gray.dark}`,
      borderRadius: theme.spacing(0.5),
      overflow: 'hidden',
      '& .mde-textarea-wrapper .mde-text': {
        resize: resize,
      },
    }),

    toolbarStyled: {},

    textareaStyled: () => ({
      fontSize: '0.875rem',
      fontFamily: 'inherit',
    }),

    previewStyled: {},

    labelStyled: {
      fontSize: theme.typography.pxToRem(16),
      fontFamily: theme.typography.fontFamily,
      fontWeight: theme.typography.fontWeightSemiBold,
      marginBottom: 14,
      gap: theme.spacing(1),
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(14),
      },
    },

    wrapStyled: {
      marginBottom: 14,
    },

    labelCtn: {
      display: 'flex',
      gap: theme.spacing(1),
      alignItems: 'center',
    },
  })
);
