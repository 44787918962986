import * as React from 'react';
import { SvgIconProps } from '@material-ui/core';
import SvgIconBtr from './SvgIconBtr';

export default function BtrFamily(props: SvgIconProps) {
  return (
    <SvgIconBtr {...props}>
      <path d="M7,30.25v-8a3.94,3.94,0,0,1,4-3.94h0a3.94,3.94,0,0,1,3.94,3.94v8" />
      <path d="M25.16,30.25V24.41a4,4,0,0,1,3.94-3.95h0a4,4,0,0,1,3.95,3.95v5.84" />
      <path d="M17.13,30.25V26.59A2.87,2.87,0,0,1,20,23.72h0a2.87,2.87,0,0,1,2.87,2.87v3.66" />
      <circle cx="10.9" cy="13.25" r="3.49" />
      <circle cx="29.1" cy="15.6" r="3.49" />
      <circle cx="20" cy="20.24" r="2.23" />
    </SvgIconBtr>
  );
}
