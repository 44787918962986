import { createStyles, makeStyles, Theme } from '@material-ui/core';

export type PlayButtonSize = 'small' | 'large';

const getPlayButtonSize = (theme: Theme, size: PlayButtonSize) => {
  switch (size) {
    case 'small':
      return {
        width: theme.spacing(2.5),
        height: theme.spacing(2.5),
        fontSize: theme.spacing(2),
      };
    case 'large':
      return {
        width: theme.spacing(6.25),
        height: theme.spacing(6.25),
        fontSize: theme.spacing(5),
      };
    default:
      return {
        width: theme.spacing(2.5),
        height: theme.spacing(2.5),
        fontSize: theme.spacing(2.5),
      };
  }
};

const useStyles = makeStyles<Theme, { size: 'small' | 'large' }>(
  (theme: Theme) =>
    createStyles({
      playButton: ({ size }) => ({
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',

        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        ...getPlayButtonSize(theme, size),
        borderRadius: '50%',

        color: theme.palette.gray.contrastText,
        backgroundColor: theme.palette.common.white,
        '&:hover': {
          backgroundColor: theme.palette.common.white,
        },
      }),
    })
);

export default useStyles;
