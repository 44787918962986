export const DEFAULT_LOCALE = 'en-GB';

/**
 * Return the locale that the user's browser is using
 */
export default function getLocale(): string {
  if (typeof navigator !== 'undefined') {
    if (navigator.languages) {
      return navigator.languages[0];
    }

    if (navigator.language) {
      return navigator.language;
    }
  }

  return DEFAULT_LOCALE;
}
