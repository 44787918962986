import * as React from 'react';
import { SvgIconProps, Tooltip } from '@material-ui/core';
import BtrDetail from '../../../../../images/iconsBtr/BtrDetail';
import { propertyAttributePredefinedIcon } from './propertyAttributes';

interface PropertyAttributeIconProps extends SvgIconProps {
  attribute: string;
  tooltip?: string;
}

interface TooltipWrapperProps {
  tooltip: string | undefined;
  children: React.ReactElement<any, any>;
}

function TooltipWrapper({ tooltip, children }: TooltipWrapperProps) {
  if (tooltip) {
    return <Tooltip title={tooltip}>{children}</Tooltip>;
  }

  return <>{children}</>;
}

export default function PropertyAttributeIcon({
  attribute,
  tooltip,
  ...rest
}: PropertyAttributeIconProps) {
  const IconPredefined = propertyAttributePredefinedIcon[attribute];

  if (!IconPredefined) {
    return (
      <TooltipWrapper tooltip={tooltip}>
        <BtrDetail {...rest} />
      </TooltipWrapper>
    );
  }

  return (
    <TooltipWrapper tooltip={tooltip}>
      <IconPredefined {...rest} />
    </TooltipWrapper>
  );
}
