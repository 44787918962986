import * as React from 'react';
import { SvgIconProps } from '@material-ui/core';
import SvgIconBtr from './SvgIconBtr';

export default function BtrCinema(props: SvgIconProps) {
  return (
    <SvgIconBtr {...props}>
      <rect
        x="5.78"
        y="16.4"
        width="21.17"
        height="17.03"
        rx="3.77"
        ry="3.77"
      />
      <polygon points="34.22 31.57 26.95 28.68 26.95 21.14 34.22 18.25 34.22 31.57" />
      <circle cx="11.43" cy="12.42" r="3.97" />
      <circle cx="20.76" cy="11.49" r="4.91" />
      <line x1="9.94" y1="21.68" x2="22.78" y2="21.68" />
    </SvgIconBtr>
  );
}
