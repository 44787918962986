export const API_HOST = process.env.GATSBY_API_HOST;

const API_ENDPOINTS = {
  // Account administration

  MY_DETAILS: `${process.env.GATSBY_API_HOST}/api/me/myDetails`,

  // Pre-login account APIs are public

  ACCOUNT_ADMINISTRATION_INITIATE_RESET_PASSWORD: `${API_HOST}/api/auth/resetpassword`,
  ACCOUNT_ADMINISTRATION_RESET_PASSWORD: (resetPasswordToken: string) =>
    `${API_HOST}/api/auth/resetpassword/${resetPasswordToken}`,

  // Properties APIs are public

  PROPERTIES: `${API_HOST}/api/properties`,
  POPULAR_PROPERTIES: `${API_HOST}/api/properties/analytics/popular`,
  PROMOTED_PROPERTIES: `${API_HOST}/api/properties/promoted`,
  BASIC_PROPERTIES_ANALYTICS: `${API_HOST}/api/properties/analytics/basic`,

  // Agencies APIs are public

  AGENCIES_CITIES: `${API_HOST}/api/agencies/cities`,
  AGENCIES_COUNCILS: (city: string) =>
    `${API_HOST}/api/agencies/councils?city=${city}`,
  AGENCIES_SEND_EMAILS: `${API_HOST}/api/agencies/emails`,

  // Contact APIs are public

  CONTACT_SEND_EMAIL_TO_US: `${API_HOST}/api/contact/emailUs`,

  // Statistics APIs are public

  AREA_RENTAL_DATA: `${API_HOST}/api/stats/rental`,

  // Developments APIs are private (need to log in)

  BASIC_DEVELOPMENT_ANALYTICS: (developmentId: number) =>
    `${API_HOST}/api/developments/analytics/basic/${developmentId}`,
  BASIC_DEVELOPMENTS_ANALYTICS: `${API_HOST}/api/developments/analytics/basic`,

  CREATE_DEVELOPMENT: `${API_HOST}/api/development`,

  EDIT_DEVELOPMENT_ADD_DEVELOPMENT_VIDEO: (developmentId: number) =>
    `${API_HOST}/api/development/${developmentId}/video`,
  EDIT_DEVELOPMENT_ADD_DEVELOPMENT_VIDEO_V2: (developmentId: number) =>
    `${API_HOST}/api/development/${developmentId}/videov2`,

  // Organization APIs (private)

  GET_ORGANIZATION_LANDLORDS: `${API_HOST}/api/orgPvt/landlords`,

  // My account analytics APIs (private)
  GET_ANALYTICS_DEVELOPMENT_PRIVATE: (developmentId: number) =>
    `${API_HOST}/api/analyticsPvt/development/${developmentId}`,

  GET_INDIVIDUAL_USER_ANALYTICS: (userId: string) =>
    `${API_HOST}/api/analyticsPvt/users/individual/${userId}`,
};

export default API_ENDPOINTS;
