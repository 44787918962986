import * as React from 'react';
import { insertClientSideCookieIfNotExists } from '../../utils/cookies';
import { COOKIE_EXPIRY, COOKIE_NAME } from '../../config/cookies';

/**
 * How long until the Agencies Form dialog shows up. It will only show up if it
 * has never been shown before (determined by a client-side cookie).
 */
const SHOW_AGENCIES_FORM_TIMEOUT = 15000; // 15s

/**
 * The Agencies Form Dialog only appears for the user once based on a
 * client-side cookie. This hook returns all necessary things to work with this.
 */
export default function useShouldShowAgenciesForm({
  developmentId,
}: {
  developmentId: number;
}) {
  const { data: shouldShowAgenciesFormInCookiesStr } =
    insertClientSideCookieIfNotExists({
      name: COOKIE_NAME.SHOULD_SHOW_AGENCIES_FORM,
      data: 'true',
      maxAge: COOKIE_EXPIRY.SHOULD_SHOW_AGENCIES_FORM_AFTER_CANCEL,
    });
  const shouldShowAgenciesFormInCookies =
    shouldShowAgenciesFormInCookiesStr === 'true';

  const [shouldShowAgenciesForm, setShouldShowAgenciesForm] =
    React.useState(false);

  React.useEffect(() => {
    const timerId = window.setTimeout(() => {
      if (shouldShowAgenciesFormInCookies && developmentId) {
        setShouldShowAgenciesForm(true);
      }
    }, SHOW_AGENCIES_FORM_TIMEOUT);

    return () => {
      window.clearTimeout(timerId);
    };
  }, [shouldShowAgenciesFormInCookies, developmentId]);

  return {
    shouldShowAgenciesForm,
    setShouldShowAgenciesForm,
  };
}
