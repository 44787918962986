import * as React from 'react';
import { SvgIconProps } from '@material-ui/core';
import SvgIconBtr from './SvgIconBtr';

export default function BtrSmokeAlarm(props: SvgIconProps) {
  return (
    <SvgIconBtr {...props}>
      <path d="M28,9.49H4.13a9.75,9.75,0,0,0,9.75,9.76h4.54a9.76,9.76,0,0,0,9.76-9.76H28Z" />
      <path d="M11.31,31.11c0-1.58,2.47-1.58,2.47-3.16s-2.47-1.59-2.47-3.17,2.47-1.58,2.47-3.17" />
      <path d="M15.32,31.11c0-1.58,2.46-1.58,2.46-3.16s-2.46-1.59-2.46-3.17,2.46-1.58,2.46-3.17" />
      <path d="M19.32,31.11c0-1.58,2.47-1.58,2.47-3.16s-2.47-1.59-2.47-3.17,2.47-1.58,2.47-3.17" />
      <line x1="31.78" y1="14.37" x2="35.87" y2="14.37" />
      <line x1="31.78" y1="17.26" x2="35.87" y2="19.64" />
      <line x1="35.88" y1="8.89" x2="31.78" y2="11.27" />
      <line x1="4.97" y1="13.47" x2="27.33" y2="13.47" />
      <line x1="16.55" y1="19.25" x2="16.55" y2="13.47" />
    </SvgIconBtr>
  );
}
