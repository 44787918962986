import * as React from 'react';
import {
  FormControl,
  InputLabel,
  InputLabelProps,
  MUIMenuItem as MenuItem,
  MUISelect as Select,
  MUISelectProps as SelectProps,
} from '../../atoms';

function NativeOption({
  children,
  ...rest
}: { children?: React.ReactNode } & Partial<
  React.HTMLAttributes<HTMLOptionElement>
>) {
  return <option {...rest}>{children}</option>;
}

export interface SelectOption {
  label?: string;
  value: string;
}

export interface SelectWithLabelProps extends Exclude<SelectProps, 'children'> {
  /**
   * Content of the label.
   */
  label: string | React.ReactNode;

  /**
   * Additional props to be passed to the underlying InputLabel component.
   */
  inputLabelProps?: Partial<InputLabelProps>;

  /**
   * The select element's options.
   */
  options?: (SelectOption | string)[];
}

export default function SelectWithLabel({
  label,
  inputLabelProps,
  native,
  options = [],
  ...rest
}: SelectWithLabelProps) {
  const OptionComponent = native ? NativeOption : MenuItem;

  return (
    <FormControl>
      <InputLabel {...inputLabelProps}>{label}</InputLabel>
      <Select {...rest}>
        {options.map((option) =>
          typeof option === 'string' ? (
            <OptionComponent key={option} value={option}>
              {option}
            </OptionComponent>
          ) : (
            <OptionComponent key={option.value} value={option.value}>
              {option.label ?? option.value}
            </OptionComponent>
          )
        )}
      </Select>
    </FormControl>
  );
}
