import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    snackbar: {
      borderRadius: 10,
    },

    normalSnackbar: {
      backgroundColor: theme.palette.grey['900'],
      color: theme.palette.common.white,
    },

    errorSnackbar: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
    },

    successSnackbar: {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.error.contrastText,
    },

    contentCtn: {
      display: 'flex',
      alignItems: 'center',

      padding: theme.spacing(2),
      borderRadius: 10,

      '& > *:first-child': {
        marginRight: theme.spacing(1),
      },
    },
  })
);

export default useStyles;
