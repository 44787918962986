import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles<
  Theme,
  { fullWidth: boolean | undefined; buttonBackgroundColor: string | undefined }
>((theme: Theme) =>
  createStyles({
    ctn: {
      display: 'flex',
      width: ({ fullWidth }) => (fullWidth ? '100%' : 'auto'),
      padding: theme.spacing(0.75),
      backgroundColor: ({ buttonBackgroundColor }) =>
        buttonBackgroundColor ?? 'transparent',
    },

    iconCtn: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },

    text: {
      flex: '1 1 auto',
    },
  })
);

export default useStyles;
