import useScreenType from './useScreenType';
import { ScreenType } from '../../config';

const screenTypeMatches: Record<ScreenType, ScreenType[]> = {
  [ScreenType.DESKTOP]: [
    ScreenType.DESKTOP,
    ScreenType.TABLET_LANDSCAPE,
    ScreenType.TABLET_PORTRAIT,
    ScreenType.MOBILE,
  ],
  [ScreenType.TABLET_LANDSCAPE]: [
    ScreenType.TABLET_LANDSCAPE,
    ScreenType.TABLET_PORTRAIT,
    ScreenType.MOBILE,
  ],
  [ScreenType.TABLET_PORTRAIT]: [ScreenType.TABLET_PORTRAIT, ScreenType.MOBILE],
  [ScreenType.MOBILE]: [ScreenType.MOBILE],
};

/**
 * Given a screen type, return true if the current screen type is the given
 * screen type or smaller.
 */
export default function useIsScreenType(screenType: ScreenType): boolean {
  const currentScreenType = useScreenType();

  return screenTypeMatches[screenType].includes(currentScreenType);
}
