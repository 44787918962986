import * as React from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import clsx from 'clsx';
import { Box, BoxProps } from '@material-ui/core';

import useStyles from './UploadArea.styles';

export interface UploadAreaProps {
  onDrop: (acceptedFiles: File[]) => void;
  dropzoneOpts?: DropzoneOptions;
  ctnProps?: BoxProps;
}

export default function UploadArea({
  onDrop,
  ctnProps,
  dropzoneOpts,
}: UploadAreaProps) {
  const { className: ctnPropsClassName, ...ctnPropsRest } = ctnProps ?? {};

  const classes = useStyles();

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    ...dropzoneOpts,
  });

  return (
    <Box
      className={clsx(classes.ctn, ctnPropsClassName)}
      {...ctnPropsRest}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
    </Box>
  );
}
