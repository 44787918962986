import * as React from 'react';
import { SvgIconProps } from '@material-ui/core';
import SvgIconBtr from './SvgIconBtr';

export default function BtrGameConsole(props: SvgIconProps) {
  return (
    <SvgIconBtr {...props}>
      <path d="M27.64,26.84l4.12,3s3.36,1.43,4.07-2.75S33.18,11.21,30,10.19s-6.92,1.22-9.57,1.22h-.92c-2.65,0-6.41-2.24-9.57-1.22s-6.51,12.72-5.8,16.9,4.07,2.75,4.07,2.75l4.12-3A13,13,0,0,1,20,24.35h0A13,13,0,0,1,27.64,26.84Z" />
      <path d="M14.49,16.43h-1v-.95a1.35,1.35,0,1,0-2.7,0v.95H9.9a1.35,1.35,0,0,0,0,2.7h.94v.94a1.35,1.35,0,1,0,2.7,0v-.94h1a1.35,1.35,0,0,0,0-2.7Z" />
      <circle cx="26.77" cy="14.84" r="0.74" />
      <circle cx="26.77" cy="20.74" r="0.74" />
      <circle cx="23.81" cy="17.79" r="0.74" />
      <circle cx="29.72" cy="17.79" r="0.74" />
    </SvgIconBtr>
  );
}
