import * as React from 'react';
import { SvgIconProps } from '@material-ui/core';
import SvgIconBtr from './SvgIconBtr';

export default function BtrDiningRoom(props: SvgIconProps) {
  return (
    <SvgIconBtr {...props}>
      <line x1="12.79" y1="20.48" x2="27.21" y2="20.48" />
      <line x1="21.41" y1="31.92" x2="18.59" y2="31.92" />
      <line x1="20" y1="20.48" x2="20" y2="31.92" />
      <path d="M12.61,28.92H3.22A2.72,2.72,0,0,1,.5,26.2V15a2.72,2.72,0,0,1,5.44,0v8.52h6.67a2.72,2.72,0,0,1,0,5.44Z" />
      <line x1="4.28" y1="31.92" x2="4.28" y2="28.92" />
      <line x1="12.14" y1="31.92" x2="12.14" y2="28.92" />
      <line x1="12.93" y1="31.92" x2="11.35" y2="31.92" />
      <line x1="5.07" y1="31.92" x2="3.49" y2="31.92" />
      <path d="M27.39,28.92h9.39A2.72,2.72,0,0,0,39.5,26.2V15a2.72,2.72,0,1,0-5.44,0v8.52H27.39a2.72,2.72,0,1,0,0,5.44Z" />
      <line x1="35.72" y1="28.92" x2="35.72" y2="31.92" />
      <line x1="27.86" y1="28.92" x2="27.86" y2="31.92" />
      <line x1="27.07" y1="31.92" x2="28.65" y2="31.92" />
      <line x1="34.93" y1="31.92" x2="36.51" y2="31.92" />
      <path d="M22.35,13.79a2.61,2.61,0,1,0-5.21,0Z" />
      <line x1="19.75" y1="11.18" x2="19.75" y2="8.08" />
    </SvgIconBtr>
  );
}
