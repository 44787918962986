import * as React from 'react';
import { SvgIconProps } from '@material-ui/core';
import SvgIconBtr from './SvgIconBtr';

export default function BtrWardrobe(props: SvgIconProps) {
  return (
    <SvgIconBtr {...props}>
      <rect x="8.19" y="3.76" width="23.61" height="30.06" />
      <line x1="20" y1="3.76" x2="20" y2="26.8" />
      <line x1="8.19" y1="26.8" x2="31.81" y2="26.8" />
      <line x1="22.56" y1="13.53" x2="22.56" y2="17.82" />
      <line x1="17.48" y1="13.53" x2="17.48" y2="17.82" />
      <line x1="18.82" y1="30.35" x2="21.38" y2="30.35" />
      <line x1="10.66" y1="33.83" x2="10.66" y2="36.24" />
      <line x1="29.47" y1="33.83" x2="29.47" y2="36.24" />
    </SvgIconBtr>
  );
}
