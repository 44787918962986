import * as React from 'react';
import { getItemFromLocalStorage } from '../../utils/localStorage';
import { EnquireFormDialogTenantData } from '../../components/organisms';

export interface EnquireFormTenantData {
  name: string;
  email: string;
  phone: string;
  bedroom: string;
  furnishedPref: string;
  numberOfTenants: string;
  numberOfAdults: string;
  numberOfChildrenAndTheirAges: string;
  relationships: string;
  moveInDate: string;
  budget: string;
  occupation: string;
  income: string;
  hasPets: string;
  hasBadDebts: string;
  hasReferences: string;
  viewingAvailability: string;
}

export interface UseEnquireFormTenantDataArgs {
  /**
   * If provided, will retrieve initial data from local storage using this key.
   *
   * Values from the 'initialData' argument can override values retrieved from
   * local storage.
   */
  storageKey?: string;

  /**
   * If provided, will use this as the initial data.
   *
   * Values provided here will override values retrieved from local storage.
   */
  initialData?: Partial<EnquireFormTenantData>;
}

export default function useEnquireFormTenantData({
  storageKey,
  initialData,
}: UseEnquireFormTenantDataArgs = {}) {
  const initialDataFromStorage: Partial<EnquireFormTenantData> = storageKey
    ? getItemFromLocalStorage<EnquireFormDialogTenantData>({
        key: storageKey,
        options: {
          parseJson: true,
        },
      }) ?? {}
    : {};

  const {
    name: initialName,
    email: initialEmail,
    phone: initialPhone,
    bedroom: initialBedroom,
    furnishedPref: initialFurnishedPref,
    numberOfTenants: initialNumberOfTenants,
    numberOfAdults: initialNumberOfAdults,
    numberOfChildrenAndTheirAges: initialNumberOfChildrenAndTheirAges,
    relationships: initialRelationships,
    moveInDate: initialMoveInDate,
    budget: initialBudget,
    occupation: initialOccupation,
    income: initialIncome,
    hasPets: initialHasPets,
    hasBadDebts: initialHasBadDebts,
    hasReferences: initialHasReferences,
    viewingAvailability: initialViewingAvailability,
  } = {
    ...initialDataFromStorage,
    ...initialData,
  };

  const [name, setName] = React.useState(initialName ?? '');
  const [email, setEmail] = React.useState(initialEmail ?? '');
  const [phone, setPhone] = React.useState(initialPhone ?? '');
  const [bedroom, setBedroom] = React.useState(initialBedroom ?? '');
  const [furnishedPref, setFurnishedPref] = React.useState(
    initialFurnishedPref ?? ''
  );
  const [numberOfTenants, setNumberOfTenants] = React.useState(
    initialNumberOfTenants ?? ''
  );
  const [numberOfAdults, setNumberOfAdults] = React.useState(
    initialNumberOfAdults ?? ''
  );
  const [numberOfChildrenAndTheirAges, setNumberOfChildrenAndTheirAges] =
    React.useState(initialNumberOfChildrenAndTheirAges ?? '');
  const [relationships, setRelationships] = React.useState(
    initialRelationships ?? ''
  );
  const [moveInDate, setMoveInDate] = React.useState(initialMoveInDate ?? '');
  const [budget, setBudget] = React.useState(initialBudget ?? '');
  const [occupation, setOccupation] = React.useState(initialOccupation ?? '');
  const [income, setIncome] = React.useState(initialIncome ?? '');
  const [hasPets, setHasPets] = React.useState(initialHasPets ?? '');
  const [hasBadDebts, setHasBadDebts] = React.useState(
    initialHasBadDebts ?? ''
  );
  const [hasReferences, setHasReferences] = React.useState(
    initialHasReferences ?? ''
  );
  const [viewingAvailability, setViewingAvailability] = React.useState(
    initialViewingAvailability ?? ''
  );

  return {
    name,
    setName,
    email,
    setEmail,
    phone,
    setPhone,
    bedroom,
    setBedroom,
    furnishedPref,
    setFurnishedPref,
    numberOfTenants,
    setNumberOfTenants,
    numberOfAdults,
    setNumberOfAdults,
    numberOfChildrenAndTheirAges,
    setNumberOfChildrenAndTheirAges,
    relationships,
    setRelationships,
    moveInDate,
    setMoveInDate,
    budget,
    setBudget,
    occupation,
    setOccupation,
    income,
    setIncome,
    hasPets,
    setHasPets,
    hasBadDebts,
    setHasBadDebts,
    hasReferences,
    setHasReferences,
    viewingAvailability,
    setViewingAvailability,
  };
}
