import * as React from 'react';
import { useAgencyCities, useAgencyCouncils } from '../../api/agencies';
import {
  useAreaRentalData,
  RentalStatisticsRoomCategory,
} from '../../api/statistics';

/**
 * The API's rental statistics uses a different format for bedroom types. This
 * maps the front-end's bedroom types to the API's bedroom types.
 */
export const bedroomToRoomCategoryMap: Record<
  string,
  RentalStatisticsRoomCategory
> = {
  Studio: 'studio',
  '1 Bedroom': '1br',
  '2 Bedrooms': '2br',
  '3 Bedrooms': '3br',
  '4 Bedrooms': '4br+',
  Shared: 'room',
  'Shared Ensuite': 'room',
};

export interface UseAgenciesPreferencesArgs {
  bedroom?: string;
}

/**
 * This hook returns certain states for the Agencies Form. Their data comes from
 * our API.
 */
export default function useAgenciesPreferences({
  bedroom,
}: UseAgenciesPreferencesArgs = {}) {
  const [agenciesCity, setAgenciesCity] = React.useState('');
  const [agenciesCouncil, setAgenciesCouncil] = React.useState<string[]>([]);

  const { data: agenciesCitiesData } = useAgencyCities();
  const { data: agenciesCouncilsData } = useAgencyCouncils({
    city: agenciesCity,
    queryConfig: {
      enabled: !!agenciesCity,
    },
  });

  const agenciesCouncilsAreaCodeMap = agenciesCouncilsData?.meta.areaCode ?? {};
  const selectedAreaCodes = agenciesCouncil.includes('ALL')
    ? Object.values(agenciesCouncilsAreaCodeMap).filter(
        (areaCode) => !!areaCode
      )
    : agenciesCouncil
        .map((agencyCouncil) => agenciesCouncilsAreaCodeMap[agencyCouncil])
        .filter((areaCode) => !!areaCode);
  const selectedAreaCodesStr = selectedAreaCodes.join(',');

  const { data: areaRentalData } = useAreaRentalData({
    areaCode: selectedAreaCodesStr,
    roomCategory: bedroomToRoomCategoryMap[bedroom ?? ''],
    queryConfig: {
      enabled: !!(selectedAreaCodesStr && bedroom),

      // Skip all retries as this query can return 404 quite a lot (due to
      // insufficient statistics data).
      retry: false,
    },
  });

  return {
    agenciesCity,
    setAgenciesCity,
    agenciesCouncil,
    setAgenciesCouncil,
    agenciesCities: agenciesCitiesData?.data ?? [],
    agenciesCouncils: agenciesCouncilsData?.data ?? [],
    areaRentalData,
  };
}
