import * as React from 'react';
import { SvgIconProps } from '@material-ui/core';
import SvgIconBtr from './SvgIconBtr';

export default function BtrBedSize(props: SvgIconProps) {
  return (
    <SvgIconBtr {...props}>
      <path d="M10.2,14.71V11.05A3.09,3.09,0,0,1,13.29,8H26.71a3.09,3.09,0,0,1,3.09,3.09v3.66" />
      <polyline points="7.66 24.14 10.2 14.71 26.91 14.71 29.8 14.71 32.46 24.33" />
      <path d="M9.22,23.43H30.78a1.87,1.87,0,0,1,1.87,1.87v5a0,0,0,0,1,0,0H7.34a0,0,0,0,1,0,0v-5A1.87,1.87,0,0,1,9.22,23.43Z" />
      <line x1="8.77" y1="30.28" x2="8.77" y2="32.04" />
      <line x1="30.99" y1="30.28" x2="30.99" y2="32.04" />
      <line x1="28.2" y1="18.81" x2="11.8" y2="18.81" />
      <polyline points="25.32 16.35 28.2 18.83 25.37 21.26" />
      <polyline points="14.68 16.35 11.8 18.83 14.63 21.26" />
    </SvgIconBtr>
  );
}
