// https://developers.google.com/maps/documentation/javascript/reference/places-service#PlacesServiceStatus
import {
  Geometry,
  LatLng,
  LatLngBounds,
  LatLngBoundsLiteral,
  LatLngLiteral,
} from '../gcpMaps/types';

// https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompleteSessionToken
export type AutocompleteSessionToken = any;

// https://developers.google.com/maps/documentation/javascript/reference/places-service#PlacesServiceStatus
export enum PlacesServiceStatus {
  INVALID_REQUEST = 'INVALID_REQUEST',
  NOT_FOUND = 'NOT_FOUND',
  OK = 'OK',
  OVER_QUERY_LIMIT = 'OVER_QUERY_LIMIT',
  REQUEST_DENIED = 'REQUEST_DENIED',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
  ZERO_RESULTS = 'ZERO_RESULTS',
}

export interface PredictionSubString {
  // Length of the substring
  length: number;
  // The offset to the substring's start within the description string
  offset: number;
}

export interface StructuredFormatting {
  // Usually the place's name
  main_text: string;
  main_text_matched_substrings: PredictionSubString[];
  // Usually the place's location
  secondary_text: string;
}

export interface PredictionTerm {
  // The offset of the start of this term in the place's description
  offset: number;
  // The value of this term
  value: string;
}

// https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompletePrediction
export interface AutocompletePrediction {
  description: string;
  matched_substrings: PredictionSubString[];
  place_id: string;
  structured_formatting: StructuredFormatting;
  terms: PredictionTerm[];
  types: string[];
}

export interface AddressComponent {
  // The full text of the address component
  long_name: string;
  // The abbreviated, short text of the address component
  short_name: string;
  types: string[];
}

// https://developers.google.com/maps/documentation/javascript/reference/places-service#PlaceResult
// Note: our interface is incomplete. We only extract out the properties that
// we use
export interface PlaceResult {
  place_id?: string;
  types?: string[];

  // Be careful when using: see code injection note here:
  // https://developers.google.com/maps/documentation/javascript/reference/places-service#PlaceResult.name
  name?: string;

  geometry?: Geometry;
  formatted_address?: string;
  address_components?: AddressComponent[];
}

// https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#ComponentRestrictions
export interface ComponentRestrictions {
  // ISO 3166-1 Alpha-2 country code, case insensitive
  country: string | string[];
}

export type AutocompletionRequestTypes =
  | 'geocode'
  | 'address'
  | 'establishment'
  | '(regions)'
  | '(cities)';

export interface AutocompletionRequest {
  // User-entered input string
  input: string;
  // The character position in the input term at which the service uses text for
  // predictions (the position of the cursor in the input field).
  offset?: number;

  // Used to restrict predictions to only those within the parent component.
  componentRestrictions?: ComponentRestrictions;

  // The location where distance_meters is calculated from
  origin?: LatLng | LatLngLiteral;

  // Bounds for prediction biasing. Both 'location' and 'radius' will be ignored
  // if 'bounds' is set.
  bounds?: LatLngBounds | LatLngBoundsLiteral;

  // Predictions will be biased towards the given 'location' and 'radius'.
  // Alternatively, 'bounds' can be used.
  location?: LatLng;
  // Radius in meters. Must ALWAYS be accompanied by the 'location' property.
  radius?: number;

  // If nothing is specified, all types are returned.
  // In general, only a single type is allowed.
  // Exception: mixing 'geocode' and 'establishment' has the same effect as
  // specifying no types.
  // https://developers.google.com/maps/documentation/places/web-service/supported_types#table3
  types?: AutocompletionRequestTypes[];

  sessionToken?: AutocompleteSessionToken;
}

export interface AutocompleteResponse {
  predictions: AutocompletePrediction[];
}

declare function autocompleteServiceGetPlacePredictions(
  request: AutocompletionRequest,
  callback?: (
    autocompletePredictions: AutocompletePrediction[],
    placesServiceStatus: PlacesServiceStatus
  ) => void
): void;

// https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service
export interface AutocompleteService {
  getPlacePredictions: typeof autocompleteServiceGetPlacePredictions;
  getQueryPredictions: unknown;
}

export interface PlaceDetailsRequest {
  placeId: string;

  // Fields to be included in the details response, which will be billed for.
  // https://developers.google.com/maps/billing/understanding-cost-of-use#places-product
  // If no fields are specified or ['ALL'] is passed in, ALL available fields
  // will be returned and billed for (don't do this).
  // List of fields: https://developers.google.com/maps/documentation/javascript/reference/places-service#PlaceResult
  // Specify nested fields like this: 'geometry.location'.
  fields?: Array<keyof PlaceResult>;
  sessionToken?: AutocompleteSessionToken;
}

declare function placesServiceGetDetails(
  request: PlaceDetailsRequest,
  callback: (
    placeResult: PlaceResult,
    placesServiceStatus: PlacesServiceStatus
  ) => void
): void;

export interface PlacesService {
  getDetails: typeof placesServiceGetDetails;
}
