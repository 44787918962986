import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import pubFetcher from '../utils/fetcher';
import API_ENDPOINTS from '../endpoints';

export interface SendAgencyEmailsArgs {
  agencyArea1: string[];
  agencyArea2: string;
  tenantName: string;
  tenantEmail: string;
  tenantPhone: string;
  tenantBedroom: string;
  tenantFurnishedPref: string;
  tenantNumberOfAdults: string;
  tenantNumberOfChildrenAndTheirAges: string;
  tenantRelationships: string;
  tenantMoveInDate: string;
  tenantBudget: string;
  tenantOccupation: string;
  tenantIncome: string;
  tenantHasPets: boolean;
  tenantHasBadDebts: boolean;
  tenantHasReferences: boolean;
  tenantViewingAvailability: string;
}

export interface SendAgencyEmailsResponse {
  data: string;
  meta: {
    count: number;
  };
}

export default function useSendAgencyEmails(
  queryConfig?: UseMutationOptions<
    SendAgencyEmailsResponse,
    Error,
    SendAgencyEmailsArgs
  >
) {
  return useMutation(
    (body) =>
      pubFetcher(API_ENDPOINTS.AGENCIES_SEND_EMAILS, {
        method: 'POST',
        body: JSON.stringify(body),
      }),
    queryConfig
  );
}
