// https://github.com/vercel/next.js/discussions/20784
import { CustomVariables } from './variables';
import { getClientIDGa } from '../storage/cookie';

declare global {
  interface Window {
    dataLayer: Record<string, unknown>[];
    onbeforeunload: () => void;
  }
}

/**
 * This calls the .push() method of Google Tag Manager's dataLayer object, if
 * it's available.
 *
 * Some additional information:
 * https://developers.google.com/tag-manager/devguide
 * https://support.google.com/tagmanager/answer/6164391?hl=en
 */
export default function gtmDataLayerPush(object: Record<string, unknown>) {
  if (window.dataLayer !== undefined) {
    if (!object[CustomVariables.USER_ID]) {
      object[CustomVariables.USER_ID] = getClientIDGa();
    }
    window.dataLayer.push(object);
  }
}
