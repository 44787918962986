/**
 * The first letter will be uppercase and the rest will be lowercase.
 *
 * Example: 'hello world' will be converted to 'Hello World'
 */
export default function toTitleCase(originalStr: string): string {
  return originalStr.replace(/\w\S*/g, function (txt) {
    return txt[0].toUpperCase() + txt.slice(1).toLowerCase();
  });
}
