import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    textStyled: {
      background: 'transparent',
      borderRadius: theme.spacing(2),
      '& input': {
        height: 'auto',
        padding: theme.spacing(2, 2.5),
        [theme.breakpoints.down('md')]: {
          fontSize: theme.typography.pxToRem(14),
        },
      },
      '& .MuiInputBase-root': {
        backgroundColor: '#fff',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderRadius: theme.spacing(1),
        borderColor: theme.palette.gray.dark,
      },
    },

    labelStyled: {
      fontSize: theme.typography.pxToRem(16),
      fontFamily: theme.typography.fontFamily,
      fontWeight: theme.typography.fontWeightSemiBold,
      marginBottom: 14,
      [theme.breakpoints.down('md')]: {
        fontSize: theme.typography.pxToRem(14),
      },
    },

    labelCtn: {
      display: 'flex',
      gap: theme.spacing(1),
      alignItems: 'center',
    },

    wrapStyled: {
      marginBottom: 14,
    },
  })
);
