import * as React from 'react';
import { Autocomplete, AutocompleteProps } from '@material-ui/lab';
import { AutocompletePrediction } from '../../../api/googleMaps/gcpPlaces/types';
import Option from './Option/Option';
import Input from './Input/Input';
import useStyles from './AddressAutocomplete.styles';

type ExtendedAutocompleteProps = Omit<
  AutocompleteProps<AutocompletePrediction, false, false, true>,
  | 'options'
  | 'onInputChange'
  | 'renderInput'
  | 'autoComplete'
  | 'freeSolo'
  | 'getOptionLabel'
  | 'getOptionSelected'
  | 'filterOptions'
  | 'filterSelectedOptions'
  | 'renderOption'
  | 'value'
  | 'onChange'
  | 'includeInputInList'
>;

export interface AddressAutocompleteProps extends ExtendedAutocompleteProps {
  // This function triggers when the "Enter" key is pressed in the input, or
  // when an autocomplete prediction is selected.
  action?: () => void;

  // Relates to the input field
  setInputValue: (newValue: string) => void;

  // Autocomplete prediction results from our Maps service. These will be
  // presented as options available for the user to select from.
  autocompletePredictions: AutocompletePrediction[] | null;

  // Relates to the autocomplete prediction selected by the user
  selectedAutocompletePrediction: AutocompletePrediction | null;
  selectAutocompletePrediction: (
    newValue: AutocompletePrediction | null
  ) => void;

  // If true, will not have rounded corners
  noRoundedCorners?: boolean;
}

export default function AddressAutocomplete({
  action,
  setInputValue,
  autocompletePredictions,
  selectedAutocompletePrediction,
  selectAutocompletePrediction,
  noRoundedCorners,
  ...rest
}: AddressAutocompleteProps) {
  const classes = useStyles();

  const onInputChange = (e: unknown, newValue: string) => {
    // We skip the first onInputChange action. See the comment for
    // inputChangeCountRef to see why.
    setInputValue(newValue);
  };

  const onInputKeydown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && action) {
      action();
    }
  };

  const onChange = (e: unknown, newValue: AutocompletePrediction | null) => {
    selectAutocompletePrediction(newValue);
  };

  return (
    <Autocomplete<AutocompletePrediction, false, false, true>
      data-testid="address-autocomplete"
      className={classes.addressAutocomplete}
      // ********************* //
      // ***** BEHAVIOUR ***** //
      // ********************* //
      // Autocomplete highlight behaviour
      autoComplete
      // User input is not bound to the provided options.
      freeSolo
      // The highlight can move to the input
      includeInputInList
      // ***************************************** //
      // ***** INPUT VALUE / ON INPUT CHANGE ***** //
      // ***************************************** //
      onInputChange={onInputChange}
      renderInput={(params) => (
        <Input
          {...params}
          noRoundedCorners={!!noRoundedCorners}
          onKeyDown={onInputKeydown}
        />
      )}
      // ******************* //
      // ***** OPTIONS ***** //
      // ******************* //
      options={autocompletePredictions ?? []}
      // A function that is used to fill the Input.
      // A function that determines if an option is selected, considering the
      // current value. In our case, we compare Places' ID with each other.
      getOptionSelected={(option, value) =>
        option && value ? option.place_id === value.place_id : false
      }
      // Hide the selected options from the option list.
      filterSelectedOptions
      // Options are what appear as the user types. They are presented as a list
      // of possible results for the user to select from.
      renderOption={(autocompletePrediction) => (
        <Option autocompletePrediction={autocompletePrediction} />
      )}
      getOptionLabel={(option) => option?.description ?? ''}
      // ***************************** //
      // ***** VALUE / ON CHANGE ***** //
      // ***************************** //
      // This pair relates to the option selected by the user.
      value={selectedAutocompletePrediction}
      onChange={onChange}
      // ********************************************** //
      // The rest of the props, allowing props override...
      {...rest}
    />
  );
}
