import * as React from 'react';
import { SvgIconProps } from '@material-ui/core';
import SvgIconBtr from './SvgIconBtr';

export default function BtrLift(props: SvgIconProps) {
  return (
    <SvgIconBtr {...props}>
      <polyline points="11.4 11.81 31.25 11.81 31.25 6.16 8.76 6.16 8.76 33.84 31.25 33.84 31.25 14.92" />
      <line x1="20" y1="11.81" x2="20" y2="33.84" />
      <line x1="18.13" y1="9.01" x2="21.87" y2="9.06" />
      <line x1="14.34" y1="18.48" x2="14.34" y2="27.73" />
      <polyline points="12.03 20.25 14.36 17.92 16.65 20.21" />
      <line x1="25.5" y1="27.17" x2="25.5" y2="17.92" />
      <polyline points="27.82 25.4 25.48 27.73 23.19 25.44" />
    </SvgIconBtr>
  );
}
