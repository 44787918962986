import { createStyles, makeStyles, Theme } from '@material-ui/core';
import * as React from 'react';

const useStyles = makeStyles<
  Theme,
  { textDecoration?: React.CSSProperties['textDecoration'] }
>((theme: Theme) =>
  createStyles({
    link: {
      textDecoration: ({ textDecoration }) => textDecoration ?? 'none',
    },

    withColor: {
      color: theme.palette.primary.main,
      '&:hover': {
        color: theme.palette.primary.dark,
      },
      '&:visited': {
        color: theme.palette.primary.main,
      },
      '&:visited:hover': {
        color: theme.palette.primary.dark,
      },
    },

    noColor: {
      color: 'inherit',
      '&:hover': {
        color: 'inherit',
      },
      '&:visited': {
        color: 'inherit',
      },
      '&:visited:hover': {
        color: 'inherit',
      },
    },

    fullSize: {
      display: 'block',
    },
  })
);

export default useStyles;
