import { Box } from '@material-ui/core';
import React from 'react';
import { StyledTabs, StyledTab } from './SpecialTabs.styles';

export interface SpecialTabInfo {
  label: string;
  key: string | number;
  tabPanelContent: React.ReactNode;
}

export interface SpecialTabsProps {
  tabs: SpecialTabInfo[];
  activeTab: string | number;
  onChangeTab: (event: React.ChangeEvent<Element>, value: number) => void;
}

export default function SpecialTabs({
  tabs,
  activeTab = 0,
  onChangeTab,
}: SpecialTabsProps) {
  return (
    <Box>
      <StyledTabs value={activeTab} onChange={onChangeTab}>
        {tabs.map((tab) => (
          <StyledTab key={tab.key} label={tab.label} value={tab.key} />
        ))}
      </StyledTabs>
      {tabs.map((tab) => (
        <Box key={tab.key} hidden={activeTab !== tab.key}>
          {tab.tabPanelContent}
        </Box>
      ))}
    </Box>
  );
}
