import * as React from 'react';
import { SvgIconProps } from '@material-ui/core';
import SvgIconBtr from './SvgIconBtr';

export default function BtrTvStand(props: SvgIconProps) {
  return (
    <SvgIconBtr {...props}>
      <rect x="0.5" y="11.16" width="39" height="14.58" />
      <line x1="4.51" y1="25.75" x2="4.51" y2="28.84" />
      <line x1="35.53" y1="25.75" x2="35.53" y2="28.84" />
      <line x1="9.38" y1="11.16" x2="9.38" y2="25.75" />
      <line x1="30.42" y1="11.16" x2="30.42" y2="25.75" />
      <line x1="9.38" y1="19.84" x2="30.42" y2="19.84" />
      <line x1="7.43" y1="16.86" x2="7.43" y2="19.84" />
      <line x1="32.24" y1="16.86" x2="32.24" y2="19.84" />
    </SvgIconBtr>
  );
}
