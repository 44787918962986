import { SvgIconProps } from '@material-ui/core';
import { PropertyV2 } from '../../../../../api/property/types';
import { lodashCaseToNormalCase } from '../../../../../utils/string';
import BtrDishwasher from '../../../../../images/iconsBtr/BtrDishwasher';
import BtrDryer from '../../../../../images/iconsBtr/BtrDryer';
import BtrFridge from '../../../../../images/iconsBtr/BtrFridge';
import BtrHob from '../../../../../images/iconsBtr/BtrHob';
import BtrMicrowave from '../../../../../images/iconsBtr/BtrMicrowave';
import BtrOven from '../../../../../images/iconsBtr/BtrOven';
import BtrWashingMachine from '../../../../../images/iconsBtr/BtrWashingMachine';
import BtrTerrace from '../../../../../images/iconsBtr/BtrTerrace';
import BtrBath from '../../../../../images/iconsBtr/BtrBath';
import BtrFurnished from '../../../../../images/iconsBtr/BtrFurnished';
import BtrKitchen from '../../../../../images/iconsBtr/BtrKitchen';
import BtrMeter from '../../../../../images/iconsBtr/BtrMeter';
import BtrVideoDoorEntry from '../../../../../images/iconsBtr/BtrVideoDoorEntry';
import BtrBed from '../../../../../images/iconsBtr/BtrBed';
import BtrBedSize from '../../../../../images/iconsBtr/BtrBedSize';
import BtrWardrobe from '../../../../../images/iconsBtr/BtrWardrobe';
import BtrCoffeeTable from '../../../../../images/iconsBtr/BtrCoffeeTable';
import BtrTableAndChairs from '../../../../../images/iconsBtr/BtrTableAndChairs';
import BtrSofa from '../../../../../images/iconsBtr/BtrSofa';
import BtrTv from '../../../../../images/iconsBtr/BtrTv';
import BtrTvStand from '../../../../../images/iconsBtr/BtrTvStand';
import BtrFamily from '../../../../../images/iconsBtr/BtrFamily';
import BtrPet from '../../../../../images/iconsBtr/BtrPet';
import BtrStudent from '../../../../../images/iconsBtr/BtrStudent';
import BtrDrawers from '../../../../../images/iconsBtr/BtrDrawers';
import BtrSpeedyClock from '../../../../../images/iconsBtr/BtrSpeedyClock';
import BtrCalendarWarning from '../../../../../images/iconsBtr/BtrCalendarWarning';
import BtrNoWallet from '../../../../../images/iconsBtr/BtrNoWallet';
import BtrPaper from '../../../../../images/iconsBtr/BtrPaper';
import BtrBreakClause from '../../../../../images/iconsBtr/BtrBreakClause';
import BtrBalcony from '../../../../../images/iconsBtr/BtrBalcony';
import BtrWallet from '../../../../../images/iconsBtr/BtrWallet';
import BtrCctv from '../../../../../images/iconsBtr/BtrCctv';
import BtrCreditCardWifi from '../../../../../images/iconsBtr/BtrCreditCardWifi';
import BtrSmokeAlarm from '../../../../../images/iconsBtr/BtrSmokeAlarm';
import BtrSofaAndTable from '../../../../../images/iconsBtr/BtrSofaAndTable';
import BtrDiningRoom from '../../../../../images/iconsBtr/BtrDiningRoom';
import BtrGameConsole from '../../../../../images/iconsBtr/BtrGameConsole';
import BtrWorkingArea from '../../../../../images/iconsBtr/BtrWorkingArea';
import BtrCinema from '../../../../../images/iconsBtr/BtrCinema';
import BtrTreadmill from '../../../../../images/iconsBtr/BtrTreadmill';
import BtrSwimmingPool from '../../../../../images/iconsBtr/BtrSwimmingPool';
import BtrBar from '../../../../../images/iconsBtr/BtrBar';
import BtrChat from '../../../../../images/iconsBtr/BtrChat';
import BtrEnergy from '../../../../../images/iconsBtr/BtrEnergy';
import BtrParking from '../../../../../images/iconsBtr/BtrParking';
import BtrParcel from '../../../../../images/iconsBtr/BtrParcel';
import BtrBell from '../../../../../images/iconsBtr/BtrBell';
import BtrSettings from '../../../../../images/iconsBtr/BtrSettings';
import BtrBike from '../../../../../images/iconsBtr/BtrBike';
import BtrLift from '../../../../../images/iconsBtr/BtrLift';
import BtrHeating from '../../../../../images/iconsBtr/BtrHeating';
import BtrWifi from '../../../../../images/iconsBtr/BtrWifi';
import BtrTree from '../../../../../images/iconsBtr/BtrTree';

type PredefinedLabel = string | ((d: any) => string);

export const propertyAttributePredefinedLabel: Partial<
  Record<keyof PropertyV2, PredefinedLabel>
> = {
  // Development attributes
  attr_cctv: 'CCTV',
  attr_lift: 'Lifts',
  attr_parcel: 'Parcel collection & storage',
  attr_smoke_alarm: 'Smoke alarms',
  attr_dining_room: 'Dining area',
  attr_epc: (d: string) => `EPC rating ${d ? d.toUpperCase() : 'unknown'}`,

  // Terms & costs
  zero_deposit: 'Zero Deposit available',
  short_rent: `Short rent accepted`,
  max_term: (d: string) => `Max term: ${d}`,
  min_term: (d: string) => `Min term: ${d}`,
  deposit_term: (d: string) => `Deposit: ${d}`,
  notice_period: (d: string) => `Notice period: ${d}`,
  break_clause: (d: string) => `Break clause: ${d}`,

  // Flat attributes
  fttr_built_in_wardrobe: 'Built-in wardrobe',
  fttr_drawers: 'Bedside tables',
  fttr_fridge: 'Fridge & freezer',
};

export const propertyAttributePredefinedIcon: Partial<
  Record<keyof PropertyV2, (props: SvgIconProps) => JSX.Element>
> = {
  // Facilities
  attr_epc: BtrEnergy,
  attr_parcel: BtrParcel,
  attr_concierge: BtrBell,
  attr_onsite_management: BtrBell,
  attr_onsite_maintenance: BtrSettings,
  attr_bike_storage: BtrBike,
  attr_parking: BtrParking,
  attr_lift: BtrLift,
  attr_heating: BtrHeating,
  attr_wifi: BtrWifi,

  // Amenities
  attr_common_area: BtrSofaAndTable,
  attr_dining_room: BtrDiningRoom,
  attr_entertainment_area: BtrGameConsole,
  attr_work_area: BtrWorkingArea,
  attr_communal_garden: BtrTree,
  attr_cinema: BtrCinema,
  attr_gym: BtrTreadmill,
  attr_pool: BtrSwimmingPool,
  attr_bar: BtrBar,
  attr_roof_terrace: BtrTerrace,
  attr_social_events: BtrChat,

  // Security
  attr_cctv: BtrCctv,
  attr_controlled_access: BtrCreditCardWifi,
  attr_smoke_alarm: BtrSmokeAlarm,

  // Terms & costs
  max_term: BtrPaper,
  min_term: BtrPaper,
  notice_period: BtrCalendarWarning,
  break_clause: BtrBreakClause,
  deposit_term: BtrWallet,
  zero_deposit: BtrNoWallet,
  short_rent: BtrSpeedyClock,

  // Flat attributes
  fttr_dishwasher: BtrDishwasher,
  fttr_dryer: BtrDryer,
  fttr_fridge: BtrFridge,
  fttr_hob_type: BtrHob,
  fttr_microwave: BtrMicrowave,
  fttr_oven: BtrOven,
  fttr_washing_machine: BtrWashingMachine,
  fttr_balcony: BtrBalcony,
  attr_bathroom_type: BtrBath,
  attr_furnished: BtrFurnished,
  fttr_open_plan_kitchen: BtrKitchen,
  fttr_smart_meter: BtrMeter,
  attr_video_door_entry: BtrVideoDoorEntry,
  fttr_bed_and_mattress: BtrBed,
  fttr_bed_size: BtrBedSize,
  fttr_built_in_wardrobe: BtrWardrobe,
  fttr_coffee_table: BtrCoffeeTable,
  fttr_dining_table_and_chairs: BtrTableAndChairs,
  fttr_drawers: BtrDrawers,
  fttr_sofa: BtrSofa,
  fttr_tv: BtrTv,
  fttr_tv_stand: BtrTvStand,
  fttr_student_friendly: BtrStudent,
  fttr_family_friendly: BtrFamily,
  fttr_pet_friendly: BtrPet,
};

/**
 * Given a property attribute name, return a label for it.
 *
 * The function first looks up a label in the pre-defined label dictionary. If
 * nothing is found, then the below steps will be executed.
 *
 * If the attribute is a boolean, will use the attribute name as the label.
 * The function will remove the 'attr_' / 'fttr_' prefix and convert the
 * remaining text to normal case.
 *
 * If the attribute is a string, will use the string as the label.
 */
export function getPropertyAttributeLabel(
  attribute: string,
  data: PropertyV2
): string {
  // First, check if a pre-defined attribute label exists:
  const predefinedLabel: PredefinedLabel | undefined =
    propertyAttributePredefinedLabel[attribute];
  if (predefinedLabel) {
    if (typeof predefinedLabel === 'function') {
      return predefinedLabel(data[attribute]);
    }

    return predefinedLabel;
  }

  // If no pre-defined attribute label exists, generate the label dynamically.

  // If the attribute is a string, return the attribute value
  if (typeof data[attribute] === 'string') {
    return data[attribute] as string;
  }

  // In all other cases, return a label based on the attribute's name.
  // The .slice(5) removes the prefix 'attr_' / 'fttr_'
  return lodashCaseToNormalCase(attribute.slice(5));
}
