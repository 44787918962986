import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles<Theme, { x: number; y: number }>((theme: Theme) =>
  createStyles({
    spacer: {
      width: ({ x }) => `${theme.spacing(x)}px`,
      height: ({ y }) => `${theme.spacing(y)}px`,
    },
  })
);

export default useStyles;
