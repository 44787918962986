import * as React from 'react';
import { Clear } from '@material-ui/icons';
import clsx from 'clsx';
import { IconButton, IconButtonProps } from '../../../atoms';

import useIsScreenType from '../../../../utils/dom/useIsScreenType';
import { ScreenType } from '../../../../config';

import useStyles from '../InteractiveMedia.styles';
import { OverlayItemStylesProps } from '../types';

export type DeleteButtonProps = IconButtonProps & OverlayItemStylesProps;

export default function DeleteButton({
  isPrimary,
  variant,
  overlaySize,
  ...iconButtonProps
}: DeleteButtonProps) {
  const isMobile = useIsScreenType(ScreenType.TABLET_PORTRAIT);
  const classes = useStyles({
    isMobile,
    isPrimary,
    variant,
    overlaySize,
  });

  return (
    <IconButton
      className={clsx(
        classes.interactiveMediaOverlayItemCtn,
        classes.interactiveMediaAddOrDeleteButtonCtn,
        classes.interactiveMediaDeleteButtonCtn
      )}
      size="inherit"
      color="inherit"
      {...iconButtonProps}
    >
      <Clear fontSize="inherit" color="inherit" />
    </IconButton>
  );
}
