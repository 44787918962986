import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles<Theme, { noRoundedCorners: boolean }>(
  (theme: Theme) =>
    createStyles({
      inputCtn: {
        position: 'relative',
        width: '100%',
      },

      input: ({ noRoundedCorners }) => ({
        width: '100%',

        '& .MuiInputBase-root': {
          paddingLeft: theme.spacing(4),
        },
        '& .MuiOutlinedInput-root:hover fieldset': {
          borderColor: theme.palette.primary.main,
        },
        '& .MuiOutlinedInput-root fieldset': {
          borderColor: theme.palette.primary.main,
          borderRadius: noRoundedCorners ? 0 : undefined,
          borderWidth: 2,
        },
      }),

      searchIcon: {
        position: 'absolute',
        top: '50%',
        left: theme.spacing(1),
        transform: 'translateY(-50%)',
        color: theme.palette.gray.main,
      },
    })
);

export default useStyles;
