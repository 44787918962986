// Note that this should match what our backend accepts
// .mp4, .flv, .avi, .webm, .mov, .mkv
export const ACCEPTABLE_VIDEO_MIME_TYPES =
  'video/mp4, video/x-flv, video/x-msvideo, video/webm, video/quicktime, video/x-matroska';

export const ACCEPTABLE_VIDEO_MIME_TYPES_LABELS = {
  'video/mp4': 'mp4',
  'video/x-flv': 'flv',
  'video/x-msvideo': 'avi',
  'video/webm': 'webm',
  'video/quicktime': 'mov',
  'video/x-matroska': 'mkv',
};

export const MAX_VIDEO_FILE_SIZE = 128e6; // 128Mb

export const MAX_VIDEO_FILES_AT_ONCE = 10;

/**
 * Debounce time between each video uploads (used when multiple videos are
 * chosen to be uploaded at once).
 *
 * Can be undefined.
 */
export const VIDEO_UPLOAD_DEBOUNCE: number | undefined = 100; // ms

export const VIDEO_ERROR_TEXTS = {
  NO_VIDEO_SELECTED: `No valid video selected. Please select videos that are not more than ${
    MAX_VIDEO_FILE_SIZE / 1e6
  }MB in size and are of the following types: ${Object.values(
    ACCEPTABLE_VIDEO_MIME_TYPES_LABELS
  ).join(', ')}`,
  VIDEO_TOO_LARGE: `Video must be less than ${MAX_VIDEO_FILE_SIZE / 1e6}Mb`,
  TOO_MANY_VIDEOS: `Only a maximum of ${MAX_VIDEO_FILES_AT_ONCE} videos can be uploaded at once`,
  COULD_NOT_UPLOAD_VIDEO: (name: string, reason: string) =>
    `Could not upload video ${name}: ${reason}`,
  UNKNOWN:
    'An unknown error occurred. Please email our support at info@buildtorent.io',
};
