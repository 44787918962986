import * as React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

export enum InteractiveMediaVariant {
  BEST_FIT_SQUARE = 'best fit square',
  BEST_FIT_WIDE = 'best fit wide',
}

export type InteractiveMediaOverlaySize = 'small' | 'large';

export interface UseStylesProps {
  isMobile: boolean;
  isPrimary: boolean;
  variant: InteractiveMediaVariant;
  overlaySize: InteractiveMediaOverlaySize;
  roundedCorners?: boolean;
  canClick?: boolean;
  mediaObjectFit?: React.CSSProperties['objectFit'];
}

const getButtonCtnSize = (theme: Theme, size: 'small' | 'medium' | 'large') => {
  switch (size) {
    case 'small':
      return {
        width: theme.spacing(1.875),
        height: theme.spacing(1.875),
        fontSize: theme.typography.pxToRem(8),
      };
    case 'medium':
      return {
        width: theme.spacing(2.375),
        height: theme.spacing(2.375),
        fontSize: theme.typography.pxToRem(10),
      };
    case 'large':
      return {
        width: theme.spacing(4.375),
        height: theme.spacing(4.375),
        fontSize: theme.typography.pxToRem(13.75),
      };
    default:
      return {
        width: theme.spacing(2.375),
        height: theme.spacing(2.375),
        fontSize: theme.typography.pxToRem(10),
      };
  }
};

// This refers to the overall, outer border radius of the media item.
const getItemBorderRadius = (roundedCorners: boolean) =>
  roundedCorners ? '10px' : 0;

const getButtonCtnFirstRowTop = (
  theme: Theme,
  variant: InteractiveMediaVariant
) =>
  variant === InteractiveMediaVariant.BEST_FIT_SQUARE
    ? theme.spacing(1)
    : theme.spacing(2.5);

const getButtonCtnColor = (
  theme: Theme,
  variant: 'whiteGray' | 'whitePrimary' | 'primary'
) => {
  switch (variant) {
    case 'whiteGray': {
      return {
        color: theme.palette.gray.dark,
        backgroundColor: theme.palette.common.white,
        '&:hover': {
          backgroundColor: theme.palette.common.white,
        },
        '&.Mui-disabled': {
          backgroundColor: theme.palette.common.white,
        },
      };
    }
    case 'whitePrimary': {
      return {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.common.white,
        '&:hover': {
          backgroundColor: theme.palette.common.white,
        },
        '&.Mui-disabled': {
          backgroundColor: theme.palette.common.white,
        },
      };
    }
    case 'primary':
    default: {
      return {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
          backgroundColor: theme.palette.primary.main,
        },
        '&.Mui-disabled': {
          backgroundColor: theme.palette.primary.main,
        },
      };
    }
  }
};

const useStyles = makeStyles<Theme, UseStylesProps>((theme: Theme) =>
  createStyles({
    interactiveMediaCtn: {
      position: 'relative',
      width: '100%',
      height: '100%',
      borderRadius: ({ roundedCorners = false }) =>
        getItemBorderRadius(roundedCorners),
    },

    // An "item" is the either the actual <img> or <video>
    interactiveMediaItemCtn: {
      position: 'relative',
      width: '100%',
      height: '100%',
      backgroundColor: 'transparent',
      borderRadius: ({ roundedCorners = false }) =>
        getItemBorderRadius(roundedCorners),
    },

    interactiveMediaItem: {
      width: '100%',
      height: '100%',
      objectFit: ({ mediaObjectFit = 'cover' }) => mediaObjectFit,
      overflow: 'hidden',
      borderRadius: ({ roundedCorners = false }) =>
        getItemBorderRadius(roundedCorners),
      cursor: ({ canClick = false }) => (canClick ? 'pointer' : 'auto'),
    },

    interactiveMediaItemNotReady: {
      opacity: 0.25,
    },

    interactiveMediaOverlayItemCtn: {
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      filter: 'drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.16))',
    },

    interactiveMediaEditButtonCtn: ({ variant, overlaySize }) => ({
      ...getButtonCtnSize(theme, overlaySize),
      top: getButtonCtnFirstRowTop(theme, variant),
      left:
        variant === InteractiveMediaVariant.BEST_FIT_SQUARE
          ? theme.spacing(1)
          : undefined,
      right:
        variant === InteractiveMediaVariant.BEST_FIT_SQUARE
          ? undefined
          : theme.spacing(8.375),
      borderRadius:
        variant === InteractiveMediaVariant.BEST_FIT_SQUARE ? 3 : '50%',
      ...getButtonCtnColor(theme, 'whiteGray'),
    }),

    interactiveMediaMakePrimaryButtonCtn: ({ variant, overlaySize }) => ({
      ...getButtonCtnSize(theme, overlaySize),
      top: getButtonCtnFirstRowTop(theme, variant),
      left:
        variant === InteractiveMediaVariant.BEST_FIT_SQUARE
          ? theme.spacing(3.75)
          : undefined,
      right:
        variant === InteractiveMediaVariant.BEST_FIT_SQUARE
          ? undefined
          : theme.spacing(14.75),
      borderRadius:
        variant === InteractiveMediaVariant.BEST_FIT_SQUARE ? 3 : '50%',
      ...getButtonCtnColor(theme, 'whiteGray'),
    }),

    interactiveMediaAddOrDeleteButtonCtn: ({ variant, overlaySize }) => ({
      ...getButtonCtnSize(
        theme,
        overlaySize === 'small' ? 'medium' : overlaySize
      ),
      top:
        variant === InteractiveMediaVariant.BEST_FIT_SQUARE
          ? undefined
          : getButtonCtnFirstRowTop(theme, variant),
      bottom:
        variant === InteractiveMediaVariant.BEST_FIT_SQUARE
          ? -theme.spacing(0.5)
          : undefined,
      right:
        variant === InteractiveMediaVariant.BEST_FIT_SQUARE
          ? -theme.spacing(0.25)
          : theme.spacing(2),
      borderRadius: '50%',
    }),

    interactiveMediaDeleteButtonCtn: {
      ...getButtonCtnColor(theme, 'whitePrimary'),
    },

    interactiveMediaAddButtonCtn: {
      ...getButtonCtnColor(theme, 'primary'),
    },

    interactiveMediaPrimaryOverlayCtn: ({ isPrimary, variant }) =>
      variant === InteractiveMediaVariant.BEST_FIT_SQUARE || !isPrimary
        ? { display: 'none' }
        : {
            top: getButtonCtnFirstRowTop(theme, variant),
            left: theme.spacing(2.5),
            padding: theme.spacing(1, 1.5),
            borderRadius: 10,
            backgroundColor: theme.palette.common.white,
          },
  })
);

export default useStyles;
