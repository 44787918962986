import useWindowSize from './useWindowSize';
import { ScreenType, screenTypeMinWidthThresholds } from '../../config';

/**
 * Return the current screen type (based on screen width). The possible values
 * are:
 * - Desktop
 * - Tablet (landscape)
 * - Tablet (portrait)
 * - Mobile
 */
export default function useScreenType(): ScreenType {
  const { width } = useWindowSize();

  if (
    width === undefined ||
    (width && width > screenTypeMinWidthThresholds[ScreenType.DESKTOP])
  ) {
    return ScreenType.DESKTOP;
  }

  if (width > screenTypeMinWidthThresholds[ScreenType.TABLET_LANDSCAPE]) {
    return ScreenType.TABLET_LANDSCAPE;
  }

  if (width > screenTypeMinWidthThresholds[ScreenType.TABLET_PORTRAIT]) {
    return ScreenType.TABLET_PORTRAIT;
  }

  return ScreenType.MOBILE;
}
