import * as React from 'react';
import { AutocompleteService, PlacesService } from '../types';
import useGoogleMaps from './useGoogleMaps';

export interface UseAutocompleteServicesProps {
  googleMapsAttributeElementId: string;
}

export default function useAutocompleteServices({
  googleMapsAttributeElementId,
}: UseAutocompleteServicesProps) {
  const googleMaps = useGoogleMaps();
  const googleMapsLoaded = !!googleMaps;

  const [autocompleteService, setAutocompleteService] =
    React.useState<AutocompleteService | null>(null);
  const [placesService, setPlacesService] =
    React.useState<PlacesService | null>(null);

  React.useEffect(() => {
    if (googleMapsLoaded) {
      setAutocompleteService(new google.maps.places.AutocompleteService());
      setPlacesService(
        new google.maps.places.PlacesService(
          (document.getElementById(
            googleMapsAttributeElementId
          ) as HTMLDivElement) ?? document.createElement('div')
        )
      );
    }
  }, [googleMapsLoaded]);

  return { autocompleteService, placesService };
}
