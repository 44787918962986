export enum SinglePropertyViewCustomEvent {
  'ENQUIRE_BUTTON' = 'spv-enquire-button',
  'PHONE_BUTTON' = 'spv-phone-button',
  'WEBSITE_BUTTON' = 'spv-website-button',
  'CLAIM_DEVELOPMENT_BUTTON' = 'spv-claim-development-button',
  'PSV_SIM_PROPS_LEFT_SCROLL' = 'psv_sim_props_left_scroll_btn',
  'PSV_SIM_PROPS_RIGHT_SCROLL' = 'psv_sim_props_right_scroll_btn',
  'PSV_SIM_PROPS_VIEW_SIMILARS' = 'psv_sim_props_view_similars_btn',
  'PSV_AREA_GUIDE_MOVING_TO' = 'psv_area_guide_moving_to_btn',
  'PSV_AREA_GUIDE_ECON' = 'psv_area_guide_econ_btn',
  'PSV_AREA_GUIDE_POP_ATRCTN' = 'psv_area_guide_pop_atrctn_btn',
  'PSV_AREA_GUIDE_NIGHTLIFE' = 'psv_area_guide_nightlife_btn',
  'PSV_AREA_GUIDE_FOOD' = 'psv_area_guide_food_btn',
  'PSV_AREA_GUIDE_SHOPPING' = 'psv_area_guide_shopping_btn',
  'PSV_AREA_GUIDE_TRAVEL' = 'psv_area_guide_travel_btn',
  'PSV_AREA_GUIDE_EXPLORE' = 'psv_area_guide_explore_btn',
  'PSV_ENQUIRE_DIALOG_CANCEL' = 'psv_enquire_dialog_cancel_btn',
  'PSV_ENQUIRE_DIALOG_SUBMIT' = 'psv_enquire_dialog_submit_btn',
  'PSV_SPEND_TIME_SECTIONS' = 'psv_spend_time_sections',
  'PSV_ORG_IMAGES' = 'psv_org_images',
}
export enum PropertiesViewCustomEvent {
  USER_SEARCH = 'properties-user-search',
}
export enum AppCustomEvent {
  SESSION = 'session',
}
