import * as React from 'react';
import { SvgIconProps } from '@material-ui/core';
import SvgIconBtr from './SvgIconBtr';

export default function BtrBalcony(props: SvgIconProps) {
  return (
    <SvgIconBtr {...props}>
      <line x1="5.71" y1="22.63" x2="34.29" y2="22.63" />
      <line x1="5.71" y1="32.95" x2="34.29" y2="32.95" />
      <line x1="9" y1="22.63" x2="9" y2="30.5" />
      <line x1="11.75" y1="22.63" x2="11.75" y2="30.5" />
      <line x1="14.5" y1="22.63" x2="14.5" y2="30.5" />
      <line x1="17.25" y1="22.63" x2="17.25" y2="30.5" />
      <line x1="20" y1="22.63" x2="20" y2="30.5" />
      <line x1="22.75" y1="22.63" x2="22.75" y2="30.5" />
      <line x1="25.5" y1="22.63" x2="25.5" y2="30.5" />
      <line x1="28.25" y1="22.63" x2="28.25" y2="30.5" />
      <line x1="31" y1="22.63" x2="31" y2="30.5" />
      <path d="M9,21.05v-3a11,11,0,0,1,11-11h0a11,11,0,0,1,11,11v3" />
      <line x1="20" y1="20.38" x2="20" y2="7.5" />
    </SvgIconBtr>
  );
}
