import * as React from 'react';
import clsx from 'clsx';
import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Video, VideoMIMEType } from '../../atoms';

import useIsScreenType from '../../../utils/dom/useIsScreenType';
import { ScreenType } from '../../../config';

import { OverlayItemStylesProps } from './types';
import {
  AddButton,
  DeleteButton,
  EditButton,
  MakePrimaryButton,
  PrimaryOverlay,
} from './OverlayElements';
import useStyles, { InteractiveMediaVariant } from './InteractiveMedia.styles';

export enum InteractiveMediaType {
  IMAGE,
  VIDEO,
}

export interface InteractiveMediaProps {
  id: string;
  src?: string;
  alt?: string;

  status?: 'idle' | 'loading' | 'error';

  type: InteractiveMediaType;
  variant: InteractiveMediaVariant;
  overlaySize?: 'small' | 'large';
  roundedCorners?: boolean;
  mediaObjectFit?: React.CSSProperties['objectFit'];

  // "Primary" medias are used as cover photos, and are normally displayed
  // prominently at the top of lists.
  isPrimary?: boolean;

  canAdd?: boolean;
  onAddButtonClick?: () => void;
  canEdit?: boolean;
  onEditButtonClick?: () => void;
  canDelete?: boolean;
  onDeleteButtonClick?: () => void;
  canMakePrimary?: boolean;
  onMakePrimaryButtonClick?: () => void;

  canClick?: boolean;
  onClick?: () => void;

  // Overload-only props
  thumbnailSrc?: string;
}

export interface InteractiveMediaImageProps extends InteractiveMediaProps {
  type: InteractiveMediaType.IMAGE;
}

export interface InteractiveMediaVideoProps extends InteractiveMediaProps {
  type: InteractiveMediaType.VIDEO;
  thumbnailSrc: string;
}

export const isMediaImage = (
  props: InteractiveMediaProps
): props is InteractiveMediaImageProps =>
  props.type === InteractiveMediaType.IMAGE;

export const isMediaVideo = (
  props: InteractiveMediaProps
): props is InteractiveMediaVideoProps =>
  props.type === InteractiveMediaType.VIDEO;

function InteractiveMedia(props: InteractiveMediaImageProps): JSX.Element;
function InteractiveMedia(props: InteractiveMediaVideoProps): JSX.Element;
function InteractiveMedia(props: InteractiveMediaProps) {
  const {
    src,
    alt,
    status = 'idle',
    variant,
    overlaySize = 'small',
    roundedCorners,
    mediaObjectFit = 'cover',
    isPrimary,
    onEditButtonClick,
    onMakePrimaryButtonClick,
    onDeleteButtonClick,
    onAddButtonClick,
    onClick,
    canEdit,
    canMakePrimary,
    canDelete,
    canAdd,
    canClick,
  } = props;

  const isMobile = useIsScreenType(ScreenType.TABLET_PORTRAIT);
  const classes = useStyles({
    isMobile,
    isPrimary: !!isPrimary,
    variant,
    overlaySize,
    roundedCorners: !!roundedCorners,
    canClick: !!canClick,
    mediaObjectFit,
  });

  const overlayItemStylesProps: OverlayItemStylesProps = {
    isPrimary: !!isPrimary,
    variant,
    overlaySize,
  };

  const clickableItemProps =
    canClick && onClick
      ? {
          onClick,
          onKeyDown: (e: React.KeyboardEvent<unknown>) => {
            if (e.key === 'Enter') {
              onClick();
            }
          },
          tabIndex: 0,
        }
      : undefined;

  const renderImage = isMediaImage(props) && (
    <img
      className={clsx(
        classes.interactiveMediaItem,
        status !== 'idle' && classes.interactiveMediaItemNotReady
      )}
      src={src}
      alt={alt ?? 'photo'}
      {...clickableItemProps}
    />
  );
  const renderVideo =
    isMediaVideo(props) &&
    (status !== 'idle' || !src ? (
      <Skeleton width="100%" height="100%" variant="rect" />
    ) : (
      <Video
        native
        size={isMobile ? 'small' : 'large'}
        className={clsx(
          classes.interactiveMediaItem,
          status !== 'idle' && classes.interactiveMediaItemNotReady
        )}
        src={src}
        videoType={VideoMIMEType.MPEG4}
        poster={props.thumbnailSrc}
        {...clickableItemProps}
      />
    ));

  return (
    <Box className={classes.interactiveMediaCtn}>
      <Box className={classes.interactiveMediaItemCtn}>
        {renderImage}
        {renderVideo}

        {src && isPrimary && <PrimaryOverlay {...overlayItemStylesProps} />}
        {src && onEditButtonClick && (
          <EditButton
            {...overlayItemStylesProps}
            onClick={onEditButtonClick}
            disabled={!canEdit}
          />
        )}
        {src && !isPrimary && onMakePrimaryButtonClick && (
          <MakePrimaryButton
            {...overlayItemStylesProps}
            onClick={onMakePrimaryButtonClick}
            disabled={!canMakePrimary}
          />
        )}
        {onDeleteButtonClick && (
          <DeleteButton
            {...overlayItemStylesProps}
            onClick={onDeleteButtonClick}
            disabled={!canDelete}
          />
        )}
        {!src && onAddButtonClick && (
          <AddButton
            {...overlayItemStylesProps}
            onClick={onAddButtonClick}
            disabled={!canAdd}
          />
        )}
      </Box>
    </Box>
  );
}

export default InteractiveMedia;
