import * as React from 'react';
import { SvgIconProps } from '@material-ui/core';
import SvgIconBtr from './SvgIconBtr';

export default function BtrParcel(props: SvgIconProps) {
  return (
    <SvgIconBtr {...props}>
      <polygon points="33.44 12.58 20 5.86 6.56 12.58 6.56 27.42 20 34.14 33.44 27.42 33.44 12.58" />
      <line x1="20" y1="19.3" x2="20" y2="34.14" />
      <polyline points="6.56 12.58 20 19.3 33.44 12.58" />
      <polyline points="13.71 9 27.58 15.94 27.58 23.48" />
    </SvgIconBtr>
  );
}
