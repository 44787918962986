import * as React from 'react';
import { SvgIconProps } from '@material-ui/core';
import SvgIconBtr from './SvgIconBtr';

export default function BtrKitchen(props: SvgIconProps) {
  return (
    <SvgIconBtr {...props}>
      <polygon points="36.43 33.08 3.57 33.08 3.57 12.65 17.32 12.65 36.43 12.65 36.43 33.08" />
      <line x1="17.32" y1="12.65" x2="17.32" y2="33.08" />
      <line x1="3.57" y1="20.16" x2="36.43" y2="20.16" />
      <line x1="27.34" y1="33.08" x2="27.34" y2="20.16" />
      <line x1="25.49" y1="16.74" x2="28.59" y2="16.74" />
      <line x1="24.66" y1="25.44" x2="24.66" y2="27.41" />
      <line x1="29.89" y1="25.44" x2="29.89" y2="27.41" />
      <circle cx="7.82" cy="16.74" r="0.05" />
      <circle cx="10.37" cy="16.74" r="0.05" />
      <circle cx="12.91" cy="16.74" r="0.05" />
      <rect x="6.06" y="22.27" width="8.7" height="8.7" />
      <path d="M6.06,6.92h8.7a0,0,0,0,1,0,0V9.43A2.76,2.76,0,0,1,12,12.19H8.83A2.76,2.76,0,0,1,6.06,9.43V6.92A0,0,0,0,1,6.06,6.92Z" />
      <line x1="6.06" y1="8.3" x2="4.22" y2="8.3" />
      <line x1="16.6" y1="8.3" x2="14.76" y2="8.3" />
    </SvgIconBtr>
  );
}
