import * as React from 'react';
import { SvgIconProps } from '@material-ui/core';
import SvgIconBtr from './SvgIconBtr';

export default function BtrBar(props: SvgIconProps) {
  return (
    <SvgIconBtr {...props}>
      <line x1="0.5" y1="18.22" x2="39.5" y2="18.22" />
      <line x1="0.5" y1="20.78" x2="39.5" y2="20.78" />
      <line x1="5.5" y1="18.22" x2="5.5" y2="33.59" />
      <line x1="34.8" y1="18.22" x2="34.8" y2="33.59" />
      <rect x="24.89" y="24.05" width="6.06" height="2.38" rx="0.9" ry="0.9" />
      <line x1="27.92" y1="26.43" x2="27.92" y2="33.59" />
      <rect x="15.19" y="24.05" width="6.06" height="2.38" rx="0.9" ry="0.9" />
      <line x1="18.22" y1="26.43" x2="18.22" y2="33.59" />
      <rect x="8.61" y="9.05" width="3.8" height="9.17" rx="1.14" ry="1.14" />
      <line x1="10.51" y1="9.05" x2="10.51" y2="6.41" />
      <polygon points="20 10.66 17.41 13.25 14.82 10.66 20 10.66" />
      <line x1="17.41" y1="13.64" x2="17.41" y2="17.84" />
    </SvgIconBtr>
  );
}
