import { DEFAULT_COOKIE_FLAGS } from './config';
import getClientSideCookie from './getClientSideCookie';

export interface InsertClientSideCookieIfNotExistsArgs {
  name: string;
  data: string;
  maxAge: number; // in seconds
}

/**
 * Create a client-side cookie with document.Cookie if a cookie with the same
 * name doesn't already exist. Cookies created by this method are NOT secure.
 * Don't use this for any sensitive data.
 *
 * If a cookie with the same name already exists, will return its data.
 */
export default function insertClientSideCookieIfNotExists({
  name,
  data,
  maxAge,
}: InsertClientSideCookieIfNotExistsArgs): {
  /**
   * The newly inserted cookie's data or the existing cookie's data
   */
  data: string;

  /**
   * This is true if the cookie already exists
   */
  existing: boolean;
} {
  const existingData = getClientSideCookie(name);

  if (existingData) {
    return {
      data: existingData,
      existing: true,
    };
  }

  document.cookie = `${name}=${data};max-age=${maxAge};${DEFAULT_COOKIE_FLAGS}`;
  return {
    data,
    existing: false,
  };
}
