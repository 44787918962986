const KEY_ORG_IMAGE_IDS_DISPLAYED = 'ORG_IMAGE_IDS_DISPLAYED';
const KEY_SESSION_START = 'SESSION_START';

function extractDataFromSessionStorage<T = unknown>(key: string): T | null {
  if (!window) {
    return null;
  }

  const data = window.sessionStorage?.getItem(key);

  if (!data) {
    return null;
  }

  try {
    return JSON.parse(data) as T;
  } catch (e) {
    return null;
  }
}

function saveDataToSessionStorage<T = unknown>(key: string, data: T) {
  if (window) {
    if (!data) {
      return;
    }

    try {
      if (typeof data === 'object') {
        window.sessionStorage.setItem(key, JSON.stringify(data));
      } else {
        window.sessionStorage.setItem(key, `${data}`);
      }
    } catch (e) {
      return;
    }
  }
}

export const pushOrgImageIdDisplayedToSessionStorage = (orgImageId: string) => {
  if (!orgImageId) {
    return;
  }

  const data = extractDataFromSessionStorage(
    KEY_ORG_IMAGE_IDS_DISPLAYED
  ) as Array<string>;

  let dataAppend = [orgImageId];

  if (data) {
    dataAppend = [...new Set([...data, ...dataAppend])];
  }

  saveDataToSessionStorage(KEY_ORG_IMAGE_IDS_DISPLAYED, dataAppend);
};

export const getOrgImageIdsDisplayedFromSessionStorage = (): string[] => {
  return (
    extractDataFromSessionStorage<string[]>(KEY_ORG_IMAGE_IDS_DISPLAYED) || []
  );
};

export const resetOrgImageIdsDisplayedFromSessionStorage = () => {
  if (window) {
    window.sessionStorage?.removeItem(KEY_ORG_IMAGE_IDS_DISPLAYED);
  }
};

export const getSessionStartFromSessionStorage = (): number => {
  return extractDataFromSessionStorage<number>(KEY_SESSION_START) || 0;
};

export const setSessionStartFromSessionStorage = (value: number): void => {
  saveDataToSessionStorage(KEY_SESSION_START, `${value}`);
};

export const resetSessionStartFromSessionStorage = (): void => {
  window?.sessionStorage?.removeItem?.(KEY_SESSION_START);
};
