import * as React from 'react';

import useStyles from './Spacer.styles';

export interface SpacerProps {
  x?: number;
  y?: number;
}

export default function Spacer({ x: xFromProps, y: yFromProps }: SpacerProps) {
  const classes = useStyles({
    x: xFromProps !== undefined ? xFromProps : 0,
    y: yFromProps !== undefined ? yFromProps : 0,
  });

  return <div className={classes.spacer} data-testid="spacer" />;
}
