import * as React from 'react';
import { SvgIconProps } from '@material-ui/core';
import SvgIconBtr from './SvgIconBtr';

export default function BtrTv(props: SvgIconProps) {
  return (
    <SvgIconBtr {...props}>
      <rect x="5.79" y="8.54" width="28.42" height="18.96" />
      <line x1="11.5" y1="31.46" x2="29.2" y2="31.46" />
      <line x1="20.35" y1="27.5" x2="20.35" y2="31.46" />
    </SvgIconBtr>
  );
}
