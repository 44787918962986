import toTitleCase from './toTitleCase';

export interface LodashCaseToNormalCaseOpts {
  capitalizeAllFirstLetters?: boolean;
}

/**
 * Example: "i_am_legend" will be converted to "I Am Legend" or "I am legend",
 * depending on the options provided.
 */
export default function lodashCaseToNormalCase(
  originalStr: string,
  { capitalizeAllFirstLetters }: LodashCaseToNormalCaseOpts = {}
): string {
  if (!originalStr) {
    return '';
  }

  const lodashReplaced = originalStr.replace(/_/g, ' ');

  if (capitalizeAllFirstLetters) {
    return toTitleCase(lodashReplaced);
  }

  return (
    lodashReplaced[0].toUpperCase() + lodashReplaced.slice(1).toLowerCase()
  );
}
