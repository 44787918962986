import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    popper: {
      zIndex: 1,
    },
    tooltip: {
      fontSize: '0.875rem',
    },
    button: {
      padding: 0,
    },
    icon: {
      fontSize: '1rem',
    },
    closeIcon: {
      fontSize: '1.25rem',
      position: 'absolute',
      right: 0,
      top: 0,
      cursor: 'pointer',
    },
  })
);

export default useStyles;
