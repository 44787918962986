import * as React from 'react';
import { SvgIconProps } from '@material-ui/core';
import SvgIconBtr from './SvgIconBtr';

export default function BtrWifi(props: SvgIconProps) {
  return (
    <SvgIconBtr {...props}>
      <path d="M35.76,15.75a22.22,22.22,0,0,0-31.38,0,.48.48,0,0,0,0,.67l2.67,2.67a.48.48,0,0,0,.67,0,17.48,17.48,0,0,1,24.7,0" />
      <path d="M13.06,24.43a9.92,9.92,0,0,1,14,0,.48.48,0,0,0,.67,0l2.67-2.67a.48.48,0,0,0,0-.67,14.65,14.65,0,0,0-20.7,0" />
      <path d="M17.73,25.1a3.31,3.31,0,1,0,4.68,0A3.3,3.3,0,0,0,17.73,25.1Z" />
    </SvgIconBtr>
  );
}
