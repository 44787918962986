import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import API_ENDPOINTS from '../endpoints';
import { OrgFileV2 } from '../orgs/types';
import pvtFetcher, { PvtFetcherProps } from '../utils/pvtFetcher';

export interface AddDevelopmentVideoProps extends PvtFetcherProps {
  // A UUID that only lives on the client. This is used to keep track of
  // uploaded videos and prevent them from being uploaded twice.
  clientId: string;

  developmentId: number;
  videoFile: File;

  // TODO: deprecate this when edit property v2 is fully live
  version?: 1 | 2;
}

interface SignedUrlResponse {
  data: { objectId: string; signedUrl: string; video: OrgFileV2 };
  meta: { originalname: string };
}

export async function addDevelopmentVideo({
  developmentId,
  videoFile,
  orgId,
  authToken,
  version = 2,
}: AddDevelopmentVideoProps) {
  // For instructions on how to upload objects using signed URLs, see below:
  // https://cloud.google.com/storage/docs/access-control/signed-urls#signing-resumable
  // https://cloud.google.com/storage/docs/access-control/signing-urls-with-helpers#upload-object
  // https://cloud.google.com/storage/docs/xml-api/post-object-resumable
  // https://cloud.google.com/storage/docs/xml-api/put-object-upload

  // The steps are:
  // 1. Use an initial POST request to get a session URI (this is also where
  //    metadata is specified). This session URI is then used in PUT requests to
  //    actually upload data. Our API performs the step of getting a session
  //    URI. All the client does is receiving the URI and uses it to upload the
  //    video.
  // 2. Once the session URI is received, use it to upload the video.

  // ----- 1. Get the signed URL ----- //

  const apiUrl =
    version === 1
      ? API_ENDPOINTS.EDIT_DEVELOPMENT_ADD_DEVELOPMENT_VIDEO(developmentId)
      : API_ENDPOINTS.EDIT_DEVELOPMENT_ADD_DEVELOPMENT_VIDEO_V2(developmentId);

  const signedUrlRes = await pvtFetcher<SignedUrlResponse>(apiUrl, {
    authToken,
    orgId,
    method: 'POST',
    body: JSON.stringify({
      videoName: videoFile.name,
    }),
    mode: 'cors',
  });

  const signedUrl = signedUrlRes.data.signedUrl;

  // ----- 2. Actually upload data ----- //

  // TODO: make use of the fact that the upload is resumable?

  // See here on how to use the PUT request to upload files:
  // https://cloud.google.com/storage/docs/xml-api/put-object-upload

  const videoFileAsArrayBuffer = await videoFile.arrayBuffer();

  const uploadRes = await fetch(`${signedUrl}`, {
    method: 'PUT',

    // https://cloud.google.com/storage/docs/xml-api/put-object-upload#request_headers
    // https://cloud.google.com/storage/docs/xml-api/put-object#request-headers
    headers: {
      'Content-Type': `application/octet-stream`,
    },

    body: videoFileAsArrayBuffer,
  });

  if (!uploadRes.ok) {
    throw new Error(`Could not upload video`);
  }

  // Successful uploads don't have anything in their body. We end the upload
  // function here.
}

interface UseAddDevelopmentVideoProps {
  queryConfig?: UseMutationOptions<void, Error, AddDevelopmentVideoProps>;
}

export default function useAddDevelopmentVideo({
  queryConfig,
}: UseAddDevelopmentVideoProps = {}) {
  return useMutation<void, Error, AddDevelopmentVideoProps>(
    (data) => addDevelopmentVideo(data),
    queryConfig
  );
}
