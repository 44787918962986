import * as React from 'react';
import { SvgIconProps } from '@material-ui/core';
import SvgIconBtr from './SvgIconBtr';

export default function BtrDishwasher(props: SvgIconProps) {
  return (
    <SvgIconBtr {...props}>
      <rect x="5.75" y="6.35" width="28.5" height="25.03" />
      <line x1="9.3" y1="31.38" x2="9.3" y2="33.65" />
      <line x1="30.7" y1="31.38" x2="30.7" y2="33.65" />
      <rect x="8.84" y="12.38" width="22.31" height="15.73" />
      <circle cx="9.97" cy="9.47" r="0.07" />
      <circle cx="13.62" cy="9.47" r="0.07" />
      <circle cx="17.28" cy="9.47" r="0.07" />
      <line x1="8.84" y1="20.85" x2="31.16" y2="20.85" />
      <circle cx="16.04" cy="21.61" r="6" />
      <path d="M19.66,26.39a5.92,5.92,0,0,0,3.62,1.22,6,6,0,0,0,0-12,5.92,5.92,0,0,0-3.62,1.22" />
    </SvgIconBtr>
  );
}
