export const VIDEO_EXTENSIONS = ['mp4', 'flv', 'avi', 'webm', 'mov', 'mkv'];

export enum ScreenType {
  DESKTOP = 'desktop',
  TABLET_LANDSCAPE = 'tablet_landscape',
  TABLET_PORTRAIT = 'tablet_portrait',
  MOBILE = 'mobile',
}

export const screenTypeMinWidthThresholds: Record<ScreenType, number> = {
  [ScreenType.MOBILE]: 0,
  [ScreenType.TABLET_PORTRAIT]: 601,
  [ScreenType.TABLET_LANDSCAPE]: 961,
  [ScreenType.DESKTOP]: 1281,
};

export const screenTypeMaxWidthThresholds: Record<ScreenType, number> = {
  [ScreenType.MOBILE]: 600,
  [ScreenType.TABLET_PORTRAIT]: 960,
  [ScreenType.TABLET_LANDSCAPE]: 1280,
  [ScreenType.DESKTOP]: Infinity,
};
