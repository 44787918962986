import * as React from 'react';
import { Box, ButtonBase, ButtonBaseProps } from '@material-ui/core';
import { Typography, TypographyProps } from '../../atoms';
import useStyles from './ButtonWithIcon.styles';

export interface ButtonWithIconProps {
  text: string;
  icon: React.ReactNode;
  onClick?: () => void;

  /**
   * Background color for the button.
   */
  color?: string;

  /**
   * If true, the button will span 100% of its parent's width.
   */
  fullWidth?: boolean;

  buttonProps?: Partial<ButtonBaseProps>;
  typographyProps?: Partial<TypographyProps>;
}

export default function ButtonWithIcon({
  text,
  icon,
  onClick,
  color,
  fullWidth,
  buttonProps,
  typographyProps,
}: ButtonWithIconProps) {
  const classes = useStyles({ fullWidth, buttonBackgroundColor: color });

  return (
    <ButtonBase className={classes.ctn} onClick={onClick} {...buttonProps}>
      <Box className={classes.iconCtn}>{icon}</Box>
      <Typography
        className={classes.text}
        variant="subtitle1"
        fontWeight={600}
        color="gray"
        colorVariant="contrastText"
        textTransform="uppercase"
        {...typographyProps}
      >
        {text}
      </Typography>
    </ButtonBase>
  );
}
