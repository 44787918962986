import * as React from 'react';
import { SvgIconProps } from '@material-ui/core';
import SvgIconBtr from './SvgIconBtr';

export default function BtrChat(props: SvgIconProps) {
  return (
    <SvgIconBtr {...props}>
      <path d="M16,13.79V9.55A2.09,2.09,0,0,1,18,7.47H31.92A2.08,2.08,0,0,1,34,9.55V20.74a2.08,2.08,0,0,1-2.08,2.08H29.46l-4.2,3.39V22.82H18A2.09,2.09,0,0,1,16,20.74V16.28" />
      <path d="M21.35,25.29v1.77a2.08,2.08,0,0,1-2.08,2.07H14.74v3.4l-4.2-3.4H8.08A2.08,2.08,0,0,1,6,27.06V15.87a2.08,2.08,0,0,1,2.08-2.08H19.27a2.08,2.08,0,0,1,2.08,2.08v7" />
    </SvgIconBtr>
  );
}
