import * as React from 'react';
import {
  Snackbar as MUISnackbar,
  SnackbarProps as MUISnackbarProps,
  Box,
} from '@material-ui/core';
import clsx from 'clsx';
import { useStyles } from './Snackbar.styles';

export enum SnackbarType {
  NORMAL = 'normal',
  SUCCESS = 'success',
  ERROR = 'error',
}

export interface SnackbarProps extends MUISnackbarProps {
  vertical?: 'top' | 'bottom';
  horizontal?: 'left' | 'center' | 'right';
  hideSnackbar: () => void;
  show?: boolean;
  type?: SnackbarType;
  className?: string;
}

export default function Snackbar({
  children,
  className,
  vertical = 'top',
  horizontal = 'center',
  autoHideDuration = 2000,
  type = SnackbarType.NORMAL,
  show = false,
  hideSnackbar,
}: SnackbarProps) {
  const classes = useStyles();

  const handleClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: 'timeout' | 'clickaway'
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    hideSnackbar();
  };

  return (
    <MUISnackbar
      anchorOrigin={{ vertical, horizontal }}
      autoHideDuration={autoHideDuration}
      className={clsx(classes.containerStyled, className)}
      onClose={handleClose}
      open={show}
    >
      <Box className={clsx(classes.snackbarStyled, className)}>{children}</Box>
    </MUISnackbar>
  );
}
