import gtmDataLayerPush from '../gtmDataLayerPush';
import { AppCustomEvent, PropertiesViewCustomEvent } from '../events';
import { CustomVariables } from '../variables';
export interface GtmDataLayerPropertiesUserSearchProps {
  [CustomVariables.SEARCH_URL]: string;
}
export interface GtmDataLayerSessionUser {
  [CustomVariables.SESSION_START]: number;
  [CustomVariables.SESSION_END]: number;
}
export function gtmDataLayerPropertiesUserSearchPush(
  data: GtmDataLayerPropertiesUserSearchProps
) {
  gtmDataLayerPush({
    event: PropertiesViewCustomEvent.USER_SEARCH,
    ...data,
  });
}
export function gtmDataLayerSessionUser(data: GtmDataLayerSessionUser) {
  gtmDataLayerPush({
    event: AppCustomEvent.SESSION,
    ...data,
  });
}
