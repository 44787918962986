import { navigate as gatsbyNavigate } from 'gatsby';

/**
 * Navigate to an internal link
 */
export async function navigate(to: string): Promise<void>;
export async function navigate(to: number): Promise<void>;
export async function navigate(to): Promise<void> {
  if (typeof window !== 'undefined') {
    return gatsbyNavigate(to);
  }
}

/**
 * Navigate to an external link
 */
export function navigateExt(to: string) {
  if (typeof window !== 'undefined') {
    window.location.assign(to);
  }
}

/**
 * Return the 'search' part of the current URL (i.e. the query parameters string
 * that comes after the path portion that starts with a '?' but not including
 * the anchor hashtag).
 *
 * The '?' is included by default but can be optionally removed.
 */
export const getUrlSearchString = (removeQuestionMark?: boolean): string => {
  if (typeof location !== 'undefined') {
    if (removeQuestionMark) {
      return location.search.substring(1);
    }

    return location.search;
  }

  return '';
};
