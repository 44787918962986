import * as React from 'react';
import {
  createStyles,
  makeStyles,
  SimplePaletteColorOptions,
  Theme,
  Typography as MUITypography,
  TypographyProps as MUITypographyProps,
} from '@material-ui/core';
import { PaletteOptions } from '@material-ui/core/styles/createPalette';
import clsx from 'clsx';

export type Color =
  | keyof PaletteOptions
  | 'initial'
  | 'inherit'
  | 'textPrimary'
  | 'textSecondary';

export type FontWeight = 'normal' | 'bold' | 'lighter' | 'bolder' | number;

export interface TypographyProps extends Omit<MUITypographyProps, 'color'> {
  component?: keyof HTMLElementTagNameMap;
  color?: Color;
  colorVariant?: keyof SimplePaletteColorOptions | number;
  fontWeight?: FontWeight;
  fontSize?: React.CSSProperties['fontSize'] | number;
  lineHeight?: React.CSSProperties['lineHeight'] | number;
  textTransform?: React.CSSProperties['textTransform'];
  textAlign?: React.CSSProperties['textAlign'];
  textDecoration?: React.CSSProperties['textDecoration'];
}

const colorsWithoutShade = [
  'initial',
  'inherit',
  'textPrimary',
  'textSecondary',
];

const needShade = (color: Color): boolean =>
  !colorsWithoutShade.includes(color);

const useStyles = makeStyles<
  Theme,
  {
    color?: Color;
    colorVariant: keyof SimplePaletteColorOptions | number;
    fontWeight?: FontWeight;
    fontSize?: React.CSSProperties['fontSize'] | number;
    lineHeight?: React.CSSProperties['lineHeight'] | number;
    textTransform?: React.CSSProperties['textTransform'];
    textAlign?: React.CSSProperties['textAlign'];
    textDecoration?: React.CSSProperties['textDecoration'];
  }
>((theme: Theme) =>
  createStyles({
    styled: ({
      color,
      colorVariant,
      fontWeight,
      fontSize,
      lineHeight,
      textTransform,
      textAlign,
      textDecoration,
    }) => ({
      color:
        color && needShade(color)
          ? theme.palette[color as keyof PaletteOptions][`${colorVariant}`]
          : color,
      fontWeight,
      fontSize,
      lineHeight,
      textTransform,
      textAlign,
      textDecoration,
    }),
  })
);

const Typography = React.forwardRef<HTMLElement, TypographyProps>(
  function Typography(
    {
      color,
      colorVariant = 'main',
      fontWeight,
      fontSize,
      lineHeight,
      className,
      textTransform,
      textAlign,
      textDecoration,
      ...rest
    },
    ref
  ) {
    const classes = useStyles({
      color,
      colorVariant,
      fontWeight,
      fontSize,
      lineHeight,
      textTransform,
      textAlign,
      textDecoration,
    });

    return (
      <MUITypography
        className={clsx(classes.styled, className)}
        ref={ref}
        {...rest}
      />
    );
  }
);

export default Typography;
