export enum CustomVariables {
  DEVELOPMENT_ID = 'developmentId',
  DEVELOPMENT_TITLE = 'developmentTitle',
  FLAT_ID = 'flatId',
  FLAT_TITLE = 'flatTitle',
  FLAT_CATEGORY = 'flatCategory',
  FLAT_RENT_PCM = 'flatRentPcm',
  SPEND_TIME_SUMMARY_SEC = 'spendTimeSummarySec',
  ORG_IMAGE_ID = 'orgImageId',
  SPEND_TIME_AVAILABLE_PROPERTIES_SEC = 'spendTimeAvailableSec',
  SPEND_TIME_FACILITIES_SERVICES_SEC = 'spendTimeFacilitiesServicesSec',
  SPEND_TIME_MAP_SEC = 'spendTimeMapSec',
  SPEND_TIME_VIRTUAL_TOUR_SEC = 'spendTimeVirtualTourSec',
  SPEND_TIME_AREA_GUIDE_SEC = 'spendTimeAreaGuideSec',
  SPEND_TIME_SIMILAR_PROPERTIES_SEC = 'spendTimeSimilarSec',
  SEARCH_URL = 'searchUrl',
  SESSION_START = 'sessionStart',
  SESSION_END = 'sessionEnd',
  USER_ID = 'userId',
}
