import * as React from 'react';
import { SvgIconProps } from '@material-ui/core';
import SvgIconBtr from './SvgIconBtr';

export default function BtrParking(props: SvgIconProps) {
  return (
    <SvgIconBtr {...props}>
      <rect
        x="9.93"
        y="9.93"
        width="20.14"
        height="20.14"
        transform="translate(20 -8.28) rotate(45)"
      />
      <path d="M17.78,25.51V16.12h3.74a2.33,2.33,0,0,1,2.33,2.33h0a2.33,2.33,0,0,1-2.31,2.33l-3.76,0" />
    </SvgIconBtr>
  );
}
