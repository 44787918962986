import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';

import useAuthToken from '../../store/auth/hooks/useAuthToken';
import useAddFlatImage from '../../api/developments/flats/useAddFlatImage';

import { wait } from '../../utils/timer';

import {
  FlatImageFile,
  FlatImageFileMetadata,
  FlatImageFilesById,
} from './types';
import {
  FLAT_IMAGE_ERROR_TEXTS,
  MAX_FLAT_IMAGE_FILES_AT_ONCE,
  FLAT_IMAGE_UPLOAD_DEBOUNCE,
} from './config';
import { OrgFileCategory } from '../../api/orgs/types';

export interface UseUploadFlatImagesProps {
  orgId: number;
  developmentId: number;
  flatId: number;

  defaultCategory:
    | OrgFileCategory.FLOOR_PLAN_IMAGE
    | OrgFileCategory.UNCATEGORIZED
    | OrgFileCategory.FLAT_IMAGE;

  onNewImagesError?: (message: string) => void;

  onImageUploadSuccess?: (props: { id: string; file: File }) => void;
  onImageUploadError?: (props: {
    id: string;
    file: File;
    message: string;
  }) => void;
}

export default function useUploadFlatImages({
  orgId,
  developmentId,
  flatId,
  defaultCategory,
  onNewImagesError,
  onImageUploadSuccess,
  onImageUploadError,
}: UseUploadFlatImagesProps) {
  const authToken = useAuthToken();

  const [stagingImages, setStagingImages] = React.useState<FlatImageFilesById>(
    {}
  );

  const { mutate: addFlatImage } = useAddFlatImage({
    queryConfig: {
      onSuccess: (_, { formData, clientId }) => {
        setStagingImages((prev) => {
          const newStagingImages = { ...prev };
          delete newStagingImages[clientId];
          return newStagingImages;
        });

        if (onImageUploadSuccess) {
          onImageUploadSuccess({
            id: clientId,
            file: formData.get('images') as File,
          });
        }
      },
      onError: (error, { formData, clientId }) => {
        setStagingImages((prev) => ({
          ...prev,
          [clientId]: {
            ...prev[clientId],
            status: 'error',
          },
        }));

        if (onImageUploadError) {
          onImageUploadError({
            id: clientId,
            file: formData.get('images') as File,
            message: error.message,
          });
        }
      },
    },
  });

  const uploadImage = async (
    {
      clientId,
      imageFile: { file, description, priority },
    }: { clientId: string; imageFile: FlatImageFile },
    debounce?: number
  ) => {
    if (debounce) {
      await wait(debounce);
    }

    const formData = new FormData();
    formData.append('images', file);

    addFlatImage({
      authToken,
      orgId,
      developmentId,
      flatId,
      formData,
      clientId,
      description,
      priority,
      category: defaultCategory,
    });
  };

  // This is the handler for a <input type="file" /> kinda element. It should be
  // called when the user selects files, or drag'n'drop files.
  const onNewImages = (files: File[], metadata?: FlatImageFileMetadata) => {
    if (files.length === 0) {
      if (onNewImagesError) {
        onNewImagesError(FLAT_IMAGE_ERROR_TEXTS.NO_IMAGE_SELECTED);
      }
      return;
    }

    if (files.length > MAX_FLAT_IMAGE_FILES_AT_ONCE) {
      if (onNewImagesError) {
        onNewImagesError(FLAT_IMAGE_ERROR_TEXTS.TOO_MANY_IMAGES);
      }
      return;
    }

    const newImageFilesById: FlatImageFilesById = {};
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const clientId = uuidv4();
      const imageFile: FlatImageFile = {
        id: clientId,
        file,
        status: 'uploading',
        preview: URL.createObjectURL(file),
        ...metadata,
      };
      newImageFilesById[clientId] = imageFile;

      // We don't need to wait for the result of this here.
      void uploadImage(
        { clientId, imageFile },
        FLAT_IMAGE_UPLOAD_DEBOUNCE ? i * FLAT_IMAGE_UPLOAD_DEBOUNCE : undefined
      );
    }

    setStagingImages((prev) => ({
      ...prev,
      ...newImageFilesById,
    }));
  };

  return {
    stagingImages,
    onNewImages,
  };
}
