import * as React from 'react';
import {
  Box,
  BoxProps,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';

// ========== TYPES ========== //

export interface SvgIconImgProps extends BoxProps {
  src: string;
  alt?: string;
}

// ========== STYLES ========== //

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    img: {
      width: '100%',
      height: '100%',
    },
  })
);

// ========== COMPONENT ========== //

const SvgIconImg = ({ src, alt, ...boxProps }: SvgIconImgProps) => {
  const classes = useStyles();

  return (
    <Box {...boxProps}>
      <img className={classes.img} src={src} alt={alt} />
    </Box>
  );
};

export default SvgIconImg;
