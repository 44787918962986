import * as React from 'react';
import { Box, IconButton, Snackbar as MUISnackbar } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import clsx from 'clsx';
import useStyles from './Snackbar.styles';

export enum SnackbarType {
  NORMAL = 'normal',
  SUCCESS = 'success',
  ERROR = 'error',
}

export interface SnackbarProps {
  type: SnackbarType;
  show: boolean;
  hideSnackbar: () => void;
  children?: React.ReactNode;
}

export default function Snackbar({
  type,
  show,
  hideSnackbar,
  children,
}: SnackbarProps) {
  const classes = useStyles();

  const handleClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    hideSnackbar();
  };

  return (
    <MUISnackbar
      className={clsx([
        classes.snackbar,
        type === SnackbarType.NORMAL && classes.normalSnackbar,
        type === SnackbarType.ERROR && classes.errorSnackbar,
        type === SnackbarType.SUCCESS && classes.successSnackbar,
      ])}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={show}
      autoHideDuration={5000}
      onClose={handleClose}
    >
      <Box className={classes.contentCtn} boxShadow={2}>
        <IconButton color="inherit" size="small" onClick={handleClose}>
          <Close />
        </IconButton>
        {children}
      </Box>
    </MUISnackbar>
  );
}
