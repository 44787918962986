import * as React from 'react';
import { SvgIconProps } from '@material-ui/core';
import SvgIconBtr from './SvgIconBtr';

export default function BtrDrawers(props: SvgIconProps) {
  return (
    <SvgIconBtr {...props}>
      <rect x="7.95" y="16.59" width="24.09" height="13.83" />
      <line x1="7.95" y1="23.5" x2="32.05" y2="23.5" />
      <line x1="18.57" y1="20.26" x2="21.43" y2="20.26" />
      <line x1="18.57" y1="27.26" x2="21.43" y2="27.26" />
      <line x1="10.95" y1="30.41" x2="10.95" y2="32.82" />
      <line x1="29.05" y1="30.41" x2="29.05" y2="32.82" />
      <rect x="24.26" y="7.18" width="5.81" height="9.41" />
      <ellipse cx="19.42" cy="13.26" rx="2.95" ry="3.06" />
    </SvgIconBtr>
  );
}
