import * as React from 'react';
import { SvgIconProps } from '@material-ui/core';
import SvgIconBtr from './SvgIconBtr';

export default function BtrCctv(props: SvgIconProps) {
  return (
    <SvgIconBtr {...props}>
      <polygon points="23.66 19.49 4 16.08 5.82 5.58 28.76 9.55 23.66 19.49" />
      <polyline points="22.73 19.32 23.66 19.49 26.94 20.05 27.71 15.59 25.6 15.23" />
      <polyline points="14.5 17.9 13.1 26.04 2.21 32.08" />
      <line x1="2.21" y1="28.95" x2="2.21" y2="34.42" />
      <line x1="8.4" y1="9.12" x2="16.99" y2="10.75" />
      <path d="M30.35,16.06a1.45,1.45,0,0,1,.95,1.81" />
      <path d="M31.31,13a4.65,4.65,0,0,1,3.05,5.83" />
      <path d="M32.27,9.93a7.88,7.88,0,0,1,5.16,9.86" />
    </SvgIconBtr>
  );
}
