import * as React from 'react';
import { PaletteOptions } from '@material-ui/core/styles/createPalette';
import { SimplePaletteColorOptions } from '@material-ui/core';
import { Typography } from '../../atoms';

type Color = keyof PaletteOptions;

export interface ColorLabelProps {
  color?: Color;
  colorVariant?: keyof SimplePaletteColorOptions | number;
  text?: string;
  children?: React.ReactNode;
  className?: string;
}

export default function ColorLabel({
  text,
  color,
  colorVariant,
  className,
  children,
}: ColorLabelProps) {
  return (
    <Typography className={className} color={color} colorVariant={colorVariant}>
      {text || children}
    </Typography>
  );
}
