import * as React from 'react';
import { SvgIconProps } from '@material-ui/core';
import SvgIconBtr from './SvgIconBtr';

export default function BtrHob(props: SvgIconProps) {
  return (
    <SvgIconBtr {...props}>
      <rect x="7.32" y="7.32" width="25.36" height="25.36" />
      <line x1="7.32" y1="26.95" x2="32.68" y2="26.95" />
      <circle cx="20" cy="17.21" r="4.96" />
      <line x1="20" y1="10.01" x2="20" y2="14.2" />
      <line x1="20" y1="20.07" x2="20" y2="24.26" />
      <line x1="12.87" y1="17.14" x2="17.06" y2="17.14" />
      <line x1="22.94" y1="17.14" x2="27.13" y2="17.14" />
      <circle cx="10.77" cy="29.95" r="0.08" />
      <circle cx="15.35" cy="29.95" r="0.08" />
      <circle cx="19.92" cy="29.95" r="0.08" />
    </SvgIconBtr>
  );
}
