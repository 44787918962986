import * as React from 'react';
import { SvgIconProps } from '@material-ui/core';
import SvgIconBtr from './SvgIconBtr';

export default function BtrMeter(props: SvgIconProps) {
  return (
    <SvgIconBtr {...props}>
      <polyline points="26.05 27.49 26.05 36.13 6.33 36.13 6.33 11.85 26.05 11.85 26.05 23.99" />
      <rect x="8.8" y="14.74" width="14.98" height="8.16" />
      <circle cx="10.37" cy="27.26" r="1.57" />
      <line x1="20.27" y1="25.69" x2="20.27" y2="28.83" />
      <line x1="23.38" y1="25.69" x2="23.38" y2="28.83" />
      <line x1="10.37" y1="18.83" x2="13.73" y2="18.83" />
      <line x1="14.91" y1="18.83" x2="18.28" y2="18.83" />
      <path className="cls-2" d="M27.65,8.83a1.18,1.18,0,0,1,.77,1.47" />
      <path className="cls-2" d="M28.42,6.35a3.78,3.78,0,0,1,2.48,4.72" />
      <path className="cls-2" d="M29.2,3.87a6.37,6.37,0,0,1,4.18,8" />
      <polyline points="8.8 11.85 8.8 9.57 14.57 9.57 14.57 11.85" />
      <polyline points="18.28 11.85 18.28 9.57 24.05 9.57 24.05 11.85" />
    </SvgIconBtr>
  );
}
