import * as React from 'react';
import { SvgIconProps } from '@material-ui/core';
import SvgIconBtr from './SvgIconBtr';

export default function BtrWorkingArea(props: SvgIconProps) {
  return (
    <SvgIconBtr {...props}>
      <line x1="0.13" y1="28.74" x2="39.88" y2="28.74" />
      <polyline points="3.03 28.74 3.03 11.26 25.97 11.26 25.97 28.74" />
      <path d="M32.29,20c0-1.07,1.55-1.07,1.55-2.15s-1.55-1.08-1.55-2.15,1.55-1.08,1.55-2.16" />
      <path d="M30,21.67h6.27a0,0,0,0,1,0,0v4.19a2.66,2.66,0,0,1-2.66,2.66h-.94A2.66,2.66,0,0,1,30,25.87V21.67a0,0,0,0,1,0,0Z" />
      <line x1="38.11" y1="22.54" x2="38.11" y2="25.53" />
      <line x1="6.06" y1="26.39" x2="25.97" y2="26.39" />
      <circle cx="14.5" cy="18.4" r="1.37" />
    </SvgIconBtr>
  );
}
