import * as React from 'react';
import { Box } from '@material-ui/core';
import { AddCircleOutlined } from '@material-ui/icons';
import { Spacer, Typography, UploadArea, UploadAreaProps } from '../../atoms';

import useStyles from './MediaUploadArea.styles';
import { MediaUploadAreaLayout, MediaUploadAreaVariant } from './types';

export interface MediaUploadAreaProps {
  variant?: MediaUploadAreaVariant;
  layout?: MediaUploadAreaLayout;
  icon?: React.ReactNode;

  // These only appear when the variant is 'full'
  hintTitleFullVariant?: string;
  hintSubtitleFullVariant?: string;

  uploadAreaProps?: UploadAreaProps;

  // Uploaded medias
  items?: React.ReactNode[];
}

export default function MediaUploadArea({
  variant = 'full',
  layout = 'equal',
  icon,
  hintTitleFullVariant,
  hintSubtitleFullVariant,
  uploadAreaProps,
  items,
}: MediaUploadAreaProps) {
  const classes = useStyles({ variant, layout });

  const fullVariantElements = (
    <>
      <Spacer y={3.75} />
      {hintTitleFullVariant && (
        <Typography fontSize={24} fontWeight={600}>
          {hintTitleFullVariant}
        </Typography>
      )}
      {hintSubtitleFullVariant && (
        <Typography fontSize={16}>{hintSubtitleFullVariant}</Typography>
      )}
      <Spacer y={12.25} />
      <Typography fontSize={16} fontWeight={600} textDecoration="underline">
        Upload from your device
      </Typography>
    </>
  );

  return (
    <Box className={classes.mediaUploadAreaCtn}>
      {items}

      {uploadAreaProps && (
        <Box className={classes.mediaUploadAreaHintCtn}>
          {/* This contains the descriptive texts and icons that instructs users on how to upload */}
          <Box className={classes.mediaUploadAreaHint}>
            {icon ?? <AddCircleOutlined fontSize="inherit" color="inherit" />}
            {variant === 'full' && fullVariantElements}
          </Box>

          {/* This contains the actual area where users can drag'n'drop items */}
          <UploadArea {...uploadAreaProps} />
        </Box>
      )}
    </Box>
  );
}
