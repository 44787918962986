import * as React from 'react';
import { Box, Typography } from '@material-ui/core';
import clsx from 'clsx';

import useStyles from './Video.styles';
import PlayButton from './PlayButton/PlayButton';
import { PlayButtonSize } from './PlayButton/PlayButton.styles';

export enum VideoMIMEType {
  FLASH = 'video/x-flv',
  MPEG4 = 'video/mp4',
  IPHONE_INDEX = 'application/x-mpegURL',
  IPHONE_SEGMENT = 'video/MP2T',
  THREE_GP_MOBILE = 'video/3gpp',
  QUICKTIME = 'video/quicktime',
  AV_INTERLEAVE = 'video/x-msvideo',
  WINDOWS_MEDIA = 'video/x-ms-wmv',
  WEBM = 'video/webm',
}

export interface VideoProps extends React.ComponentPropsWithoutRef<'video'> {
  /**
   * If true, will use browser-native controls.
   */
  native?: boolean;

  // Non-native props

  size?: PlayButtonSize;
  hidePlayButton?: boolean;

  /**
   * Only 1x source is supported. In the future, we might consider supporting
   * multiple sources (i.e. an array of 'src' and 'videoType').
   */
  videoType: VideoMIMEType;
}

const defaultNativeProps: React.ComponentPropsWithoutRef<'video'> = {
  controls: true,
};

const defaultNonNativeProps: React.ComponentPropsWithoutRef<'video'> = {
  controls: false,
};

export default function Video({
  native,
  size = 'small',
  hidePlayButton,
  className,
  autoPlay,
  src,
  videoType,
  ...rest
}: VideoProps) {
  const classes = useStyles();

  const videoRef = React.useRef<HTMLVideoElement | null>(null);
  const [isPlaying, setIsPlaying] = React.useState(autoPlay);

  const togglePlayPause = () => {
    const video = videoRef.current;

    if (video) {
      if (isPlaying) {
        video.pause();
        setIsPlaying(false);
      } else {
        void video.play();
        setIsPlaying(true);
      }
    }
  };

  const videoProps = native
    ? { ...defaultNativeProps, ...rest }
    : {
        ...defaultNonNativeProps,
        onClick: () => {
          togglePlayPause();
        },
        onEnded: () => {
          setIsPlaying(false);
        },
        ...rest,
      };

  return (
    <Box className={classes.videoCtn}>
      <video
        ref={videoRef}
        className={clsx(classes.video, className)}
        {...videoProps}
      >
        <source src={src} type={videoType} />
        <Typography>
          Sorry, your browser doesn&#39;t support this video.
        </Typography>
      </video>

      {!native && !hidePlayButton && !isPlaying && (
        <PlayButton iconSize={size} onClick={togglePlayPause} />
      )}
    </Box>
  );
}
