import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    optionCtn: {
      display: 'flex',
      gap: theme.spacing(2),
    },

    locationIcon: {
      color: theme.palette.gray.main,
    },

    textsCtn: {
      display: 'flex',
      flexDirection: 'column',
    },
  })
);

export default useStyles;
