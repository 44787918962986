import * as React from 'react';
import { SvgIconProps } from '@material-ui/core';
import SvgIconBtr from './SvgIconBtr';

export default function BtrSpeedyClock(props: SvgIconProps) {
  return (
    <SvgIconBtr {...props}>
      <path d="M8.46,23.36h3A11.62,11.62,0,0,1,11,20,11.35,11.35,0,1,1,22.31,31.35h-16" />
      <polyline points="22.31 14.54 22.31 20 26.8 20" />
      <line x1="6.34" y1="27.61" x2="15.1" y2="27.61" />
    </SvgIconBtr>
  );
}
