import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';

import useAuthToken from '../../store/auth/hooks/useAuthToken';
import useAddDevelopmentImage from '../../api/developments/useAddDevelopmentImage';

import { wait } from '../../utils/timer';

import {
  DevelopmentImageFile,
  DevelopmentImageFileMetadata,
  DevelopmentImageFilesById,
} from './types';
import {
  DEVELOPMENT_IMAGE_ERROR_TEXTS,
  MAX_DEVELOPMENT_IMAGE_FILES_AT_ONCE,
  DEVELOPMENT_IMAGE_UPLOAD_DEBOUNCE,
} from './config';

export interface UseUploadDevelopmentImagesProps {
  orgId: number;
  developmentId: number;

  onNewImagesError?: (message: string) => void;

  onImageUploadSuccess?: (props: { id: string; file: File }) => void;
  onImageUploadError?: (props: {
    id: string;
    file: File;
    message: string;
  }) => void;
}

export default function useUploadDevelopmentImages({
  orgId,
  developmentId,
  onNewImagesError,
  onImageUploadSuccess,
  onImageUploadError,
}: UseUploadDevelopmentImagesProps) {
  const authToken = useAuthToken();

  const [stagingImages, setStagingImages] =
    React.useState<DevelopmentImageFilesById>({});

  const { mutate: addDevelopmentImage } = useAddDevelopmentImage({
    queryConfig: {
      onSuccess: (_, { formData, clientId }) => {
        setStagingImages((prev) => {
          const newStagingImages = { ...prev };
          delete newStagingImages[clientId];
          return newStagingImages;
        });

        if (onImageUploadSuccess) {
          onImageUploadSuccess({
            id: clientId,
            file: formData.get('images') as File,
          });
        }
      },
      onError: (error, { formData, clientId }) => {
        setStagingImages((prev) => ({
          ...prev,
          [clientId]: {
            ...prev[clientId],
            status: 'error',
          },
        }));

        if (onImageUploadError) {
          onImageUploadError({
            id: clientId,
            file: formData.get('images') as File,
            message: error.message,
          });
        }
      },
    },
  });

  const uploadImage = async (
    {
      clientId,
      imageFile: { file, description, priority, category },
    }: { clientId: string; imageFile: DevelopmentImageFile },
    debounce?: number
  ) => {
    if (debounce) {
      await wait(debounce);
    }

    const formData = new FormData();
    formData.append('images', file);

    addDevelopmentImage({
      authToken,
      orgId,
      developmentId,
      formData,
      clientId,
      description,
      priority,
      category,
    });
  };

  // This is the handler for a <input type="file" /> kinda element. It should be
  // called when the user selects files, or drag'n'drop files.
  const onNewImages = (
    files: File[],
    metadata?: DevelopmentImageFileMetadata
  ) => {
    if (files.length === 0) {
      if (onNewImagesError) {
        onNewImagesError(DEVELOPMENT_IMAGE_ERROR_TEXTS.NO_IMAGE_SELECTED);
      }
      return;
    }

    if (files.length > MAX_DEVELOPMENT_IMAGE_FILES_AT_ONCE) {
      if (onNewImagesError) {
        onNewImagesError(DEVELOPMENT_IMAGE_ERROR_TEXTS.TOO_MANY_IMAGES);
      }
      return;
    }

    const newImageFilesById: DevelopmentImageFilesById = {};
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const clientId = uuidv4();
      const imageFile: DevelopmentImageFile = {
        id: clientId,
        file,
        status: 'uploading',
        preview: URL.createObjectURL(file),
        ...metadata,
      };
      newImageFilesById[clientId] = imageFile;

      // We don't need to wait for the result of this here.
      void uploadImage(
        { clientId, imageFile },
        DEVELOPMENT_IMAGE_UPLOAD_DEBOUNCE
          ? i * DEVELOPMENT_IMAGE_UPLOAD_DEBOUNCE
          : undefined
      );
    }

    setStagingImages((prev) => ({
      ...prev,
      ...newImageFilesById,
    }));
  };

  return {
    stagingImages,
    onNewImages,
  };
}
