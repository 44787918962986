import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    // Version 1
    paginationV1: {
      padding: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginLeft: 'auto',
      '& li': {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        fontSize: '0.875rem',
        width: 'auto',
        margin: '12px 0',
        height: 30,
        '&.active,&:hover': {
          color: theme.palette.primary.dark,
        },
      },
    },
    disabled: {
      opacity: '0.5',
      background: 'transparent !important',
      color: '#414141 !important',
      pointerEvents: 'none',
    },
    prevPagination: {
      borderRight: '1px solid #b2b2b2',
      justifyContent: 'flex-end',
      paddingRight: 12,
      paddingLeft: 0,
    },
    nextPagination: {
      borderLeft: '1px solid #b2b2b2',
      justifyContent: 'flex-start',
      paddingRight: 0,
      paddingLeft: 12,
    },
    pageSelectCtn: {
      display: 'flex',
      alignItems: 'center',
    },

    // Version 2
    pagination: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginLeft: 'auto',
      marginTop: theme.spacing(5),
      maxWidth: 430,
      [theme.breakpoints.down('md')]: {
        backgroundColor: '#fff',
        padding: theme.spacing(1.5, 2),
        margin: '37px -16px -20px',
      },
    },
    paginationLeft: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    paginationRight: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    paginationPage: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        '& .MuiTypography-root': {
          fontSize: 14,
        },
      },
    },
    pageSelect: {
      margin: theme.spacing(0, 1),
      border: '1px solid #b2b2b2',
      '& .MuiOutlinedInput-root': {
        borderRadius: 0,
      },
      '& .MuiSelect-root': {
        padding: theme.spacing(0.5, 2, 0.5, 1),
        color: theme.palette.gray.contrastText,
        fontSize: 13,
        fontWeight: 700,
      },
      '& .MuiSelect-iconOutlined': {
        color: theme.palette.gray.light1,
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.gray.dark,
      },
      [theme.breakpoints.down('md')]: {
        '& .MuiSelect-root': {
          fontSize: 12,
        },
      },
    },
    paginationButton: {
      textTransform: 'capitalize',
      fontFamily: theme.typography.fontFamily,
      fontSize: 16,
      color: theme.palette.gray.dark,
      borderRight: `1px solid ${theme.palette.gray.light1}`,
      borderRadius: 0,
      padding: theme.spacing(0, 2, 0, 0),
      lineHeight: '20px',
      marginLeft: -8,
      minWidth: 'auto',
      '& .MuiSvgIcon-root': {
        marginTop: -2,
        marginBottom: -2,
        marginRight: 6,
      },
      [theme.breakpoints.down('md')]: {
        fontSize: 14,
        marginLeft: 0,
        '& .MuiSvgIcon-root': {
          display: 'none',
        },
      },
    },
    paginationNext: {
      borderLeft: `1px solid ${theme.palette.gray.light1}`,
      borderRight: 0,
      padding: theme.spacing(0, 0, 0, 2),
      marginLeft: 0,
      marginRight: -8,
      '& .MuiButton-label svg': {
        marginLeft: 6,
        marginRight: 0,
      },
      [theme.breakpoints.down('md')]: {
        marginRight: 0,
      },
    },
    pageSelectItem: {
      margin: '0 6px',
      padding: theme.spacing(0.5),
      color: theme.palette.gray.main,
      justifyContent: 'center',
      width: 32,
      minHeight: 'auto',
      '&.Mui-selected:hover': {
        backgroundColor: '#f3f3f3',
      },
      [theme.breakpoints.down('md')]: {
        width: 29,
        height: 29,
        fontSize: 14,
      },
    },
  })
);

export default useStyles;
