import {
  getSessionStartFromSessionStorage,
  resetSessionStartFromSessionStorage,
} from '../../storage/sessionStorage';
import { getCurrentTimeStampUTC } from '../../date';
import { gtmDataLayerSessionUser } from './gtmDataLayerProperties';
import { CustomVariables } from '../variables';

export function gtmDataLayerSessionPush() {
  const sessionStart = getSessionStartFromSessionStorage();
  const sessionEnd = getCurrentTimeStampUTC();

  if (sessionStart) {
    resetSessionStartFromSessionStorage();
    gtmDataLayerSessionUser({
      [CustomVariables.SESSION_START]: sessionStart,
      [CustomVariables.SESSION_END]: sessionEnd,
    });
  }
}
