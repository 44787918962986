import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    ctn: {
      width: '100%',
      height: '100%',
      opacity: 0,
      cursor: 'pointer',
    },
  })
);

export default useStyles;
