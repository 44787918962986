import * as React from 'react';
import {
  Box,
  FormControlLabel,
  InputLabel,
  Radio as MUIRadio,
  RadioGroup,
  RadioProps,
  RadioGroupProps as MUIRadioGroupProps,
} from '@material-ui/core';
import clsx from 'clsx';
import { useStyles, useRadioStyles } from './Radio.styles';

export function StyledRadio(props: RadioProps) {
  const classes = useRadioStyles();

  return (
    <MUIRadio
      className={classes.root}
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

export type RadioOption = {
  value: string | number;
  label?: string;
  labelPlacement?: 'bottom' | 'top' | 'start' | 'end';
};

export type RadioGroupProps = MUIRadioGroupProps & {
  styleCustomized?: boolean;
  labelClassName?: string;
  wrapClassName?: string;
  label?: string;
  htmlFor?: string;
  options?: RadioOption[];
  radioProps?: RadioProps;
  inline?: boolean;
};

const Radio = React.forwardRef(
  (
    {
      htmlFor,
      label,
      options,
      className,
      labelClassName,
      wrapClassName,
      styleCustomized = true,
      inline = true,
      radioProps,
      ...rest
    }: RadioGroupProps,
    ref
  ) => {
    const classes = useStyles();

    return (
      <Box
        className={clsx(styleCustomized && classes.wrapStyled, wrapClassName)}
      >
        {label && (
          <InputLabel
            className={clsx(
              styleCustomized && classes.labelStyled,
              labelClassName
            )}
            htmlFor={htmlFor}
          >
            {label}
          </InputLabel>
        )}
        <RadioGroup
          {...rest}
          className={clsx(
            styleCustomized && classes.radioGroupStyled,
            inline && classes.radioInlineStyled,
            className
          )}
          ref={ref}
        >
          {options &&
            options.map(({ value, label, labelPlacement }) => (
              <FormControlLabel
                key={value}
                value={value}
                control={<StyledRadio {...radioProps} />}
                label={label}
                labelPlacement={labelPlacement}
              />
            ))}
        </RadioGroup>
      </Box>
    );
  }
);

Radio.displayName = 'Radio';

export default Radio;
