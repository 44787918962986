import { useEffect } from 'react';
import {
  getSessionStartFromSessionStorage,
  setSessionStartFromSessionStorage,
} from '../../utils/storage/sessionStorage';
import { getCurrentTimeStampUTC } from '../../utils/date';
import { gtmDataLayerSessionPush } from '../../utils/marketing/eventPushes/gtmDataLayerSession';

export default function useAnalyticsSessionData() {
  useEffect(() => {
    if (!getSessionStartFromSessionStorage()) {
      const timestamp = getCurrentTimeStampUTC();
      setSessionStartFromSessionStorage(timestamp);
    }

    window.onbeforeunload = () => {
      gtmDataLayerSessionPush();
    };
  }, []);
}
