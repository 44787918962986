/**
 * We use Google's Places Autocomplete service.
 *
 * API details:
 * https://developers.google.com/maps/documentation/javascript/places-autocomplete
 * https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompleteService.constructor
 */

import * as React from 'react';
import logIfDev from '../../../../utils/logging/logIfDev';
import { LatLngLiteral } from '../../gcpMaps/types';
import {
  PlaceResult,
  PlacesServiceStatus,
  AutocompletePrediction,
  AutocompleteService,
  PlacesService,
  AutocompleteSessionToken,
} from '../types';

export interface UseGooglePlacesAutocompleteProps {
  autocompleteService: AutocompleteService | null | undefined;
  placesService: PlacesService | null | undefined;

  // A "prediction" can include places (as defined by the Places API) plus
  // suggested search terms.
  predictions: AutocompletePrediction[] | null;
  setPredictions: (predictions: AutocompletePrediction[] | null) => void;

  // A "place" can be an establishment, geographic location, or prominent point
  // of interest, as defined by the Places API.
  placeDetails: PlaceResult | null;
  setPlaceDetails: (placeResult: PlaceResult | null) => void;
}

// https://en.wikipedia.org/wiki/List_of_extreme_points_of_the_United_Kingdom
const GB_BOUNDS = {
  north: 58.6707,
  south: 49.9593,
  east: 1.7639,
  west: -6.227944,
};

export default function useGooglePlacesAutocomplete({
  autocompleteService,
  placesService,
  setPredictions,
  setPlaceDetails,
}: UseGooglePlacesAutocompleteProps) {
  const [sessionToken, setSessionToken] =
    React.useState<AutocompleteSessionToken | null>(null);

  // https://developers.google.com/maps/documentation/javascript/places-autocomplete#session_tokens
  const refreshSessionToken = () => {
    try {
      if (google) {
        logIfDev(`Refreshing session token ⌚...`);
        setSessionToken(new google.maps.places.AutocompleteSessionToken());
      }
    } catch (err) {
      logIfDev(
        `Could not refresh session token: ${(err as Error).message}`,
        'error'
      );
    }
  };

  const getPredictions = (
    input: string,
    location?: LatLngLiteral,
    radius?: number
  ) => {
    if (!sessionToken) {
      refreshSessionToken();
      return;
    }

    if (sessionToken && autocompleteService) {
      autocompleteService.getPlacePredictions(
        {
          input,
          sessionToken,

          // Other options:
          // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompletionRequest

          // Restrict to UK-only results
          componentRestrictions: {
            country: 'gb',
          },

          // Boundary for prediction biasing
          bounds: GB_BOUNDS,

          // Location for prediction biasing
          // location:
          //   location &&
          //   new google.maps.LatLng({
          //     lat: location.lat,
          //     lng: location.lng,
          //   }),
          // Radius must be accompanied by location
          // radius: location && radius,
        },
        (
          predictions: AutocompletePrediction[],
          status: PlacesServiceStatus
        ) => {
          if (status !== PlacesServiceStatus.OK) {
            logIfDev(status, 'error');
            return;
          }

          setPredictions(predictions);
        }
      );
    } else {
      logIfDev(
        `getPredictions: no session token found and no autocompleteService found`,
        'error'
      );
    }
  };

  const getPlaceDetails = (placeId: string) => {
    if (placesService && sessionToken && placeId) {
      placesService.getDetails(
        {
          placeId,
          sessionToken,
          fields: [
            'place_id',
            'types',
            'name',
            'geometry',
            'address_components',
            'formatted_address',
          ],
        },
        (place: PlaceResult, status: PlacesServiceStatus) => {
          if (status !== PlacesServiceStatus.OK) {
            logIfDev(status, 'error');

            // Refresh the token even if an error occurred, because only 1
            // call to placesService.getDetails() is allowed.
            // https://developers.google.com/maps/documentation/javascript/places-autocomplete#session_tokens
            refreshSessionToken();
            return;
          }

          setPlaceDetails(place);

          // Refresh the token. This completes 1 billable request
          // https://developers.google.com/maps/documentation/javascript/places-autocomplete#session_tokens
          refreshSessionToken();
        }
      );
    }
  };

  const clearPredictions = (predictionToKeep?: AutocompletePrediction) => {
    if (predictionToKeep) {
      setPredictions([predictionToKeep]);
    } else {
      setPredictions([]);
    }
  };

  // Create an AutocompleteService instance on component load
  React.useEffect(() => {
    if (autocompleteService && placesService) {
      refreshSessionToken();
    }
  }, [!!autocompleteService, !!placesService]);

  return {
    getPlaceDetails,
    getPredictions,
    clearPredictions,
  };
}
