import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import API_ENDPOINTS from '../endpoints';
import pubFetcher from '../utils/fetcher';

export interface GetAreaRentalDataResponse {
  data: Record<string, { lowerQuartile: number; mean: number }>;
  meta: {
    areaCodesWithoutData: string[];
    highest: {
      lowerQuartile: number;
      lowerQuartileAreaCode: string;
      mean: number;
      meanAreaCode: string;
    };
  };
}

export type RentalStatisticsRoomCategory =
  | 'studio'
  | '1br'
  | '2br'
  | '3br'
  | '4br+'
  | 'room';

export interface UseAreaRentalDataArgs {
  /**
   * This can be a comma-separated string of area codes, or a single area code.
   */
  areaCode: string;

  roomCategory: RentalStatisticsRoomCategory;
  queryConfig?: UseQueryOptions<GetAreaRentalDataResponse>;
}

export default function useAreaRentalData({
  areaCode,
  roomCategory,
  queryConfig,
}: UseAreaRentalDataArgs) {
  const searchParams = new URLSearchParams({
    areaCode,
    roomCategory,
  });
  const url = `${API_ENDPOINTS.AREA_RENTAL_DATA}?${searchParams.toString()}`;

  return useQuery<GetAreaRentalDataResponse, Error>(
    [url],
    () => pubFetcher(url),
    queryConfig
  );
}
