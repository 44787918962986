import * as React from 'react';
import { Box } from '@material-ui/core';
import { LocationOn } from '@material-ui/icons';
import parse from 'autosuggest-highlight/parse';
import useStyles from './Option.styles';
import { AutocompletePrediction } from '../../../../api/googleMaps/gcpPlaces/types';
import { Typography } from '../../../atoms';

export interface OptionProps {
  autocompletePrediction: AutocompletePrediction;
}

const PredictionTextHighlighted = ({ text }: { text: string }) => {
  return (
    <Typography component="span" fontWeight={700}>
      {text}
    </Typography>
  );
};

const PredictionTextNormal = ({ text }: { text: string }) => {
  return <Typography component="span">{text}</Typography>;
};

export default function Option({ autocompletePrediction }: OptionProps) {
  const classes = useStyles();

  // Parse the autocomplete prediction and the user's input to highlight in bold
  // the bits that match the user's typings.

  const predictionTextMatches =
    autocompletePrediction.structured_formatting.main_text_matched_substrings;
  const predictionTextParts = parse(
    autocompletePrediction.structured_formatting.main_text,
    predictionTextMatches.map((m) => [m.offset, m.offset + m.length])
  );

  return (
    <Box className={classes.optionCtn}>
      <LocationOn className={classes.locationIcon} />
      <Box className={classes.textsCtn}>
        <Box>
          {predictionTextParts.map(({ text, highlight }, i) =>
            highlight ? (
              <PredictionTextHighlighted key={i} text={text} />
            ) : (
              <PredictionTextNormal key={i} text={text} />
            )
          )}
        </Box>

        <Typography variant="body2" color="gray">
          {autocompletePrediction.structured_formatting.secondary_text}
        </Typography>
      </Box>
    </Box>
  );
}
