import * as React from 'react';
import { SvgIconProps } from '@material-ui/core';
import SvgIconBtr from './SvgIconBtr';

export default function BtrSofaAndTable(props: SvgIconProps) {
  return (
    <SvgIconBtr {...props}>
      <g>
        <line x1="30.2" y1="19.7" x2="39.3" y2="19.7" />
        <line x1="34.8" y1="19.9" x2="34.8" y2="27.2" />
        <line x1="33.2" y1="27.2" x2="36.4" y2="27.2" />
        <path
          d="M24.9,25.4H4.6c-0.8,0-1.4-0.5-1.7-1.2l-2.1-5.7c-0.3-0.9,0.1-2,1.1-2.3c0.9-0.3,2,0.2,2.3,1.1c0,0,0,0,0,0
		l1.7,4.5h17.8l1.7-4.5c0.4-0.9,1.4-1.3,2.4-1c0.9,0.4,1.3,1.3,1,2.2l-2.1,5.7C26.3,24.9,25.6,25.4,24.9,25.4z"
        />
        <path d="M4.1,17.1v-1.7c0-1.4,1.1-2.6,2.5-2.6c0,0,0,0,0,0h16.1c1.4,0,2.6,1.1,2.6,2.6c0,0,0,0,0,0v1.8" />
        <path d="M5.5,21c0.2-0.7,0.9-1.3,1.6-1.3H13c0.9,0,1.7,0.7,1.7,1.7c0,0,0,0,0,0c0,0.1,0,0.3-0.1,0.4" />
        <path d="M14.8,21.8c0-0.1-0.1-0.3-0.1-0.4c0-0.9,0.7-1.7,1.7-1.7c0,0,0,0,0,0h5.9c0.8,0,1.4,0.5,1.6,1.3" />
        <line x1="5.5" y1="25.4" x2="5.5" y2="27.2" />
        <line x1="23.9" y1="25.4" x2="23.9" y2="27.2" />
        <line x1="25.3" y1="27.2" x2="22.7" y2="27.2" />
        <line x1="6.8" y1="27.2" x2="4.2" y2="27.2" />
      </g>
    </SvgIconBtr>
  );
}
