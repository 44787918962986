// Note that this should match what our backend accepts
// .mp4, .flv, .avi, .webm, .mov, .mkv
export const ACCEPTABLE_DEVELOPMENT_IMAGE_MIME_TYPES =
  'image/jpeg, image/png, image/webp';

export const ACCEPTABLE_DEVELOPMENT_IMAGE_MIME_TYPES_LABELS = {
  'image/jpeg': 'jpeg',
  'image/png': 'png',
  'image/webp': 'webp',
};

export const MAX_DEVELOPMENT_IMAGE_FILE_SIZE = 10e7; // 10Mb

export const MAX_DEVELOPMENT_IMAGE_FILES_AT_ONCE = 10;

/**
 * Debounce time between each video uploads (used when multiple videos are
 * chosen to be uploaded at once).
 *
 * Can be undefined.
 */
export const DEVELOPMENT_IMAGE_UPLOAD_DEBOUNCE: number | undefined = 100; // ms

export const DEVELOPMENT_IMAGE_ERROR_TEXTS = {
  NO_IMAGE_SELECTED: `No valid image selected. Please select images that are not more than ${
    MAX_DEVELOPMENT_IMAGE_FILE_SIZE / 1e6
  }MB in size and are of the following types: ${Object.values(
    ACCEPTABLE_DEVELOPMENT_IMAGE_MIME_TYPES_LABELS
  ).join(', ')}`,
  IMAGE_TOO_LARGE: `Image must be less than ${
    MAX_DEVELOPMENT_IMAGE_FILE_SIZE / 1e6
  }Mb`,
  TOO_MANY_IMAGES: `Only a maximum of ${MAX_DEVELOPMENT_IMAGE_FILES_AT_ONCE} images can be uploaded at once`,
  COULD_NOT_UPLOAD_IMAGE: (name: string, reason: string) =>
    `Could not upload image ${name}: ${reason}`,
  UNKNOWN:
    'An unknown error occurred. Please email our support at info@buildtorent.io',
};
