import * as React from 'react';
import { Box } from '@material-ui/core';
import clsx from 'clsx';
import { Typography } from '../../../atoms';

import useIsScreenType from '../../../../utils/dom/useIsScreenType';
import { ScreenType } from '../../../../config';

import useStyles from '../InteractiveMedia.styles';
import { OverlayItemStylesProps } from '../types';

export type PrimaryOverlayProps = OverlayItemStylesProps;

export default function PrimaryOverlay({
  isPrimary,
  variant,
  overlaySize,
}: PrimaryOverlayProps) {
  const isMobile = useIsScreenType(ScreenType.TABLET_PORTRAIT);
  const classes = useStyles({
    isMobile,
    isPrimary,
    variant,
    overlaySize,
    roundedCorners: false,
  });

  return (
    <Box
      className={clsx(
        classes.interactiveMediaOverlayItemCtn,
        classes.interactiveMediaPrimaryOverlayCtn
      )}
    >
      <Typography fontSize={16} fontWeight={600}>
        Cover photo
      </Typography>
    </Box>
  );
}
