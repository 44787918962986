import * as React from 'react';
import { SvgIconProps } from '@material-ui/core';
import SvgIconBtr from './SvgIconBtr';

export default function BtrSettings(props: SvgIconProps) {
  return (
    <SvgIconBtr {...props}>
      <path d="M10.48,30.6,7.67,27.8,9.9,24.63a11.83,11.83,0,0,1-1.53-3.72l-3.82-.66v-4l3.82-.66A11.83,11.83,0,0,1,9.9,11.91L7.67,8.74l2.81-2.8,3.12,2.2a12.05,12.05,0,0,1,3.77-1.6L18,2.81h4l.63,3.66a12.2,12.2,0,0,1,3.91,1.59l3-2.12,2.8,2.8-2.09,3a11.78,11.78,0,0,1,1.66,4l3.56.62v4l-3.56.61a11.74,11.74,0,0,1-1.66,4l2.09,3-2.8,2.8" />
      <path d="M21.31,27.64V21.46a3.9,3.9,0,0,0,.57-7.11L21,16.78H19l-.89-2.44a3.9,3.9,0,0,0,.56,7.12v8.62a3.89,3.89,0,0,0-.57,7.1L19,34.75H21l.89,2.44a3.9,3.9,0,0,0-.56-7.12" />
      <path d="M15.3,25.66a8.83,8.83,0,1,1,9.49.11" />
    </SvgIconBtr>
  );
}
