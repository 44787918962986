import * as React from 'react';
import { SvgIconProps } from '@material-ui/core';
import SvgIconBtr from './SvgIconBtr';

export default function BtrDryer(props: SvgIconProps) {
  return (
    <SvgIconBtr {...props}>
      <rect x="7.25" y="5.55" width="25.49" height="26.86" />
      <line x1="10.43" y1="32.41" x2="10.43" y2="34.45" />
      <line x1="29.57" y1="32.41" x2="29.57" y2="34.45" />
      <circle cx="11.03" cy="8.35" r="0.06" />
      <circle cx="14.3" cy="8.35" r="0.06" />
      <line x1="7.25" y1="10.53" x2="32.75" y2="10.53" />
      <circle cx="20" cy="21.34" r="7.45" />
      <line x1="24.62" y1="8.29" x2="29.56" y2="8.29" />
      <path d="M16.38,25c0-1.21,1.44-1.21,1.44-2.42s-1.44-1.21-1.44-2.42,1.44-1.21,1.44-2.42" />
      <path d="M19.44,25c0-1.21,1.44-1.21,1.44-2.42s-1.44-1.21-1.44-2.42,1.44-1.21,1.44-2.42" />
      <path d="M22.5,25c0-1.21,1.45-1.21,1.45-2.42s-1.45-1.21-1.45-2.42S24,18.92,24,17.71" />
    </SvgIconBtr>
  );
}
