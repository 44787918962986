import * as React from 'react';
import {
  PlaceResult,
  AutocompletePrediction,
  AutocompleteService,
  PlacesService,
} from '../../api/googleMaps/gcpPlaces/types';
import { useGooglePlacesAutocomplete } from '../../api/googleMaps/gcpPlaces/hooks';
import { useCurrentLocation } from '../../utils/geolocation';
import { usePrev } from '../../utils/hook';

export interface UseAddressAutocompleteProps {
  autocompleteService: AutocompleteService | null | undefined;
  placesService: PlacesService | null | undefined;

  initialInputValue?: string;
  predictionRadius?: number; // Radius of the prediction search in m
  debounceTime?: number; // Input debounce time in ms
}

const DEFAULT_PREDICTION_RADIUS = 5000; // m
const DEFAULT_DEBOUNCE_TIME = 150; // ms

export default function useAddressAutocomplete({
  autocompleteService,
  placesService,
  initialInputValue = '',
  predictionRadius = DEFAULT_PREDICTION_RADIUS,
  debounceTime = DEFAULT_DEBOUNCE_TIME,
}: UseAddressAutocompleteProps) {
  const currentLocation = useCurrentLocation();

  // This refers to the text input that the user types into the
  // AddressAutocomplete box.
  const [inputValue, setInputValue] = React.useState(initialInputValue);

  // The prediction service will only be called if the new input is different
  // from the previous input (and if it's not empty).
  const prevInputValue = usePrev(inputValue);

  // This refers to the prediction result that the user selects among the
  // prediction results presented by the AddressAutocomplete box.
  const [selectedAutocompletePrediction, setSelectedAutocompletePrediction] =
    React.useState<AutocompletePrediction | null>(null);

  // These states are used by the Google Places Autocomplete service. The
  // service retrieves predictions on keystrokes, then returns a Place when the
  // user selects a place among the prediction results.
  const [predictions, setPredictions] = React.useState<
    AutocompletePrediction[] | null
  >(null);
  const [placeDetails, setPlaceDetails] = React.useState<PlaceResult | null>(
    null
  );

  const { clearPredictions, getPredictions, getPlaceDetails } =
    useGooglePlacesAutocomplete({
      autocompleteService,
      placesService,
      predictions,
      setPredictions,
      placeDetails,
      setPlaceDetails,
    });

  const [debounceTimerRunning, setDebounceTimerRunning] = React.useState(false);

  React.useEffect(() => {
    if (!debounceTimerRunning && inputValue && inputValue !== prevInputValue) {
      setDebounceTimerRunning(true);
      window.setTimeout(() => {
        setDebounceTimerRunning(false);
      }, debounceTime);

      getPredictions(
        inputValue,
        currentLocation ?? undefined,
        currentLocation ? predictionRadius : undefined
      );
    }
  }, [
    inputValue,
    debounceTime,
    predictionRadius,
    currentLocation,
    getPredictions,
    setPredictions,
    setDebounceTimerRunning,
  ]);

  return {
    inputValue,
    setInputValue,
    selectedAutocompletePrediction,
    setSelectedAutocompletePrediction,

    predictions,
    setPredictions,
    placeDetails,
    setPlaceDetails,

    getPredictions,
    clearPredictions,
    getPlaceDetails,
  };
}
