import * as React from 'react';
import {
  Box,
  InputLabel,
  TextareaAutosize as MUITextarea,
  TextareaAutosizeProps as MUITextareaProps,
} from '@material-ui/core';
import clsx from 'clsx';
import { useStyles } from './TextArea.styles';

export type TextAreaProps = MUITextareaProps & {
  styleCustomized?: boolean;
  labelClassName?: string;
  wrapClassName?: string;
  htmlFor?: string;
  label?: string;
};

const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      htmlFor,
      label,
      className,
      labelClassName,
      wrapClassName,
      styleCustomized = true,
      ...rest
    }: TextAreaProps,
    ref
  ) => {
    const classes = useStyles();

    return (
      <Box
        className={clsx(styleCustomized && classes.wrapStyled, wrapClassName)}
      >
        {label && (
          <InputLabel
            className={clsx(
              styleCustomized && classes.labelStyled,
              labelClassName
            )}
            htmlFor={htmlFor}
          >
            {label}
          </InputLabel>
        )}
        <MUITextarea
          {...rest}
          ref={ref}
          className={clsx(styleCustomized && classes.textStyled, className)}
        />
      </Box>
    );
  }
);

TextArea.displayName = 'TextArea';

export default TextArea;
