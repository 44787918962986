import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    addressAutocomplete: {
      width: '100%',
    },
  })
);

export default useStyles;
