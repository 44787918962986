import * as React from 'react';
import { SvgIconProps } from '@material-ui/core';
import SvgIconBtr from './SvgIconBtr';

export default function BtrWallet(props: SvgIconProps) {
  return (
    <SvgIconBtr {...props}>
      <rect x="6.44" y="14.14" width="27.12" height="17.94" />
      <polyline points="6.44 14.14 29.08 7.92 29.08 14.14" />
      <path d="M29,21.33V19.84a2.31,2.31,0,0,0-2.3-2.31h0a2.31,2.31,0,0,0-2.3,2.31v1a2.3,2.3,0,0,0,2.3,2.31h0a2.3,2.3,0,0,1,2.3,2.3v1a2.3,2.3,0,0,1-2.3,2.3h0a2.3,2.3,0,0,1-2.3-2.3V25" />
      <line x1="26.67" y1="15.86" x2="26.67" y2="30.21" />
    </SvgIconBtr>
  );
}
