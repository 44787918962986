import * as React from 'react';
import { SvgIconProps } from '@material-ui/core';
import SvgIconBtr from './SvgIconBtr';

export default function BtrHeating(props: SvgIconProps) {
  return (
    <SvgIconBtr {...props}>
      <polyline points="9.65 13.19 9.65 33.89 30.35 33.89 30.35 13.19" />
      <polyline points="5.92 15.32 20 6.11 34.08 15.32" />
      <circle cx="20" cy="22.72" r="2.71" />
      <line x1="20" y1="15.95" x2="20" y2="18.3" />
      <line x1="20" y1="26.93" x2="20" y2="29.27" />
      <line x1="26.66" y1="22.61" x2="24.32" y2="22.61" />
      <line x1="15.68" y1="22.61" x2="13.34" y2="22.61" />
      <line x1="24.71" y1="17.9" x2="23.05" y2="19.56" />
      <line x1="16.95" y1="25.67" x2="15.29" y2="27.32" />
      <line x1="24.71" y1="27.32" x2="23.05" y2="25.67" />
      <line x1="16.95" y1="19.56" x2="15.29" y2="17.9" />
    </SvgIconBtr>
  );
}
