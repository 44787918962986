import * as React from 'react';
import { SvgIconProps } from '@material-ui/core';
import SvgIconBtr from './SvgIconBtr';

export default function BtrSofa(props: SvgIconProps) {
  return (
    <SvgIconBtr {...props}>
      <path d="M33,27H7a2.29,2.29,0,0,1-2.16-1.51l-2.7-7.34a2.31,2.31,0,1,1,4.33-1.6l2.15,5.83H31.39l2.15-5.83a2.31,2.31,0,1,1,4.33,1.6l-2.7,7.34A2.29,2.29,0,0,1,33,27Z" />
      <path d="M6.39,16.34V14.1a3.29,3.29,0,0,1,3.3-3.29H30.31a3.29,3.29,0,0,1,3.3,3.29v2.28" />
      <path d="M8.21,21.29a2.14,2.14,0,0,1,2.08-1.63h7.57A2.14,2.14,0,0,1,20,21.81a1.64,1.64,0,0,1-.08.54" />
      <path d="M20.07,22.35a2,2,0,0,1-.08-.54,2.14,2.14,0,0,1,2.14-2.15H29.7a2.13,2.13,0,0,1,2.07,1.63" />
      <line x1="8.21" y1="26.97" x2="8.21" y2="29.19" />
      <line x1="31.77" y1="26.97" x2="31.77" y2="29.19" />
    </SvgIconBtr>
  );
}
