import * as React from 'react';
import { SvgIconProps } from '@material-ui/core';
import SvgIconBtr from './SvgIconBtr';

export default function BtrMicrowave(props: SvgIconProps) {
  return (
    <SvgIconBtr {...props}>
      <rect x="5.75" y="10.24" width="28.5" height="17.88" />
      <line x1="9.3" y1="28.12" x2="9.3" y2="29.76" />
      <line x1="30.7" y1="28.12" x2="30.7" y2="29.76" />
      <line x1="26.25" y1="10.24" x2="26.25" y2="28.12" />
      <line x1="26.25" y1="15.41" x2="34.25" y2="15.41" />
      <rect x="8.18" y="12.8" width="15.19" height="12.63" />
      <circle cx="30.25" cy="18.11" r="0.07" />
      <circle cx="30.25" cy="21.76" r="0.07" />
      <circle cx="30.25" cy="25.42" r="0.07" />
    </SvgIconBtr>
  );
}
