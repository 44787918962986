import * as React from 'react';
import { Popper, PopperProps } from '@material-ui/core';
import { Button, ButtonProps } from '../../atoms';

interface ButtonWithDropdownDropdownProps
  extends Omit<PopperProps, 'children' | 'open'> {
  open: boolean;
  handleClick: React.MouseEventHandler;
  dropdownElement: React.ReactNode;
}

export interface ButtonWithDropdownProps extends Omit<ButtonProps, 'onClick'> {
  dropdownProps: ButtonWithDropdownDropdownProps;
}

export default function ButtonWithDropdown({
  dropdownProps: {
    open,
    handleClick,
    dropdownElement,
    placement = 'bottom',
    ...restDropdown
  },
  ...rest
}: ButtonWithDropdownProps) {
  const anchorEl = React.useRef<HTMLButtonElement>(null);

  return (
    <>
      <Button ref={anchorEl} onClick={handleClick} {...rest} />
      <Popper
        open={open}
        anchorEl={anchorEl.current}
        transition
        placement={placement}
        {...restDropdown}
      >
        {dropdownElement}
      </Popper>
    </>
  );
}
