import * as React from 'react';
import { SvgIconProps } from '@material-ui/core';
import SvgIconBtr from './SvgIconBtr';

export default function BtrOven(props: SvgIconProps) {
  return (
    <SvgIconBtr {...props}>
      <rect x="5.75" y="6.53" width="28.5" height="23.67" />
      <line x1="9.3" y1="30.2" x2="9.3" y2="33.47" />
      <line x1="30.7" y1="30.2" x2="30.7" y2="33.47" />
      <rect x="8.84" y="13.73" width="22.31" height="13.2" />
      <circle cx="9.97" cy="10.82" r="0.07" />
      <circle cx="13.62" cy="10.82" r="0.07" />
      <circle cx="17.28" cy="10.82" r="0.07" />
      <line x1="17.93" y1="16.13" x2="22.07" y2="16.13" />
    </SvgIconBtr>
  );
}
