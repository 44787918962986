import {
  format,
  addDays,
  endOfMonth,
  endOfWeek,
  endOfYear,
  startOfMonth,
  startOfWeek,
  startOfYear,
  subDays,
  subMonths,
  subWeeks,
  subYears,
  getDay,
  setDay,
  isThisYear,
} from 'date-fns';
import { DateSelectorTab, TabInfo } from './types';

export const formatDateToString = (
  dates: [Date, Date] | [Date],
  showMonthOnly?: boolean,
  showYearOnly?: boolean
) => {
  const showYear = dates.some((date) => !isThisYear(date));

  const dateFormat = showMonthOnly
    ? 'MMMM'
    : showYearOnly
    ? 'yyyy'
    : `${dates.length === 1 ? 'EEEE, ' : ''} d MMM ${
        showYear ? 'yyyy' : ''
      }`.trim();

  if (dates.length === 1) {
    return format(dates[0], dateFormat);
  }

  const [from, to] = dates;
  const fromDate = format(from, dateFormat);
  const toDate = format(to, dateFormat);

  if (fromDate === toDate) {
    return fromDate;
  }

  return `${fromDate} - ${toDate}`;
};

export const getDateSelectorTab = (date: Date): TabInfo[] => [
  {
    label: 'Date',
    tabKey: DateSelectorTab.DATE,
    dates: [
      {
        key: 'yesterday',
        label: 'Yesterday',
        value: [subDays(date, 1)],
      },
      {
        key: 'today',
        label: 'Today',
        value: [date],
      },
    ],
    datesComparing: [
      {
        key: 'preceding-day',
        label: 'Preceding day',
        value: [subDays(date, 2)],
      },
      {
        key: 'same-day-last-week',
        label: 'Same day last week',
        value: [subWeeks(date, 1)],
      },
      {
        key: 'same-day-last-year',
        label: 'Same day last year',
        value: [subYears(date, 1)],
      },
      {
        key: 'same-day-of-the-week-last-year',
        label: 'Same day of the week last year',
        value: [setDay(subYears(date, 1), getDay(date))],
      },
    ],
  },
  {
    label: 'Week',
    tabKey: DateSelectorTab.WEEK,
    dates: [
      {
        key: 'last-7-days',
        label: 'Last 7 days',
        value: [addDays(subWeeks(date, 1), 1), date],
      },
      {
        key: 'this-week',
        label: 'This week',
        value: [
          startOfWeek(date, { weekStartsOn: 1 }),
          endOfWeek(date, { weekStartsOn: 1 }),
        ],
      },
      {
        key: 'last-week',
        label: 'Last week',
        value: [
          startOfWeek(subWeeks(date, 1), { weekStartsOn: 1 }),
          endOfWeek(subWeeks(date, 1), { weekStartsOn: 1 }),
        ],
      },
    ],
    datesComparing: [
      {
        key: 'preceding-period',
        label: 'Preceding period',
        value: [addDays(subWeeks(date, 2), 1), subWeeks(date, 1)],
      },
      {
        key: '4-weeks-ago',
        label: 'Four weeks ago',
        value: [addDays(subWeeks(date, 4), 1), subWeeks(date, 3)],
      },
      {
        key: 'preceding-year',
        label: 'Preceding year',
        value: [addDays(subWeeks(subYears(date, 1), 1), 1), subYears(date, 1)],
      },
      {
        key: '52-weeks-ago',
        label: '52 weeks ago',
        value: [addDays(subWeeks(date, 52), 1), subWeeks(date, 51)],
      },
    ],
  },
  {
    label: 'Month',
    tabKey: DateSelectorTab.MONTH,
    dates: [
      {
        key: 'last-30-days',
        label: 'Last 30 days',
        value: [subDays(date, 29), date],
      },
      {
        key: 'this-month',
        label: 'This month',
        showMonthOnly: true,
        value: [startOfMonth(date), endOfMonth(date)],
      },
      {
        key: 'last-month',
        label: 'Last month',
        showMonthOnly: true,
        value: [
          startOfMonth(subMonths(date, 1)),
          endOfMonth(subMonths(date, 1)),
        ],
      },
    ],
    datesComparing: [
      {
        key: 'preceding-period',
        label: 'Preceding period',
        value: [subDays(date, 59), subDays(date, 30)],
      },
      {
        key: 'four-weeks-ago',
        label: 'Four weeks ago',
        value: [subDays(subWeeks(date, 4), 29), subWeeks(date, 4)],
      },
      {
        key: 'preceding-year',
        label: 'Preceding year',
        value: [subYears(subDays(date, 29), 1), subYears(date, 1)],
      },
      {
        key: '52-weeks-ago',
        label: '52 weeks ago',
        value: [subDays(subWeeks(date, 52), 29), subWeeks(date, 52)],
      },
    ],
  },
  {
    label: 'Year',
    tabKey: DateSelectorTab.YEAR,
    dates: [
      {
        key: 'last-365-days',
        label: 'Last 365 days',
        value: [subDays(date, 364), date],
      },
      {
        key: 'this-year',
        label: 'This year',
        showYearOnly: true,
        value: [startOfYear(date), endOfYear(date)],
      },
      {
        key: 'last-year',
        label: 'Last year',
        showYearOnly: true,
        value: [startOfYear(subYears(date, 1)), endOfYear(subYears(date, 1))],
      },
    ],
    datesComparing: [
      {
        key: 'preceding-period',
        label: 'Preceding period',
        value: [subDays(date, 729), subDays(date, 365)],
      },
    ],
  },
  {
    label: 'Custom',
    tabKey: DateSelectorTab.CUSTOM,
    disabled: true,
    dates: [],
    datesComparing: [],
  },
];
