/**
 * A standard public route fetcher function for react-query.
 */
export default async function pubFetcher<Response = any>(
  url: string,
  {
    method,
    extraHeaders,
    body,
  }: {
    method?: RequestInit['method'];
    extraHeaders?: RequestInit['headers'];
    body?: RequestInit['body'];
  } = {}
) {
  const headers: RequestInit['headers'] = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  const res = await fetch(url, {
    method,
    headers: {
      ...headers,
      ...extraHeaders,
    },
    body,
  });

  if (!res.ok) {
    const errMsg = await res.text();
    throw new Error(errMsg);
  }

  return res.json() as Promise<Response>;
}
