import { useEffect, useState } from 'react';

export interface LatLngCoords {
  lat: number;
  lng: number;
}

export interface GeolocationSuccessPayload {
  coords: {
    latitude: number;
    longitude: number;
  };
}

export interface GeolocationPosition {
  coords: {
    latitude: number;
    longitude: number;
  };
  timestamp: number;
}

export interface GeolocationError {
  code: number;
  message: string;
}

/**
 * Return the browser's location as a lat / lng pair, or an error if this could
 * not be determined.
 */
export async function getCurrentLocation(): Promise<
  { lat: number; lng: number } | Error
> {
  return new Promise((resolve, reject) => {
    if (typeof navigator?.geolocation !== 'undefined') {
      const handleSuccess = (position: GeolocationPosition) => {
        resolve({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      };

      const handleError = (err: GeolocationError) => {
        reject(new Error(`Unable to retrieve location: ${err.message}`));
      };

      // Check the browser's current location. This will prompt the user, asking
      // for permission to use the location service. If all goes well, the
      // browser's location will be returned. Otherwise, an error will be
      // returned.

      navigator.geolocation.getCurrentPosition(handleSuccess, handleError);
    } else {
      reject(new Error("This browser doesn't support location service."));
    }
  });
}

/**
 * This React hook returns the browser's current location as a lat / lng pair,
 * or an error if this could not be determined.
 *
 * The hook runs on component load only.
 */
export function useCurrentLocation(): LatLngCoords | null {
  const [currentLocation, setCurrentLocation] = useState<LatLngCoords | null>(
    null
  );

  useEffect(() => {
    const f = async () => {
      try {
        const currentLocationResult = await getCurrentLocation();

        if (currentLocationResult instanceof Error) {
          setCurrentLocation(null);
        } else {
          setCurrentLocation(currentLocationResult);
        }
      } catch (err) {
        setCurrentLocation(null);
      }
    };

    void f();
  }, []);

  return currentLocation;
}
