import { v4 as uuidv4 } from 'uuid';

/**
 * @link https://www.w3schools.com/js/js_cookies.asp
 */

const GA_CLIENT_ID_KEY = '_ga';
const GA_CLIENT_ID_CUSTOM_KEY = 'gaOurClientID';

export function setCookie(name: string, value: string, extraDays = 30) {
  const d = new Date();
  d.setTime(d.getTime() + extraDays * 24 * 60 * 60 * 1000);
  let expires = 'expires=' + d.toUTCString();
  document.cookie = name + '=' + value + ';' + expires + ';path=/';
}

export function getCookie(cname: string) {
  let name = cname + '=';
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

export const getClientIDGa = () => {
  const gaClientID = getCookie(GA_CLIENT_ID_KEY);

  // example gaClientID = GA1.1.2021163731.1646646363
  if (gaClientID) {
    return gaClientID;
  }
  const gaOurClientID = getCookie(GA_CLIENT_ID_CUSTOM_KEY);

  if (gaOurClientID) {
    return gaOurClientID;
  }
  const idGenerator = `GA-${uuidv4()}`;

  // set two year same as _ga
  setCookie(GA_CLIENT_ID_CUSTOM_KEY, idGenerator, 2 * 365);

  return idGenerator;
};
