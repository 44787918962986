import * as React from 'react';
import {
  Box,
  BoxProps,
  createStyles,
  makeStyles,
  SimplePaletteColorOptions,
  Theme,
  useTheme,
} from '@material-ui/core';
import { PaletteOptions } from '@material-ui/core/styles/createPalette';
import clsx from 'clsx';

export interface DividerProps extends BoxProps {
  widthSpacing?: number;
  heightSpacing?: number;

  inline?: boolean;

  color?: keyof PaletteOptions;
  colorVariant: keyof SimplePaletteColorOptions | number;
}

const useStyles = makeStyles<
  Theme,
  {
    color?: keyof PaletteOptions;
    colorVariant: keyof SimplePaletteColorOptions | number;
  }
>((theme: Theme) =>
  createStyles({
    divider: ({ color, colorVariant }) => ({
      backgroundColor: color ? theme.palette[color][`${colorVariant}`] : color,
    }),
  })
);

export default function Divider({
  widthSpacing,
  heightSpacing,
  inline,
  color,
  colorVariant = 'main',
  className,
  ...rest
}: DividerProps) {
  const theme = useTheme();
  const classes = useStyles({ color, colorVariant });

  return (
    <Box
      display={inline ? 'inline-flex' : 'flex'}
      className={clsx(classes.divider, className)}
      width={widthSpacing !== undefined ? theme.spacing(widthSpacing) : '1px'}
      height={
        heightSpacing !== undefined ? theme.spacing(heightSpacing) : '100%'
      }
      {...rest}
    />
  );
}
