import { useEffect } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { navigate } from '../../utils/dom';
import * as authActions from '../../store/auth/actions';
import { useAppDispatch } from '../index';
import API_ENDPOINTS from '../../api/endpoints';

async function authTokenFetcher(url: string): Promise<undefined> {
  const res = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  });

  if (!res.ok) {
    const errMsg = await res.text();
    throw new Error(errMsg);
  }

  return;
}

export default function useSignOut() {
  const url = `${process.env.GATSBY_API_HOST}/api/auth/signOut`;

  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();

  const { mutate: signOut, isLoading } = useMutation<undefined, Error>(
    () => authTokenFetcher(url),
    {
      onSettled: () => {
        // Clear user details from react-query's cache
        queryClient.removeQueries([API_ENDPOINTS.MY_DETAILS]);
        void navigate('/');
      },
      onSuccess: () => {
        dispatch(authActions.signOut.success());
      },
      onError: (error) => {
        dispatch(authActions.signOut.failure(error));
      },
    }
  );

  useEffect(() => {
    if (isLoading) {
      dispatch(authActions.signOut.loading());
    }
  }, [isLoading]);

  return signOut;
}
