import { createStyles, makeStyles, Theme } from '@material-ui/core';

export type RoundedLevel = 'none' | 'medium' | 'high';

const roundedLevelMapping: Record<RoundedLevel, number> = {
  none: 0,
  medium: 12,
  high: 100,
};

const useStyles = makeStyles<Theme, { roundedLevel?: RoundedLevel }>(() =>
  createStyles({
    button: ({ roundedLevel }) => ({
      textTransform: 'capitalize',
      borderRadius: roundedLevel
        ? roundedLevelMapping[roundedLevel]
        : undefined,
    }),
  })
);

export default useStyles;
