import * as React from 'react';
import { SvgIconProps } from '@material-ui/core';
import SvgIconBtr from './SvgIconBtr';

export default function BtrNoWallet(props: SvgIconProps) {
  return (
    <SvgIconBtr {...props}>
      <polyline points="24.37 13.2 33.56 13.2 33.56 31.14 17.76 31.14" />
      <polyline points="14.65 31.14 6.44 31.14 6.44 13.2 22.11 13.2" />
      <polyline points="24.13 8.35 29.08 6.99 29.08 13.2" />
      <line x1="6.44" y1="13.2" x2="21.67" y2="9.03" />
      <path d="M29,20.39V18.91a2.3,2.3,0,0,0-2.3-2.31h0a2.3,2.3,0,0,0-2.3,2.31v1a2.3,2.3,0,0,0,2.3,2.31h0a2.3,2.3,0,0,1,2.3,2.3v1a2.3,2.3,0,0,1-2.3,2.3h0a2.3,2.3,0,0,1-2.3-2.3V24.11" />
      <line x1="26.67" y1="14.93" x2="26.67" y2="29.28" />
      <polyline points="26.67 2.25 23.72 9.35 11.9 37.75" />
    </SvgIconBtr>
  );
}
