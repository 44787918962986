import * as React from 'react';
import { SvgIconProps } from '@material-ui/core';
import SvgIconBtr from './SvgIconBtr';

export default function BtrFridge(props: SvgIconProps) {
  return (
    <SvgIconBtr {...props}>
      <polyline points="31.81 15.11 8.19 15.11 8.19 3.77 31.81 3.77 31.81 33.83 8.19 33.83 8.19 18.05" />
      <line x1="10.66" y1="33.83" x2="10.66" y2="36.24" />
      <line x1="29.47" y1="33.83" x2="29.47" y2="36.24" />
      <line x1="11.24" y1="18.05" x2="11.24" y2="23.46" />
      <line x1="11.24" y1="6.83" x2="11.24" y2="12.24" />
    </SvgIconBtr>
  );
}
