import * as React from 'react';
import {
  IconButton as MUIIconButton,
  IconButtonProps as MUIIconButtonProps,
} from '@material-ui/core';
import clsx from 'clsx';

import useStyles from './IconButton.styles';

export interface IconButtonProps extends Omit<MUIIconButtonProps, 'size'> {
  size?: 'inherit' | MUIIconButtonProps['size'];
}

export default function IconButton({
  size,
  className,
  ...rest
}: IconButtonProps) {
  const classes = useStyles();

  return (
    <MUIIconButton
      {...rest}
      className={clsx(className, size === 'inherit' && classes.fontSizeInherit)}
      size={size === 'inherit' ? 'small' : size}
    />
  );
}
