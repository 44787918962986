import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';

import useAuthToken from '../../store/auth/hooks/useAuthToken';
import useAddDevelopmentVideo from '../../api/developments/useAddDevelopmentVideo';
import { wait } from '../../utils/timer';

import { VideoFilesById } from './types';
import {
  VIDEO_ERROR_TEXTS,
  MAX_VIDEO_FILES_AT_ONCE,
  VIDEO_UPLOAD_DEBOUNCE,
} from './config';

export interface UseUploadVideosProps {
  orgId: number;
  developmentId: number;

  onNewVideosError?: (message: string) => void;

  onVideosUploadStart?: (props: { file: File }[]) => void;
  onVideoUploadSuccess?: (props: { id: string; file: File }) => void;
  onVideoUploadError?: (props: {
    id: string;
    file: File;
    message: string;
  }) => void;
}

export default function useUploadVideos({
  orgId,
  developmentId,
  onVideosUploadStart,
  onNewVideosError,
  onVideoUploadSuccess,
  onVideoUploadError,
}: UseUploadVideosProps) {
  const authToken = useAuthToken();

  // Contains videos that are not yet fully uploaded to our database. A video is
  // fully uploaded when:
  // - It has a video record in the database
  // - It has a corresponding thumbnail record in the database
  // - It lives in Storage
  const [stagingVideos, setStagingVideos] = React.useState<VideoFilesById>({});

  const { mutate: addDevelopmentVideo } = useAddDevelopmentVideo({
    queryConfig: {
      onSuccess: (_, { videoFile, clientId }) => {
        setStagingVideos((prev) => ({
          ...prev,
          [clientId]: {
            ...prev[clientId],
            status: 'uploaded',
          },
        }));

        if (onVideoUploadSuccess) {
          onVideoUploadSuccess({ id: clientId, file: videoFile });
        }
      },
      onError: (error, { videoFile, clientId }) => {
        setStagingVideos((prev) => ({
          ...prev,
          [clientId]: {
            ...prev[clientId],
            status: 'error',
          },
        }));

        if (onVideoUploadError) {
          onVideoUploadError({
            id: clientId,
            file: videoFile,
            message: error.message,
          });
        }
      },
    },
  });

  const uploadVideo = async (
    { id, file }: { id: string; file: File },
    debounce?: number
  ) => {
    if (debounce) {
      await wait(debounce);
    }

    addDevelopmentVideo({
      authToken,
      orgId,
      developmentId,
      videoFile: file,
      clientId: id,
    });
  };

  // This is the handler for a <input type="file" /> kinda element. It should be
  // called when the user selects files, or drag'n'drop files.
  const onNewVideos = (files: File[]) => {
    if (files.length === 0) {
      if (onNewVideosError) {
        onNewVideosError(VIDEO_ERROR_TEXTS.NO_VIDEO_SELECTED);
      }
      return;
    }

    if (files.length > MAX_VIDEO_FILES_AT_ONCE) {
      if (onNewVideosError) {
        onNewVideosError(VIDEO_ERROR_TEXTS.TOO_MANY_VIDEOS);
      }
      return;
    }

    if (onVideosUploadStart) {
      onVideosUploadStart(files.map((file) => ({ file })));
    }

    const newVideoFilesById: VideoFilesById = {};
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const id = uuidv4();
      newVideoFilesById[id] = {
        id,
        file,
        status: 'uploading',
      };

      // We don't need to wait for the result of this here.
      void uploadVideo(
        { id, file },
        VIDEO_UPLOAD_DEBOUNCE ? i * VIDEO_UPLOAD_DEBOUNCE : undefined
      );
    }

    setStagingVideos((prev) => ({
      ...prev,
      ...newVideoFilesById,
    }));
  };

  return {
    stagingVideos,
    onNewVideos,
  };
}
