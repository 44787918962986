import { FetchConfig } from './types';

export interface PvtFetcherBaseProps {
  authToken: string;
  orgId: number;
}

export type PvtFetcherProps = PvtFetcherBaseProps & FetchConfig;

/**
 * A standard private (authentication required) route fetcher function for
 * react-query.
 */
export default async function pvtFetcher<Response = any>(
  url: string,
  {
    authToken,
    orgId,

    method,
    extraHeaders,
    body,
  }: PvtFetcherProps
) {
  const headers: RequestInit['headers'] = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'BTR-Organization': `${orgId}`,
    Authorization: `Bearer ${authToken}`,
  };

  const res = await fetch(url, {
    method,
    headers: {
      ...headers,
      ...extraHeaders,
    },
    credentials: 'include',
    body,
  });

  if (!res.ok) {
    const errMsg = await res.text();
    throw new Error(errMsg);
  }

  return res.json() as Promise<Response>;
}
