import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export default function SvgIconBtr({ children, ...rest }: SvgIconProps) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      style={{
        fill: 'none',
        strokeMiterlimit: 10,
      }}
      {...rest}
    >
      {children}
    </SvgIcon>
  );
}
