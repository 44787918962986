import * as React from 'react';
import { SvgIconProps } from '@material-ui/core';
import SvgIconBtr from './SvgIconBtr';

export default function BtrBed(props: SvgIconProps) {
  return (
    <SvgIconBtr {...props}>
      <path d="M10.2,16.51V11.05A3.09,3.09,0,0,1,13.29,8H26.71a3.09,3.09,0,0,1,3.09,3.09v5.46" />
      <line x1="20.92" y1="16.51" x2="19.19" y2="16.51" />
      <polyline points="26.91 16.51 29.8 16.51 32.42 24.26" />
      <polyline points="7.58 24.26 10.2 16.51 13.2 16.51" />
      <path d="M9.22,23.43H30.78a1.87,1.87,0,0,1,1.87,1.87v5a0,0,0,0,1,0,0H7.34a0,0,0,0,1,0,0v-5A1.87,1.87,0,0,1,9.22,23.43Z" />
      <line x1="8.77" y1="30.28" x2="8.77" y2="32.04" />
      <line x1="30.99" y1="30.28" x2="30.99" y2="32.04" />
      <path d="M18,19.41H14.2A1.21,1.21,0,0,1,13,18l.43-3.2a1.21,1.21,0,0,1,1.2-1.05H18A1.21,1.21,0,0,1,19.19,15v3.2A1.21,1.21,0,0,1,18,19.41Z" />
      <path d="M22.14,19.41h3.77A1.2,1.2,0,0,0,27.11,18l-.42-3.2a1.22,1.22,0,0,0-1.2-1.05H22.14A1.21,1.21,0,0,0,20.93,15v3.2A1.21,1.21,0,0,0,22.14,19.41Z" />
    </SvgIconBtr>
  );
}
