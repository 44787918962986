import * as React from 'react';
import { SvgIconProps } from '@material-ui/core';
import SvgIconBtr from './SvgIconBtr';

export default function BtrBike(props: SvgIconProps) {
  return (
    <SvgIconBtr {...props}>
      <path d="M12.12,18.48A7.08,7.08,0,1,1,8.21,17.3a7.15,7.15,0,0,1,1.4.14" />
      <path d="M28.88,17.93a7.05,7.05,0,1,1-2.3,1.66" />
      <polyline points="27.1 14.03 18.73 24.38 8.21 24.38 15.28 13.92 27.08 13.94" />
      <polyline points="31.8 24.38 25.28 9.96 29.18 8.54" />
      <line x1="18.73" y1="24.38" x2="14.05" y2="10.93" />
      <line x1="11.79" y1="10.93" x2="16.39" y2="10.93" />
    </SvgIconBtr>
  );
}
