import * as React from 'react';
import {
  createStyles,
  makeStyles,
  withStyles,
  Tabs,
  TabsProps,
  Tab,
  TabProps,
  alpha,
} from '@material-ui/core';
import { DateSelectorTab } from '../types';

const useStyles = makeStyles((theme) =>
  createStyles({
    dialog: {},
    dialogTitle: {
      textAlign: 'center',
    },
    dialogContent: {
      padding: 0,
    },
    buttons: {
      flexDirection: 'column',
      gap: '0.5rem',
      '& > button:not(:first-child)': {
        margin: 0,
      },
    },
    selection: {
      justifyContent: 'space-between',
      display: 'flex',
      width: '100%',
      textAlign: 'left',
      padding: theme.spacing(0.625, 0),
      borderBottom: `1px solid ${alpha(theme.palette.gray.dark || '', 0.25)}`,
    },
  })
);

export type StyledTabsProps = {
  value: TabsProps['value'];
  variant: TabsProps['variant'];
  textColor: TabsProps['textColor'];
  indicatorColor: TabsProps['indicatorColor'];
  onChange: (event: React.ChangeEvent, value: DateSelectorTab) => void;
};

export const StyledTabs = withStyles((theme) =>
  createStyles({
    indicator: {
      bottom: -1,
      height: 3,
    },
    flexContainer: {
      borderBottom: `1px solid ${alpha(theme.palette.gray.dark || '', 0.57)}`,
    },
  })
)((props: StyledTabsProps) => {
  return <Tabs {...props} />;
});

export const StyledTab = withStyles((theme) =>
  createStyles({
    root: {
      minWidth: 'auto',
      margin: theme.spacing(0, 2),
      padding: 0,
      textTransform: 'none',
    },
    selected: {},
  })
)((props: TabProps) => <Tab disableRipple {...props} />);

export default useStyles;
