import * as React from 'react';
import {
  Button as MUIButton,
  ButtonProps as MUIButtonProps,
} from '@material-ui/core';
import clsx from 'clsx';

import useStyles, { RoundedLevel } from './Button.styles';

export interface ButtonProps extends MUIButtonProps {
  roundedLevel?: RoundedLevel;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(function button(
  { children, className, roundedLevel, ...rest },
  ref
) {
  const classes = useStyles({ roundedLevel });

  return (
    <MUIButton ref={ref} className={clsx(classes.button, className)} {...rest}>
      {children}
    </MUIButton>
  );
});

export default Button;
