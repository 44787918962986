import * as React from 'react';
import loadGoogleMaps from '../../loadGoogleMaps';

export default function useGoogleMaps() {
  const [googleMaps, setGoogleMaps] = React.useState<
    typeof google | null | undefined
  >(null);

  React.useEffect(() => {
    const initialize = async () => {
      if (typeof window !== 'undefined') {
        try {
          setGoogleMaps(google);
        } catch (err) {
          const g = await loadGoogleMaps();
          setGoogleMaps(g);
        }
      }
    };

    void initialize();
  }, []);

  return googleMaps;
}
