import * as React from 'react';
import { SvgIconProps } from '@material-ui/core';
import SvgIconBtr from './SvgIconBtr';

export default function BtrFurnished(props: SvgIconProps) {
  return (
    <SvgIconBtr {...props}>
      <rect x="4.33" y="10.97" width="18.5" height="20.88" />
      <line x1="4.33" y1="17.72" x2="22.83" y2="17.72" />
      <line x1="4.33" y1="24.97" x2="22.83" y2="24.97" />
      <line x1="8.58" y1="31.84" x2="8.58" y2="34.16" />
      <line x1="19.33" y1="31.84" x2="19.33" y2="34.16" />
      <line x1="12.21" y1="14.47" x2="14.65" y2="14.47" />
      <line x1="12.21" y1="21.41" x2="14.65" y2="21.41" />
      <line x1="12.21" y1="28.34" x2="14.65" y2="28.34" />
      <polygon points="35.67 16.59 25.71 16.59 27.34 5.84 34.03 5.84 35.67 16.59" />
      <line x1="30.69" y1="16.59" x2="30.69" y2="34.16" />
    </SvgIconBtr>
  );
}
