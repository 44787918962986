import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles<
  Theme,
  { minWidth: number; fullWidth: boolean; marginBottom: number }
>((theme: Theme) =>
  createStyles({
    selectStyled: ({ minWidth }) => ({
      backgroundColor: theme.palette.primary.contrastText,
      height: 'auto',
      borderRadius: theme.spacing(1),

      '&.MuiInputBase-root': {
        width: '100%',
      },

      '& > .MuiSelect-select': {
        height: 'auto',
        position: 'relative',
        fontSize: theme.typography.h6.fontSize,
        lineHeight: 1.2,
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightRegular,
        minWidth: minWidth,
        padding: theme.spacing(2, 4, 2, 2.5),
        boxSizing: 'border-box',
        [theme.breakpoints.down('sm')]: {
          fontSize: theme.typography.pxToRem(14),
          lineHeight: '20px',
        },
      },

      '& > .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.gray.dark,
        borderRadius: theme.spacing(1),
        top: 0,
        '& legend': {
          visibility: 'hidden',
        },
        '& span': {
          fontWeight: theme.typography.fontWeightBold,
          fontFamily: theme.typography.fontFamily,
          color: theme.palette.gray.contrastText,
          fontSize: theme.typography.pxToRem(16),
          height: '100%',
          position: 'absolute',
          top: 0,
          display: 'flex',
          alignItems: 'center',
          padding: theme.spacing(0.5, 0, 0, 1),
        },
      },

      '& > .MuiSelect-icon': {
        color: theme.palette.gray.main,
        right: theme.spacing(1),
        fontSize: '2rem',
        top: 'calc(50% - 16px)',
        zIndex: 100,
      },

      '&.Mui-focused > .MuiOutlinedInput-notchedOutline': {
        borderWidth: 1,
        backgroundColor: theme.palette.primary.contrastText,
      },

      '&.Mui-focused > .MuiSelect-select': {
        backgroundColor: 'transparent',
        color: theme.palette.gray.contrastText,
        zIndex: 99,
      },
    }),

    noPlaceHolder: () => ({
      '& > .MuiOutlinedInput-notchedOutline': {
        top: 0,
        '& legend': {
          visibility: 'hidden',
        },
        '& span': {
          pointerEvents: 'none',
        },
      },
    }),

    placeholderStyled: {
      fontWeight: theme.typography.fontWeightBold,
      fontFamily: theme.typography.fontFamily,
      color: theme.palette.gray.contrastText,
      fontSize: theme.typography.pxToRem(16),
      height: 52,
      width: '100%',
      position: 'absolute',
      bottom: 0,
      left: 0,
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(2),
      pointerEvents: 'none',
      zIndex: 1,
      [theme.breakpoints.down('sm')]: {
        height: 40,
      },
    },

    hasValue: {
      display: 'none',
      '& > .MuiOutlinedInput-notchedOutline': {
        '& > legend': {
          visibility: 'hidden',
        },
      },
    },

    labelStyled: {
      marginBottom: 14,
      fontSize: theme.typography.pxToRem(16),
      fontFamily: theme.typography.fontFamily,
      fontWeight: theme.typography.fontWeightSemiBold,
      color: theme.palette.gray.dark,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(14),
      },
    },

    dropdownStyled: {
      marginTop: 64,
      border: `1px solid ${theme.palette.gray.dark}`,
      padding: theme.spacing(0.5, 2),
      borderRadius: 0,
      color: theme.palette.gray.contrastText,
      '& .MuiListItem-root': {
        padding: 6,
        [theme.breakpoints.down('sm')]: {
          fontSize: theme.typography.pxToRem(14),
          minHeight: 'auto',
          lineHeight: 1,
        },
      },
      '& .MuiListItem-root:hover': {
        backgroundColor: '#f3f3f3',
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: 58,
        padding: theme.spacing(0.5, 1.5),
      },
    },

    wrapStyled: ({ fullWidth, minWidth, marginBottom }) => ({
      marginBottom,
      position: 'relative',
      maxWidth: fullWidth ? 'auto' : minWidth,
    }),
  })
);

export default useStyles;
