import * as React from 'react';
import { SvgIconProps } from '@material-ui/core';
import SvgIconBtr from './SvgIconBtr';

export default function BtrBell(props: SvgIconProps) {
  return (
    <SvgIconBtr {...props}>
      <path d="M9.05,25.11a11.55,11.55,0,0,1,10.87-7.82" />
      <line x1="19.92" y1="13.64" x2="19.92" y2="10.6" />
      <line x1="17.24" y1="10.6" x2="22.76" y2="10.6" />
      <path d="M30.93,28.76H5a15,15,0,0,1,30,0V32H5" />
      <line x1="29.14" y1="12.12" x2="31.97" y2="8.02" />
      <line x1="30.55" y1="13.77" x2="34.05" y2="11.35" />
      <line x1="32.3" y1="15.69" x2="34.99" y2="14.52" />
    </SvgIconBtr>
  );
}
