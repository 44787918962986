import React, { useEffect, useImperativeHandle } from 'react';
import {
  ClickAwayListener,
  IconButton,
  Tooltip,
  TooltipProps,
  Box,
} from '@material-ui/core';
import { SvgIconComponent, Close as CloseIcon } from '@material-ui/icons';
import useStyles from './PopupTooltip.styles';
import { useInView } from 'react-intersection-observer';

export interface PopupTooltipProps extends Omit<TooltipProps, 'children'> {
  icon: SvgIconComponent;
}

interface PopupTooltipRef {
  openTooltip: () => void;
  closeTooltip: () => void;
}

const PopupTooltip = React.forwardRef<PopupTooltipRef, PopupTooltipProps>(
  function tooltip({ icon: Icon, ...tooltipProps }, ref) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [tooltipRef, inView] = useInView({
      threshold: 0.1,
    });

    const handleTooltipClose = () => {
      setOpen(false);
    };

    const handleTooltipOpen = () => {
      setOpen(true);
    };

    useEffect(() => {
      if (!inView) {
        handleTooltipClose();
      }
    }, [inView]);

    useImperativeHandle(ref, () => ({
      openTooltip: handleTooltipOpen,
      closeTooltip: handleTooltipClose,
    }));

    return (
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <Box>
          <Tooltip
            ref={tooltipRef}
            PopperProps={{
              disablePortal: true,
            }}
            classes={{
              tooltip: classes.tooltip,
              popper: classes.popper,
            }}
            onClose={handleTooltipClose}
            open={open}
            placement="top"
            disableFocusListener
            disableHoverListener
            disableTouchListener
            arrow
            {...tooltipProps}
            title={
              <Box mr={1.25}>
                <CloseIcon
                  className={classes.closeIcon}
                  onClick={handleTooltipClose}
                />
                {tooltipProps.title}
              </Box>
            }
          >
            <IconButton
              className={classes.button}
              size="small"
              onClick={handleTooltipOpen}
            >
              <Icon className={classes.icon} />
            </IconButton>
          </Tooltip>
        </Box>
      </ClickAwayListener>
    );
  }
);

export default PopupTooltip;
