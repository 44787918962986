import * as React from 'react';
import { Box } from '@material-ui/core';
import { Control, Controller } from 'react-hook-form';

import { Select, SelectOption, Typography } from '../../atoms';
import PropertyAttributeIcon from '../../pages/properties/single-view/Common/PropertyAttributeIcon';

import useStyles from './SelectWithTitleAndIcon.styles';

export interface SelectWithTitleAndIconProps {
  icon: string;
  title: string;
  options?: SelectOption[];
  control: Control;
  value: string | undefined;
}

export default function SelectWithTitleAndIcon({
  icon,
  title,
  options = [
    { value: '', label: ' ' },
    { value: 'true', label: 'Yes' },
    { value: 'false', label: 'No' },
  ],
  control,
  value,
}: SelectWithTitleAndIconProps) {
  const classes = useStyles();

  return (
    <Box className={classes.wrapContainer}>
      <Box className={classes.iconContainer}>
        <PropertyAttributeIcon
          stroke={'#EC1D23'}
          fontSize="large"
          attribute={icon}
          className={classes.icon}
        />
      </Box>
      <Box className={classes.itemContainer}>
        <Typography
          className={classes.attributeName}
          fontWeight={600}
          color="gray"
          colorVariant="dark"
        >
          {title}
        </Typography>
        <Controller
          name={icon}
          control={control}
          defaultValue={value ?? ''}
          render={({ field }) => (
            <Select
              {...field}
              options={options}
              wrapClassName={classes.wrapSelect}
              className={classes.select}
              dropdownClassName={classes.selectDropdown}
              minWidth={109}
              marginBottom={0}
            />
          )}
        />
      </Box>
    </Box>
  );
}
