import * as React from 'react';

/**
 * USE PREV
 */

export function usePrev<T>(value: T): T {
  // The ref object is a generic container whose current property is mutable and
  // and can hold any value, similar to an instance property on a class.
  const ref = React.useRef<T>(value);

  // Store current value in ref
  React.useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
}
