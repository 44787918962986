import * as React from 'react';
import { Box, InputLabel } from '@material-ui/core';
import ReactMarkdown from 'react-markdown';
import ReactMde, { ReactMdeProps } from 'react-mde';
import clsx from 'clsx';
import { useStyles } from './MarkdownEditor.styles';
import 'react-mde/lib/styles/css/react-mde-all.css';
import { PopupTooltip } from '../../molecules';
import { HelpRounded } from '@material-ui/icons';

export interface MarkdownEditorProps
  extends Omit<
    ReactMdeProps,
    'generateMarkdownPreview' | 'onTabChange' | 'onChange' | 'selectedTab'
  > {
  label?: string;
  styleCustomized?: boolean;
  className?: string;
  toolbarClassName?: string;
  previewClassName?: string;
  textareaClassName?: string;
  suggestionClassName?: string;
  labelClassName?: string;
  wrapClassName?: string;
  htmlFor?: string;
  resize?: React.CSSProperties['resize'];
  generateMarkdownPreview?: (markdown: string) => Promise<React.ReactNode>;
  onTabChange?: (tab: 'write' | 'preview') => void;
  onChange?: (value: string) => void;
  selectedTab?: 'write' | 'preview';
  helperTooltip?: string;
}

function MarkdownEditor(
  {
    htmlFor,
    label,
    toolbarCommands,
    className,
    toolbarClassName,
    previewClassName,
    textareaClassName,
    suggestionClassName,
    labelClassName,
    wrapClassName,
    resize = 'none',
    styleCustomized = true,
    generateMarkdownPreview,
    onTabChange,
    onChange,
    selectedTab = 'write',
    value = '',
    helperTooltip,
    ...rest
  }: MarkdownEditorProps,
  ref
) {
  const styleClasses = useStyles({ resize });

  const [markdown, setMarkdown] = React.useState(value);
  const [markdownSelectedTab, setMarkdownSelectedTab] = React.useState<
    'write' | 'preview'
  >(selectedTab);

  const defaultToolbarCommands = [['header', 'bold', 'italic']];

  const onSelectTab = (tab: 'write' | 'preview') => {
    onTabChange && onTabChange(tab);
    setMarkdownSelectedTab(tab);
  };

  const onMarkdownChange = (value: string) => {
    onChange && onChange(value);
    setMarkdown(value);
  };

  return (
    <div
      className={clsx(
        styleCustomized && styleClasses.wrapStyled,
        wrapClassName
      )}
    >
      {label && (
        <Box className={styleClasses.labelCtn}>
          <InputLabel
            className={clsx(
              styleCustomized && styleClasses.labelStyled,
              labelClassName
            )}
            htmlFor={htmlFor}
          >
            {label}
          </InputLabel>
          {helperTooltip && (
            <InputLabel className={styleClasses.labelStyled}>
              <PopupTooltip icon={HelpRounded} title={helperTooltip} />
            </InputLabel>
          )}
        </Box>
      )}
      <ReactMde
        {...rest}
        refs={{ textarea: ref as React.RefObject<HTMLTextAreaElement> }}
        classes={{
          reactMde: clsx(
            styleCustomized && styleClasses.markdownStyled,
            className
          ),
          toolbar: clsx(
            styleCustomized && styleClasses.toolbarStyled,
            toolbarClassName
          ),
          preview: clsx(
            styleCustomized && styleClasses.previewStyled,
            previewClassName
          ),
          textArea: clsx(
            styleCustomized && styleClasses.textareaStyled,
            textareaClassName
          ),
          suggestionsDropdown: clsx(
            styleCustomized && styleClasses.suggestionStyled,
            suggestionClassName
          ),
        }}
        toolbarCommands={toolbarCommands ?? defaultToolbarCommands}
        selectedTab={markdownSelectedTab}
        onTabChange={onSelectTab}
        value={markdown}
        onChange={onMarkdownChange}
        generateMarkdownPreview={
          generateMarkdownPreview ??
          ((markdown) =>
            Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>))
        }
      />
    </div>
  );
}

export default React.forwardRef<HTMLElement, MarkdownEditorProps>(
  MarkdownEditor
);
