import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import API_ENDPOINTS from '../endpoints';
import pubFetcher from '../utils/fetcher';

export interface UseAgencyCouncilsArgs {
  city: string;
  queryConfig?: UseQueryOptions<GetAgencyCouncilsResponse>;
}

export interface GetAgencyCouncilsResponse {
  data: string[];
  meta: {
    areaCode: Record<string, string | null>;
    count: number;
  };
}

export default function useAgencyCouncils({
  city,
  queryConfig,
}: UseAgencyCouncilsArgs) {
  const url = API_ENDPOINTS.AGENCIES_COUNCILS(city);

  return useQuery<GetAgencyCouncilsResponse, Error>(
    [url],
    () => pubFetcher(url),
    queryConfig
  );
}
