export const IS_PROD = process.env.GATSBY_ENVIRONMENT === 'production';

export interface PrivateDevelopmentRouteProps {
  orgId: number;
  developmentId: number;
}

export interface PrivateFlatRouteProps extends PrivateDevelopmentRouteProps {
  flatId: number;
}

const properties = '/properties';

const map = '/map';

const accountManagementRoot = '/my-account';

const createPropertyRoot = `${accountManagementRoot}/property/create`;

const propertiesManagementRoot = `${accountManagementRoot}/properties`;

const propertyManagementRoot = `${accountManagementRoot}/property`;
const propertyManagementEditDevelopmentRoot = ({
  orgId,
  developmentId,
}: PrivateDevelopmentRouteProps) =>
  `/my-account/property/edit/${orgId}/${developmentId}`;

export const APP_ROUTES = {
  properties,
  map,

  /**
   * Account management routes
   */
  accountManagementRoot,

  /**
   * Properties management routes
   */
  propertiesManagementRoot,

  /**
   * Property management routes
   */
  propertyManagementRoot,
  propertyManagementEditDevelopmentRoot,
  propertyManagementEditDevelopmentAmenities: (
    props: PrivateDevelopmentRouteProps
  ) => `${propertyManagementEditDevelopmentRoot(props)}/amenities`,
  propertyManagementEditDevelopmentBasicInformation: (
    props: PrivateDevelopmentRouteProps
  ) => `${propertyManagementEditDevelopmentRoot(props)}/basic-information`,
  propertyManagementEditDevelopmentMedias: (
    props: PrivateDevelopmentRouteProps
  ) => `${propertyManagementEditDevelopmentRoot(props)}/media`,
  propertyManagementEditDevelopmentFacilities: (
    props: PrivateDevelopmentRouteProps
  ) => `${propertyManagementEditDevelopmentRoot(props)}/facilities`,
  propertyManagementEditDevelopmentProperties: (
    props: PrivateDevelopmentRouteProps
  ) => `${propertyManagementEditDevelopmentRoot(props)}/properties`,
  propertyManagementEditDevelopmentProperty: (props: PrivateFlatRouteProps) =>
    `${propertyManagementEditDevelopmentRoot(props)}/properties/${
      props.flatId
    }`,
  propertyManagementEditDevelopmentTenancyTerms: (
    props: PrivateDevelopmentRouteProps
  ) => `${propertyManagementEditDevelopmentRoot(props)}/tenancy-terms`,
  propertyManagementEditDevelopmentPublicListing: (
    props: PrivateDevelopmentRouteProps
  ) => `${propertyManagementEditDevelopmentRoot(props)}/public-listing`,

  /**
   * Create a new property route
   */
  createPropertyRoot,
};
