import * as React from 'react';
import useStyles, { StyledTab, StyledTabs } from './DateSelectorDialog.styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  ButtonBase,
  Switch,
} from '@material-ui/core';
import { Button, Link, Typography } from '../../../atoms';
import { Check as CheckIcon } from '@material-ui/icons';
import { formatDateToString, getDateSelectorTab } from '../utils';
import { DateSelectorTab, DatesInfo } from '../types';

interface DateSelectorDialogProps {
  open: boolean;
  handleClose: () => void;
  dateInfo: DatesInfo | null;
  dateComparingInfo: DatesInfo | null;
  selectedTab: DateSelectorTab;
  isComparing: boolean;
  onChangeTab: (event: React.ChangeEvent, value: DateSelectorTab) => void;
  onSwitchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSelectDate: (date: DatesInfo) => void;
  onSelectDateComparing: (date: DatesInfo) => void;
  onSave: () => void;
}

function DateSelectorDialog({
  open,
  handleClose,
  dateInfo,
  dateComparingInfo,
  isComparing,
  selectedTab,
  onChangeTab,
  onSwitchChange,
  onSelectDate,
  onSelectDateComparing,
  onSave,
}: DateSelectorDialogProps) {
  const classes = useStyles();

  const tabs = getDateSelectorTab(new Date());

  return (
    <Dialog
      className={classes.dialog}
      onClose={handleClose}
      open={open}
      maxWidth="xs"
    >
      <DialogTitle className={classes.dialogTitle}>Date Range</DialogTitle>
      <DialogContent className={classes.dialogContent} style={{ padding: 0 }}>
        <StyledTabs
          value={selectedTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={onChangeTab}
          variant="scrollable"
        >
          {tabs.map((tab) => (
            <StyledTab
              key={tab.tabKey}
              value={tab.tabKey}
              label={tab.label}
              selected={tab.tabKey === selectedTab}
              disabled={tab.disabled}
            />
          ))}
        </StyledTabs>
        <Box padding="0.375rem 1rem">
          {tabs.map((tab) => (
            <Box
              role="tabpanel"
              hidden={tab.tabKey !== selectedTab}
              key={tab.tabKey}
            >
              {tab.tabKey === selectedTab && (
                <Box>
                  {tab.dates.map((item) => (
                    <ButtonBase
                      key={item.label}
                      className={classes.selection}
                      onClick={() => onSelectDate(item)}
                    >
                      <Box>
                        <Typography
                          fontSize={14}
                          fontWeight={600}
                          color={
                            dateInfo?.key === item.key ? 'primary' : 'inherit'
                          }
                        >
                          {item.label}
                        </Typography>
                        <Typography
                          fontSize={12}
                          color="gray"
                          colorVariant="contrastText"
                        >
                          {formatDateToString(
                            item.value,
                            item.showMonthOnly,
                            item.showYearOnly
                          )}
                        </Typography>
                      </Box>
                      {dateInfo?.key === item.key && (
                        <CheckIcon color="primary" fontSize="small" />
                      )}
                    </ButtonBase>
                  ))}
                  <Box paddingTop={1.25}>
                    <Typography fontSize={10}>
                      Reporting time zone: Etc/GMT
                    </Typography>
                    <Typography fontSize={10} color="primary">
                      <Link to="#" noColor>
                        Learn more
                      </Link>
                    </Typography>
                  </Box>
                  <Box
                    paddingY={1.25}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography fontWeight={600}>Compare</Typography>
                    <Switch
                      checked={isComparing}
                      onChange={onSwitchChange}
                      color="primary"
                      size="small"
                    />
                  </Box>
                  {isComparing &&
                    tab.datesComparing.map((item) => (
                      <ButtonBase
                        key={item.label}
                        className={classes.selection}
                        onClick={() => onSelectDateComparing(item)}
                      >
                        <Box>
                          <Typography
                            fontSize={14}
                            fontWeight={600}
                            color={
                              dateComparingInfo?.key === item.key
                                ? 'primary'
                                : 'inherit'
                            }
                          >
                            {item.label}
                          </Typography>
                          <Typography
                            fontSize={12}
                            color="gray"
                            colorVariant="contrastText"
                          >
                            {formatDateToString(
                              item.value,
                              item.showMonthOnly,
                              item.showYearOnly
                            )}
                          </Typography>
                        </Box>
                        {dateComparingInfo?.key === item.key && (
                          <CheckIcon color="primary" fontSize="small" />
                        )}
                      </ButtonBase>
                    ))}
                </Box>
              )}
            </Box>
          ))}
        </Box>
      </DialogContent>
      <DialogActions className={classes.buttons}>
        <Button onClick={handleClose} variant="outlined" fullWidth>
          Cancel
        </Button>
        <Button
          onClick={onSave}
          color="primary"
          variant="contained"
          disabled={dateInfo == null}
          autoFocus
          fullWidth
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DateSelectorDialog;
