import * as React from 'react';
import {
  Box,
  InputLabel,
  TextField as MUITextField,
  TextFieldProps as MUITextFieldProps,
} from '@material-ui/core';
import clsx from 'clsx';
import { useStyles } from './TextField.styles';
import { PopupTooltip } from '../../molecules';
import { HelpRounded } from '@material-ui/icons';

export type TextFieldProps = MUITextFieldProps & {
  styleCustomized?: boolean;
  labelClassName?: string;
  wrapClassName?: string;
  htmlFor?: string;
  helperTooltip?: string;
};

const TextField = React.forwardRef<HTMLDivElement, TextFieldProps>(
  (
    {
      htmlFor,
      label,
      className,
      labelClassName,
      wrapClassName,
      children,
      variant = 'standard',
      styleCustomized = true,
      helperTooltip,
      ...rest
    },
    ref
  ) => {
    const classes = useStyles();

    return (
      <Box
        className={clsx(styleCustomized && classes.wrapStyled, wrapClassName)}
      >
        {label && (
          <Box className={classes.labelCtn}>
            <InputLabel
              className={clsx(
                styleCustomized && classes.labelStyled,
                labelClassName
              )}
              htmlFor={htmlFor}
            >
              {label}
            </InputLabel>
            {helperTooltip && (
              <InputLabel className={classes.labelStyled}>
                <PopupTooltip icon={HelpRounded} title={helperTooltip} />
              </InputLabel>
            )}
          </Box>
        )}
        <MUITextField
          {...rest}
          ref={ref}
          className={clsx(styleCustomized && classes.textStyled, className)}
          variant={styleCustomized ? 'outlined' : variant}
        >
          {children}
        </MUITextField>
      </Box>
    );
  }
);

TextField.displayName = 'TextField';

export default TextField;
