import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { MediaUploadAreaLayout, MediaUploadAreaVariant } from './types';

const getMediaUploadAreaLayout = (
  theme: Theme,
  layout: MediaUploadAreaLayout
) => {
  switch (layout) {
    case 'cover': {
      return {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridTemplateRows: theme.spacing(50),
        gridAutoRows: theme.spacing(25),
        gap: theme.spacing(2),

        '& > *': {
          width: '100%',
          height: '100%',
        },

        '& >:first-child': {
          gridColumn: '1 / 3',
        },
      };
    }
    case 'equal':
    default: {
      return {
        display: 'grid',
        gridTemplateColumns: `repeat(auto-fill, minmax(${theme.spacing(
          11.5
        )}px, 1fr))`,
        gridAutoRows: theme.spacing(11.5),
        gap: `${theme.spacing(1.75)}px ${theme.spacing(1)}px`,

        '& > *': {
          width: '100%',
          height: '100%',
        },

        '& >:first-child': {
          gridColumn: 'auto / auto',
        },
      };
    }
  }
};

const useStyles = makeStyles<
  Theme,
  { variant: MediaUploadAreaVariant; layout: MediaUploadAreaLayout }
>((theme: Theme) =>
  createStyles({
    mediaUploadAreaCtn: ({ layout }) => ({
      position: 'relative',

      ...getMediaUploadAreaLayout(theme, layout),

      width: '100%',
      height: '100%',
      padding: 0,

      backgroundColor: theme.palette.common.white,
      overflow: 'hidden auto',
    }),

    mediaUploadAreaHintCtn: {
      position: 'relative',
      width: '100%',
      height: '100%',
      minHeight: theme.spacing(4),
    },

    mediaUploadAreaHint: ({ variant }) => ({
      position: 'absolute',
      top: 0,
      left: 0,

      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',

      width: '100%',
      height: '100%',

      fontSize: theme.typography.pxToRem(48),

      color: theme.palette.gray.dark,
      backgroundColor:
        variant === 'full'
          ? theme.palette.common.white
          : theme.palette.gray.light2,

      borderRadius: 8,
      cursor: 'pointer',
    }),
  })
);

export default useStyles;
