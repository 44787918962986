import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) =>
  createStyles({
    ctn: {
      display: 'flex',
    },
    datesFrom: {
      fontSize: '0.875rem',
      fontWeight: 600,
      color: theme.palette.gray.contrastText,
      lineHeight: '1.25rem',
    },
    datesTo: {
      fontSize: '0.75rem',
      color: theme.palette.gray.contrastText,
      lineHeight: '1rem',
    },
    iconButton: {
      width: '1.5rem',
      height: '1.25rem',
    },
    calendarButton: {
      fontWeight: 600,
      height: '1.5rem',
    },
    calendarIcon: {
      fontSize: '1rem',
      marginRight: theme.spacing(1),
    },
  })
);

export default useStyles;
