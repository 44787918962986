export interface GetItemFromLocalStorageArgs {
  key: string;
  options?: {
    /**
     * If true, will automatically return a parsed JSON.
     *
     * If the parse fails, will return null.
     */
    parseJson?: boolean;
  };
}

export default function getItemFromLocalStorage<T = string>({
  key,
  options = {},
}: GetItemFromLocalStorageArgs): T | null {
  const value = localStorage.getItem(key);

  if (value && options.parseJson) {
    try {
      return JSON.parse(value) as T;
    } catch (err) {
      return null;
    }
  } else {
    return value as unknown as T | null;
  }
}
