/**
 * This Input component needs to consider these scenarios:
 * - The property list page is refreshed
 * - The property list page is navigated to using a "clean" path (i.e. no query
 *   parameters)
 * - The user copy-pastes some text onto the Input
 * - The user types some text onto the Input
 * - The user clears the Input
 */

import * as React from 'react';
import { Box } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { TextField, TextFieldProps } from '../../../atoms';
import useStyles from './Input.styles';

type ExtendedTextFieldProps = Omit<
  TextFieldProps,
  'defaultValue' | 'placeholder'
>;

export interface InputProps extends ExtendedTextFieldProps {
  noRoundedCorners: boolean;
}

export default function Input({
  noRoundedCorners,
  inputProps,
  ...rest
}: InputProps) {
  const classes = useStyles({ noRoundedCorners });

  // - inputProps.value is provided by MUI Autocomplete's 'inputValue' property
  const inputValue = inputProps?.value;

  return (
    <Box className={classes.inputCtn}>
      <TextField
        {...rest}
        inputProps={{
          ...inputProps,
          value: inputValue,
        }}
        className={classes.input}
        placeholder="SE1, London, or Waterloo Station..."
      />
      <Search className={classes.searchIcon} />
    </Box>
  );
}
