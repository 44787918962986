import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import API_ENDPOINTS from '../endpoints';
import pubFetcher from '../utils/fetcher';

export interface GetAgencyCitiesResponse {
  data: string[];
  meta: {
    count: number;
  };
}

export default function useAgencyCities(
  queryConfig?: UseQueryOptions<GetAgencyCitiesResponse>
) {
  const url = API_ENDPOINTS.AGENCIES_CITIES;

  return useQuery<GetAgencyCitiesResponse, Error>(
    [url],
    () => pubFetcher(url),
    queryConfig
  );
}
