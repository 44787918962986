import * as React from 'react';
import { SvgIconProps } from '@material-ui/core';
import SvgIconBtr from './SvgIconBtr';

export default function BtrCreditCardWifi(props: SvgIconProps) {
  return (
    <SvgIconBtr {...props}>
      <rect
        x="2.78"
        y="15.04"
        width="27.9"
        height="18.52"
        rx="2.58"
        ry="2.58"
      />
      <line x1="2.78" y1="19.66" x2="30.68" y2="19.66" />
      <line x1="6.35" y1="27.5" x2="12.07" y2="27.5" />
      <line x1="13.52" y1="27.5" x2="19.23" y2="27.5" />
      <line x1="20.68" y1="27.5" x2="26.4" y2="27.5" />
      <line x1="6.35" y1="30.01" x2="12.49" y2="30.01" />
      <path d="M30,12.31a1.31,1.31,0,0,1,1.37,1.27" />
      <path d="M29.87,9.38a4.26,4.26,0,0,1,4.42,4.08" />
      <path d="M29.75,6.45a7.18,7.18,0,0,1,7.47,6.9" />
    </SvgIconBtr>
  );
}
