export interface DatesInfo {
  key: string;
  label: string;
  value: [Date, Date] | [Date];
  showMonthOnly?: boolean;
  showYearOnly?: boolean;
}

export interface TabInfo {
  label: string;
  tabKey: DateSelectorTab;
  dates: DatesInfo[];
  datesComparing: DatesInfo[];
  disabled?: boolean;
}

export enum DateSelectorTab {
  DATE = 'DATE',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
  CUSTOM = 'CUSTOM',
}
