import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import API_ENDPOINTS from '../../endpoints';
import { UserInDb } from '../types';

export interface MyUserDetails {
  user: UserInDb;
  organization: {
    organizationId: number | undefined;
    landlordId: number | undefined;
    organizationTitle: string | undefined;
  };
}

export async function myDetailsFetcher(
  authToken: string
): Promise<MyUserDetails> {
  const url = `${process.env.GATSBY_API_HOST}/api/me/myDetails`;

  const headers: RequestInit['headers'] = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  if (authToken) {
    headers.Authorization = `Bearer ${authToken}`;
  }

  const res = await fetch(url, {
    headers,

    // Allow cookies
    // https://developers.google.com/web/updates/2015/03/introduction-to-fetch#sending_credentials_with_a_fetch_request
    credentials: 'include',
  });

  if (!res.ok) {
    const errMsg = await res.text();
    throw new Error(errMsg);
  }

  const json = await res.json();

  return json.data;
}

/**
 * Retrieve a user's details once the access token has been obtained and
 * verified.
 */
export default function useMyUserDetails({
  authToken,
  queryConfig,
}: {
  authToken?: string;

  // Sometimes we might already have my user details from other pages. We
  // provide it as initial data in these cases.
  queryConfig?: UseQueryOptions<any>;
}) {
  return useQuery<MyUserDetails, Error>(
    [API_ENDPOINTS.MY_DETAILS, { authToken }],
    () => myDetailsFetcher(authToken ?? ''),
    { ...queryConfig, retry: 0 }
  );
}
