/**
 * Use window.setTimeout() to delay function execution.
 */
export async function wait(howLong: number): Promise<void> {
  return new Promise((resolve) => {
    if (typeof window !== 'undefined') {
      window.setTimeout(() => {
        resolve();
      }, howLong);
    }
  });
}

/**
 * Given an array of functions and a time T in ms, execute each function after T
 * one-by-one.
 */
export async function executeSequentially(
  fns: Array<() => void>,
  debounceTime: number
): Promise<void> {
  for (let i = 0; i < fns.length; i++) {
    fns[i]();

    await wait(debounceTime);
  }
}
