import * as React from 'react';
import IconButton, { IconButtonProps } from '../../IconButton';
import { PlayArrow } from '@material-ui/icons';

import useStyles, { PlayButtonSize } from './PlayButton.styles';

export interface PlayButtonProps extends IconButtonProps {
  iconSize: PlayButtonSize;
}

export default function PlayButton({ iconSize, ...rest }: PlayButtonProps) {
  const classes = useStyles({ size: iconSize });

  return (
    <IconButton className={classes.playButton} {...rest}>
      <PlayArrow fontSize="inherit" color="inherit" />
    </IconButton>
  );
}
