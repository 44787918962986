import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';
import { DevelopmentActivityInDb, ImageData } from '../property/types';
import { CustomError, ErrorObjFromApi } from '../../utils/error';
import { combineQueryParams } from '../../utils/url';
import { OrgFile, OrgFileCategory } from '../orgs/types';

interface FetcherProps {
  authToken: string;
  orgId: number;
  developmentId: number;

  formData: FormData;
  clientId: string;
  category?: OrgFileCategory;
  description?: string;
  priority?: number;
}

interface FetcherResponse {
  data: {
    storageResult: any;
    databaseResult: {
      newDevelopmentImages: OrgFile[];
      insertedImage: OrgFile;
    };
  };
  meta: {
    originalname: string;
    activity: DevelopmentActivityInDb;
    newImg: ImageData;
  };
}

export async function addDevelopmentImage({
  authToken,
  orgId,
  developmentId,
  formData,
  clientId,
  category,
  priority,
  description,
}: FetcherProps): Promise<FetcherResponse> {
  const headers: RequestInit['headers'] = {
    Accept: 'application/json',
    'BTR-Organization': `${orgId}`,
    Authorization: `Bearer ${authToken}`,
  };

  const queryParams = combineQueryParams({
    clientId,
    category,
    priority,
    description,
  });

  const res = await fetch(
    `${process.env.GATSBY_API_HOST}/api/development/${developmentId}/image?${queryParams}`,
    {
      method: 'POST',
      headers,
      credentials: 'include',
      body: formData,
    }
  );

  if (!res.ok) {
    const errObj = (await res.json()) as ErrorObjFromApi;
    throw new CustomError({
      message: errObj.message,
      meta: errObj.meta,
    });
  }

  return res.json() as Promise<FetcherResponse>;
}

export interface UseAddDevelopmentImageProps {
  queryConfig?: UseMutationOptions<FetcherResponse, CustomError, FetcherProps>;
}

export default function useAddDevelopmentImage({
  queryConfig = {},
}: UseAddDevelopmentImageProps = {}) {
  const queryClient = useQueryClient();

  const { onSuccess: additionalOnSuccess, ...restQueryConfig } = queryConfig;

  return useMutation<FetcherResponse, CustomError, FetcherProps>(
    addDevelopmentImage,
    {
      onSuccess: (data, variables, context) => {
        void queryClient.setQueryData(
          [
            `${process.env.GATSBY_API_HOST}/api/development/${variables.developmentId}/images`,
            { authToken: variables.authToken, orgId: variables.orgId },
          ],
          { data: data.data.databaseResult.newDevelopmentImages }
        );

        if (additionalOnSuccess) {
          void additionalOnSuccess(data, variables, context);
        }
      },

      ...restQueryConfig,
    }
  );
}
