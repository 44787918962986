import * as React from 'react';
import { SvgIconProps } from '@material-ui/core';
import SvgIconBtr from './SvgIconBtr';

export default function BtrTableAndChairs(props: SvgIconProps) {
  return (
    <SvgIconBtr {...props}>
      <line x1="12.93" y1="17.65" x2="27.72" y2="17.65" />
      <line x1="20.32" y1="17.65" x2="20.32" y2="30.39" />
      <path d="M13.28,24.41H5.2a2,2,0,0,1-2-1.61L1.09,12.08A2,2,0,0,1,2,9.87a2,2,0,0,1,3,1.35l1.84,9.19H13.2a2,2,0,0,1,2,1.35A2,2,0,0,1,13.28,24.41Z" />
      <line x1="5.81" y1="24.41" x2="4.62" y2="30.39" />
      <line x1="12.74" y1="24.41" x2="14.5" y2="30.39" />
      <line x1="7.16" y1="27.89" x2="13.77" y2="27.89" />
      <path d="M26.72,24.41H34.8a2,2,0,0,0,2-1.61l2.15-10.72A2,2,0,0,0,38,9.87a2,2,0,0,0-3,1.35l-1.84,9.19H26.8a2,2,0,0,0-2,1.35A2,2,0,0,0,26.72,24.41Z" />
      <line x1="34.19" y1="24.41" x2="35.38" y2="30.39" />
      <line x1="27.26" y1="24.41" x2="25.5" y2="30.39" />
      <line x1="32.84" y1="27.89" x2="26.23" y2="27.89" />
    </SvgIconBtr>
  );
}
