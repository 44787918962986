/**
 * @param {string} queryParamsStr - This is expected to be in the query
 * parameters part of the URL, including the '?'. Hint: Gatsby provide this to
 * every page as the 'location.search' prop.
 */

export function getQueryParamsObj(
  queryParamsStr: string
): Record<string, string> {
  const parts = queryParamsStr.slice(1).split('&');

  const queryParams: Record<string, string> = {};

  if (parts.length === 1 && parts[0] === '') return queryParams;

  parts.forEach((part) => {
    const [queryKey, queryValue] = part.split('=');

    queryParams[queryKey] = queryValue;
  });

  return queryParams;
}

/**
 * Given an object of key / value query parameters, combine them into a single
 * query parameter string. All values will be encoded with encodeURIComponent.
 * Undefined values will be ignored.
 *
 * Note: the starting '?' is NOT included.
 */
export function combineQueryParams(
  queryParams: Record<
    string,
    string | string[] | number | boolean | null | undefined
  >
): string {
  return Object.entries(queryParams).reduce(
    (combinedQuery, [queryKey, queryValue], index) => {
      if (typeof queryValue === 'undefined') {
        return combinedQuery;
      }
      return `${combinedQuery}${index > 0 ? '&' : ''}${encodeURIComponent(
        queryKey
      )}=${encodeURIComponent(`${queryValue}`)}`;
    },
    ''
  );
}

/**
 * Given a query parameters string (a.k.a. the "search" portion of a URL),
 * return a key-value object representing the query parameters string.
 *
 * @param {string} queryParamsStr - The query parameters ("search" portion of a
 * URL) string
 *
 * @returns {Record<string, string>} - A key-value object representing the query
 * parameters string. If the provided string is empty, will return an empty
 * object.
 */
export function parseQueryParamsString(
  queryParamsStr: string
): Record<string, string> {
  if (!queryParamsStr) return {};

  const urlSearchParams = new URLSearchParams(queryParamsStr);

  const parsed = {};
  for (const [k, v] of urlSearchParams.entries()) {
    parsed[k] = v;
  }

  return parsed;
}

/**
 * Compare 2x paths to see if they are equal. This removes trailing and
 * beginning forward slashes ("/") before comparing.
 */
export function doesPathMatch(pathA: string, pathB: string): boolean {
  const normalizedPathA = pathA.replace(/^\/|\/$/g, '');
  const normalizedPathB = pathB.replace(/^\/|\/$/g, '');

  return normalizedPathA === normalizedPathB;
}
