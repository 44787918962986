import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';
import { useFlatImagesUrlCreator } from './useFlatImages';
import { PvtFetcherBaseProps } from '../../utils/pvtFetcher';
import { FlatActivityInDb } from '../../property/types';
import { OrgFile, OrgFileCategory } from '../../orgs/types';
import { CustomError, ErrorObjFromApi } from '../../../utils/error';
import { combineQueryParams } from '../../../utils/url';

interface FetcherProps extends PvtFetcherBaseProps {
  developmentId: number;
  flatId: number;

  formData: FormData;
  clientId: string;
  category?: OrgFileCategory;
  description?: string;
  priority?: number;
}

interface FetcherResponse {
  data: {
    storageResult: any;
    databaseResult: {
      newFlatImages: OrgFile[];
      insertedImage: OrgFile;
    };
  };
  meta: {
    clientId: string;
    originalname: string;
    activity: FlatActivityInDb;
  };
}

export async function addFlatImage({
  authToken,
  orgId,
  developmentId,
  flatId,
  formData,
  clientId,
  category,
  priority,
  description,
}: FetcherProps): Promise<FetcherResponse> {
  const headers: RequestInit['headers'] = {
    Accept: 'application/json',
    'BTR-Organization': `${orgId}`,
    Authorization: `Bearer ${authToken}`,
  };

  const queryParams = combineQueryParams({
    clientId,
    category,
    priority,
    description,
  });

  const res = await fetch(
    `${process.env.GATSBY_API_HOST}/api/development/${developmentId}/${flatId}/image?${queryParams}`,
    {
      method: 'POST',
      headers,
      credentials: 'include',
      body: formData,
    }
  );

  if (!res.ok) {
    const errObj = (await res.json()) as ErrorObjFromApi;
    throw new CustomError({
      message: errObj.message,
      meta: errObj.meta,
    });
  }

  return res.json() as Promise<FetcherResponse>;
}

export interface UseAddFlatImageProps {
  queryConfig?: UseMutationOptions<FetcherResponse, CustomError, FetcherProps>;
}

export default function useAddFlatImage({
  queryConfig = {},
}: UseAddFlatImageProps = {}) {
  const queryClient = useQueryClient();

  const { onSuccess: additionalOnSuccess, ...restQueryConfig } = queryConfig;

  return useMutation<FetcherResponse, CustomError, FetcherProps>(addFlatImage, {
    onSuccess: (data, variables, context) => {
      void queryClient.setQueryData(
        [
          useFlatImagesUrlCreator({
            developmentId: variables.developmentId,
            flatId: variables.flatId,
          }),
          { authToken: variables.authToken, orgId: variables.orgId },
        ],
        { data: data.data.databaseResult.newFlatImages }
      );

      if (additionalOnSuccess) {
        void additionalOnSuccess(data, variables, context);
      }
    },
    ...restQueryConfig,
  });
}
