import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    uploadButton: {
      position: 'relative',
    },

    uploadButtonButton: {
      fontSize: 20,
      borderColor: theme.palette.gray.dark,
      backgroundColor: theme.palette.common.white,
    },

    uploadButtonUploadArea: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
  })
);

export default useStyles;
