import * as React from 'react';
import {
  createStyles,
  makeStyles,
  Tab,
  Tabs,
  Theme,
  withStyles,
} from '@material-ui/core';
import clsx from 'clsx';

export const useStyles = makeStyles<
  Theme,
  { variant: 'scrollable' | 'standard' | 'fullWidth' }
>((theme: Theme) =>
  createStyles({
    variantTab: ({ variant }) => ({
      margin: variant === 'scrollable' ? theme.spacing(2, 0) : theme.spacing(2),
      overflow: variant === 'scrollable' ? 'visible' : 'hidden',
      '& .MuiTabs-scroller': {
        padding:
          variant === 'scrollable' ? theme.spacing(0, 2) : theme.spacing(0),
      },

      '& .MuiTabs-flexContainer': {
        display: variant === 'scrollable' ? 'block' : 'flex',
        '& button:first-child': {
          marginLeft: theme.spacing(0),
        },
        '& button:last-child': {
          marginRight:
            variant === 'scrollable' ? theme.spacing(2) : theme.spacing(0),
        },
      },
    }),
  })
);

export type StyledTabsProps = {
  value: string | number;
  variant?: 'standard' | 'fullWidth' | 'scrollable';
  className?: string;
  onChange: (event: React.ChangeEvent<{}>, value: number) => void;
};

export const StyledTabs = withStyles((theme) =>
  createStyles({
    root: {
      minHeight: 'auto',
    },
    indicator: {
      backgroundColor: 'transparent',
    },
  })
)(({ variant = 'fullWidth', ...props }: StyledTabsProps) => {
  const classes = useStyles({ variant });

  return (
    <Tabs
      {...props}
      variant={variant}
      className={clsx(props.className, classes.variantTab)}
      TabIndicatorProps={{ children: <span /> }}
    />
  );
});

export interface StyledTabProps {
  label: string;
  className?: string;
}

export const StyledTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      fontSize: theme.typography.pxToRem(13),
      fontFamily: theme.typography.fontFamily,
      fontWeight: theme.typography.fontWeightSemiBold,
      backgroundColor: '#fff',
      borderRadius: 4,
      color: theme.palette.gray.contrastText,
      textTransform: 'capitalize',
      padding: theme.spacing(0.5, 1.5),
      minHeight: 'auto',
      flexBasis: 'auto',
      margin: theme.spacing(0, 1),
      opacity: 1,
    },
    fullWidth: {
      flexBasis: 'auto',
    },
    selected: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  })
)((props: StyledTabProps) => <Tab disableRipple {...props} />);
