import { createStyles, makeStyles, Theme } from '@material-ui/core';
import checkedIcon from './checkedIcon.svg';

export const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    radioGroupStyled: {
      '& .MuiFormControlLabel-label': {
        fontWeight: theme.typography.fontWeightSemiBold,
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.gray.dark,
        [theme.breakpoints.down('md')]: {
          fontSize: theme.typography.pxToRem(14),
        },
      },
    },

    radioInlineStyled: {
      flexDirection: 'row',
    },

    labelStyled: {
      fontSize: theme.typography.pxToRem(16),
      fontFamily: theme.typography.fontFamily,
      fontWeight: theme.typography.fontWeightSemiBold,
      marginBottom: 14,
      [theme.breakpoints.down('md')]: {
        fontSize: theme.typography.pxToRem(14),
      },
    },

    wrapStyled: {
      marginBottom: 14,
    },
  })
);

export const useRadioStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(0, 1),
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },

    icon: {
      borderRadius: '50%',
      width: 25,
      height: 25,
      border: `1px solid ${theme.palette.gray.dark}`,
    },

    checkedIcon: {
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      backgroundImage: `url(${checkedIcon})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
  })
);
