import * as React from 'react';
import { SvgIconProps } from '@material-ui/core';
import SvgIconBtr from './SvgIconBtr';

export default function BtrCoffeeTable(props: SvgIconProps) {
  return (
    <SvgIconBtr {...props}>
      <rect x="0.5" y="17.89" width="39" height="5.31" />
      <line x1="5.4" y1="23.2" x2="5.4" y2="30.35" />
      <line x1="33.57" y1="23.2" x2="33.57" y2="30.35" />
      <line x1="5.4" y1="27.86" x2="29.32" y2="27.86" />
      <path d="M18.26,9.65H26.5a0,0,0,0,1,0,0v5.21a3,3,0,0,1-3,3H21.3a3,3,0,0,1-3-3V9.65a0,0,0,0,1,0,0Z" />
      <line x1="28.16" y1="11.34" x2="28.16" y2="14.34" />
      <path d="M4.44,9.65h8.24a0,0,0,0,1,0,0v5.21a3,3,0,0,1-3,3H7.47a3,3,0,0,1-3-3V9.65a0,0,0,0,1,0,0Z" />
      <line x1="14.34" y1="11.34" x2="14.34" y2="14.34" />
    </SvgIconBtr>
  );
}
